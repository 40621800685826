import Ar from "arweave/ar";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { closeDialog } from "redux/modal/modalSlice";
import { appendDevPageLog as append } from "redux/ui/uiSlice";
import { warp } from "warp/warpServer";

function RecycleBin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function openBinPage() {
    navigate("/bin");
    dispatch(closeDialog());
  }

  async function readState() {
    try {
      const contract = warp.contract(Ar.contractIds.recycleBinInstance);
      const state = await contract.readState();
      dispatch(append(state));
    } catch (err: any) {
      dispatch(append(err));
    }
  }

  async function readKV() {
    try {
      await Ar.checkInitialization();
      await Ar.checkWalletExistence();
      const userId = await Ar.account.getActiveAddress();
      const contract = warp.contract(Ar.contractIds.recycleBinInstance);
      const ownState = await contract.viewState({ function: "list", userId: userId });
      dispatch(append(ownState));
    } catch (err: any) {
      dispatch(append(err));
    }
  }

  return (
    <div className="dev-page__row">
      <label>Recycle Bin</label>
      <button onClick={openBinPage}>RecycleBinPage</button>
      <button onClick={readState}>Read State</button>
      <button onClick={readKV}>Read KV</button>
    </div>
  );
}

export default RecycleBin;
