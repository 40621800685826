import Image from "components/image";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface Props {
  id: string;
}

export default function PreviewImage(props: Props) {
  const { id } = props;

  const uploadImageState = useAppSelector((state) => state.uploadImage[id]);
  const { mode, url, file } = uploadImageState || {};

  const [src, setSrc] = React.useState("");
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    setError(false);
    switch (mode) {
      case "upload":
        if (file) {
          const objectUrl = URL.createObjectURL(file);
          setSrc(objectUrl);
          return () => URL.revokeObjectURL(objectUrl);
        } else {
          setSrc("");
        }
        break;

      case "url":
        setSrc(url);
        break;

      default:
        console.log("Invalid mode");
        break;
    }
  }, [mode, url, file]);

  return uploadImageState ? (
    <div className="upload-image__preview">{!error && <Image src={src} onError={() => setError(true)} />}</div>
  ) : null;
}
