import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShellFormState } from "./types";

const initialState: ShellFormState = {
  name: "",
  visibility: "public",
  nsfw: false,
  about: "",
  rules: "",
  thumbTx: "",
  thumbUrl: "",
  moderators: [],
  contributors: [],
  blocklists: [],
};

const shellFormSlice = createSlice({
  name: "shellForm",
  initialState,
  reducers: {
    prepareShellForm: (state) => {
      return {
        ...initialState,
        // Persist some previous values:
        visibility: state.visibility,
        nsfw: state.nsfw,
      };
    },
    updateShellForm: (state, action: PayloadAction<Partial<ShellFormState>>) => {
      return { ...state, ...action.payload };
    },
    clearShellForm: () => {
      return initialState;
    },
  },
});

export const { updateShellForm } = shellFormSlice.actions;
export default shellFormSlice;
