import { ArweaveWalletKit, useConnection } from "arweave-wallet-kit";
import * as Configs from "arweave/config";
import React from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Wallet from "redux/wallet/slice";

// ****************************************************************************
// WalletKitProvider: Configuration wrapper for <ArweaveWalletKit>
// ****************************************************************************

interface Props {
  children: React.ReactNode;
}

const arkitTheme: any = { displayTheme: "dark" };

function WalletKitProvider(props: Props) {
  const gatewayId = useAppSelector((state) => state.settings.gatewayId);

  const config = {
    permissions: Configs.permissions as any[],
    appInfo: Configs.appInfo,
    gatewayConfig: Configs.gateways[gatewayId],
    fetchAnsProfile: false,
  };

  return (
    <ArweaveWalletKit config={config} theme={arkitTheme}>
      <WalletKitStateListener />
      {props.children}
    </ArweaveWalletKit>
  );
}

// ****************************************************************************
// WalletKitStateListener
// ****************************************************************************

/**
 * Stores the wallet-kit states into the redux store.
 *
 * Try not to use wallet-kit hooks (e.g. `useConnection`) directly in a component.
 * Those hooks update a lot because it listens directly to its context (instead
 * of specific states), and the return values are sometimes non-memoized objects.
 *
 * This dummy component serves as the single listener that gets hit often, so
 * that our normal components don't have to render unnecessarily.
 *
 * If you need to use another wallet-kit hook, add it here and wire up the
 * result to a redux state.
 */
function WalletKitStateListener() {
  const { connected } = useConnection();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    async function getAddress() {
      dispatch(Wallet.actions.setConnected({ connected }));
      dispatch(Wallet.actions.setAddress({ address: connected ? await window.arweaveWallet.getActiveAddress() : "" }));
    }

    getAddress().catch((err) => console.error(err));
  }, [connected, dispatch]);

  return null;
}

// ****************************************************************************
// ****************************************************************************

export default WalletKitProvider;
