import { useParams } from "react-router-dom";
import PostButtons from "components/postButtons";
import ContentContainer from "components/contentContainer";
import { utilSetWallpaper } from "utils/setTheme";
import "./style.scss";
import { isAddress } from "arweave/utils";
import { useEffect } from "react";
import { fetchPosts } from "redux/posts/postsSlice";
import { getOptions } from "arweave/txQuery";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectProfileForId } from "redux/users/usersSlice";
import { getHandleNameForProfile } from "arweave/accountMgr";

const UserPage = () => {
  /*
  addr(pin):"nEEJj_8c757pkgbC1qfvSZXQy8Eob1baG8P-7FwAq1w"
  unique handle handle(pin):"tom#nEEq1w"
  name: tom
  We can't use # to delimit handle#address

  We can optionally try to support a url with a nonspecific handle, but
  that should probably be handled in search.
*/
  const { userAddress = "" } = useParams();
  const appDispatch = useAppDispatch();

  useEffect(() => {
    utilSetWallpaper(`wp${Math.floor(Math.random() * 8)}`);
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    appDispatch(fetchPosts(getOptions(userAddress, undefined)));
  }, [appDispatch, userAddress]);

  // ^-- Move all side effects out of render function
  const profileData = useAppSelector((state) => selectProfileForId(state, userAddress));
  const name = profileData && getHandleNameForProfile(profileData);
  if (isAddress(userAddress)) {
    return (
      <>
        <div className="user-page">
          <PostButtons />
          <h1>{name}</h1>
          <ContentContainer contentId={userAddress} />
        </div>
      </>
    );
  } else {
    return <h1>bad address</h1>;
  }
};

export default UserPage;
