import classnames from "classnames";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { closeDialog } from "redux/modal/modalSlice";
import "./style.scss";

interface Props {}

const Dialog = (props: Props) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const dispatch = useAppDispatch();
  const dialog = useAppSelector((state) => state.modal.dialog);
  const dialogProps = useAppSelector((state) => state.modal.dialogProps);

  const isOpen = dialog.length > 0;

  const handleContainerClick = () => {
    dispatch(closeDialog());
  };

  const handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (!isOpen) {
      document.body.classList.remove("disable-scroll");
      document.body.style.marginTop = "0";
      document.documentElement.scrollTop = scrollPosition;
    } else {
      const scrollOffset = document.documentElement.scrollTop;
      setScrollPosition(document.documentElement.scrollTop);
      setTimeout(() => {
        document.body.classList.add("disable-scroll");
        document.body.style.marginTop = scrollOffset * -1 + "px";
      }, 400);
    }
  }, [isOpen, scrollPosition]);

  if (isOpen) {
    return (
      <>
        {dialog.map((_, index) => {
          return ReactDOM.createPortal(
            <div className="dialog__container" onClick={handleContainerClick}>
              <div className={classnames("dialog__content", {})} onClick={handleContentClick}>
                {React.createElement(dialog[index], dialogProps[index])}
              </div>
            </div>,
            document.body
          );
        })}
      </>
    );
  } else {
    return null;
  }
};

export default Dialog;
