export function isAddress(param: string) {
  if (param.length !== 43) {
    return false;
  }
  const re = /^[A-Za-z0-9\-_]+$/;
  return re.test(param);
}

export function isUniqueHandle(param: string) {
  // bob#abcdef
  const re = /^(\w)+#([A-Za-z0-9\-_]{6})$/;
  return re.test(param);
}
