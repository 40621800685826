import { PostFormState } from "./types";

export function getTxTypeForPost(type: PostType): TxType {
  return "social-post"; // TODO: the spec allows multiple TxTypes
}

export function getPostContentType(type: PostType, postForm: PostFormState) {
  switch (type) {
    case "link":
      return "text/plain";

    case "text":
      switch (postForm.text.format) {
        case "plain":
          return "text/plain";
        case "markdown":
          return "text/markdown";
        case "json":
          return "application/json";
        default:
          console.assert(false, "unknown post format");
          return "text/plain";
      }

    case "file":
      return "text/plain"; // "application/octet-stream"; -- not uploading files now

    default:
      console.assert(false, "unknown post type");
      return "text/plain";
  }
}

export function getPostContentEncoding(type: PostType, postForm: PostFormState) {
  switch (type) {
    case "link":
    case "file":
      return null;
    case "text":
      switch (postForm.text.format) {
        case "plain":
        case "markdown":
          return null;
        case "json":
          return null; // Potentially need to support `gzip` etc. in the future.
        default:
          return null;
      }
    default:
      return null;
  }
}

export function getPostData(type: PostType, state: PostFormState) {
  switch (type) {
    case "link":
      return state.link.url;
    case "text":
      return state.text.body;
    case "file":
      return state.file.url;
    default:
      console.assert(false, "unknown post type");
      return "";
  }
}
