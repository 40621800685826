import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { hideShellPreview } from "redux/modal/modalSlice";
import "./style.scss";

const ShellPreview = () => {
  const shellPreview = useAppSelector((state) => state.modal.shellPreview);
  const shellPreviewWrapper = useRef<HTMLDivElement>(null);
  const shellPreviewProps = useAppSelector((state) => state.modal.shellPreviewProps);
  const isVisible = shellPreview !== null;
  const shell = (shellPreviewProps as any) && (shellPreviewProps as any).shell;
  const trigger = (shellPreviewProps as any) && (shellPreviewProps as any).trigger;
  const top =
    (trigger && window.innerHeight - trigger.getBoundingClientRect().y) > 160
      ? trigger.getBoundingClientRect().y + trigger.getBoundingClientRect().height + window.scrollY
      : "unset" || "unset";
  const bottom =
    top === "unset" && trigger
      ? window.innerHeight - trigger.getBoundingClientRect().bottom + 17 - window.scrollY
      : "unset";
  const left = (trigger && trigger.getBoundingClientRect().x - 10) || 0;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleMouseMove = (e: any) => {
      const elementBelowCursor = document.elementFromPoint(e.clientX, e.clientY);
      if (!shellPreviewWrapper.current?.contains(elementBelowCursor) && !trigger.contains(elementBelowCursor)) {
        dispatch(hideShellPreview());
      }
    };

    if (isVisible) {
      document.addEventListener("mousemove", handleMouseMove);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isVisible, trigger, dispatch]);

  if (isVisible && shell) {
    return ReactDOM.createPortal(
      <div
        className="shell-preview__wrapper"
        style={{ top: top, bottom: bottom, left: left }}
        ref={shellPreviewWrapper}
      >
        <div className="shell-preview">
          <div className="shell-preview__header">
            <Link to={`/u/${shell.id}`}>
              <img src={shell.avatar} />
              <label>u/{shell.title}</label>
            </Link>
            <button>Follow</button>
          </div>
          <div className="shell-preview__content">
            <p>About: Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
          </div>
        </div>
      </div>,
      document.body
    );
  } else {
    return null;
  }
};

export default ShellPreview;
