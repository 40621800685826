import DialogConfirm from "components/dialogConfirm";
import { MenuEntry } from "components/menu";
import { BaseSyntheticEvent } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { openDialog } from "redux/modal/modalSlice";
import { selectIsPostMine } from "redux/posts/postsSlice";
import { deleteItem } from "redux/recycleBin/thunks";

export interface Props {
  postId: string;
  trigger: BaseSyntheticEvent;
}

const PostMenu = (props: Props) => {
  const dispatch = useAppDispatch();
  const { postId } = props;
  const signedIn = useAppSelector((state) => state.wallet.connected);
  const isMine = useAppSelector((state) => selectIsPostMine(state, postId));

  const deletePost = () => {
    dispatch(deleteItem(postId)).catch((err) => {
      dispatch(
        openDialog(DialogConfirm, {
          title: "Failed to delete post",
          message: err.message,
          hideCancel: true,
        })
      );
    });
  };

  return (
    <>
      {isMine && <MenuEntry title="Edit Post" disabled />}
      {isMine && <MenuEntry title="Delete Post" disabled={!signedIn} action={deletePost} />}
      <MenuEntry title="Copy Link" disabled />
      <MenuEntry title="Report Link" disabled />
    </>
  );
};

export default PostMenu;
