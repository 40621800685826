import React from "react";
import { toggleTopLevelOrder } from "redux/comments/slice";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import "./style.scss";

interface Props {
  postId: string;
}

const SortBy = (props: Props) => {
  const { postId } = props;
  const dispatch = useAppDispatch();
  const sortBy = useAppSelector((state) => state.comments.postsUi[postId]?.sortBy) || "desc";

  const toggle = React.useCallback(() => {
    // Only handles top-level comments for now
    dispatch(toggleTopLevelOrder(postId));
  }, [dispatch, postId]);

  return (
    <div className="sort-by" onClick={toggle} title="Toggle order">
      {sortBy === "asc" ? "↑" : "↓"}
    </div>
  );
};

export default SortBy;
