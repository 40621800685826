import Ar from "arweave/ar";
import { COMMENTS_PAGE_SIZE } from "redux/comments/slice";

// -- TODO --------------------------------------------------------------------
// [ ] Use Apollo or Ardb instead of defining queries like this.
// ----------------------------------------------------------------------------

type GCQOptions = {
  postId: string;
  parentId: string;
  groupId?: string;
  cursor?: string | null | undefined;
  sortBy?: "desc" | "asc";
};

export function getCommentsQuery(options: GCQOptions) {
  const { postId, parentId, groupId, cursor, sortBy = "desc" } = options;

  // For "desc", use `null` instead of `HEIGHT_DESC`, since confirmation time
  // could screw up the order.
  // For "asc", since Arweave GraphQL does not provide the inverse of `null`,
  // `HEIGHT_ASC` is the closest we have.
  const sort = sortBy === "desc" ? null : "HEIGHT_ASC";

  return `
  {
    transactions(
      first: ${COMMENTS_PAGE_SIZE},
      ${cursor ? `after: "${cursor}",` : ""}
      ${sort ? `sort: ${sort},` : ""}
      tags: [
        { name: "App-Name", values: ["${Ar.appInfo.name}"] },
        { name: "App-Version", values: ["${Ar.appInfo.version}"] },
        { name: "Content-Type", values: ["text/plain"] },
        { name: "Data-Protocol", values: ["Comment"] },
        { name: "Root-Source", values: ["${postId}"] },
        ${parentId ? `{ name: "Data-Source", values: ["${parentId}"] },` : ""}
        ${groupId ? `{ name: "Group-Source", values: ["${groupId}"] },` : ""}
      ]
    ) 
    {
      pageInfo { hasNextPage }
      edges {
        cursor
        node {
          id
          owner { address }
          tags { name value }
        }
      }
    }
  }`;
}

export function getReplyCountQuery(commentIds: string[]) {
  return `
  {
    transactions(
      first: 100,
      tags: [
        { name: "App-Name", values: ["${Ar.appInfo.name}"] },
        { name: "App-Version", values: ["${Ar.appInfo.version}"] },
        { name: "Group-Source", values: ["${commentIds.join('","')}"] }
      ]
    ) 
    {
      edges {
        node {
          id
          tags { name value }
        }
      }
    }
  }`;
}
