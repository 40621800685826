import Arweave from "arweave";
import AccountMgr from "arweave/accountMgr";
import { GatewayId, gateways } from "arweave/config";
import Images from "arweave/images";
import SettingsMgr from "arweave/settingsMgr";
import Time from "arweave/time";

type AppInfo = { name: string; version: string; logo: string };
type ActiveGateway = { id: GatewayId; host: string; port: number; protocol: string; url: string; isLocal: boolean };
type ContractIds = { shellSrc: TxId; recycleBinInstance: TxId };

// ****************************************************************************
// Ar
// ****************************************************************************

class Ar {
  static appInfo: AppInfo;
  static activeGateway: ActiveGateway;
  static contractIds: ContractIds;

  static arweave: Arweave;
  static account = AccountMgr;
  static settings: SettingsMgr;

  static images = Images;
  static time = Time;

  static async checkInitialization() {
    if (!this.activeGateway) {
      throw new Error("Arweave manager not initialized.");
    }
  }

  static async checkWalletExistence() {
    if (!window.arweaveWallet) {
      throw new Error("No wallet connected. Please sign in first.");
    }
  }

  static async fetchGraphQL(graphQL: string, useGoldsky: boolean = false) {
    const url = useGoldsky ? "https://arweave-search.goldsky.com/graphql" : `${this.activeGateway.url}/graphql`;
    await this.checkInitialization();

    return fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ query: graphQL }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.errors) {
          throw new Error(`${generateErrorString("GraphQL", json)}`);
        }
        if (!json.data) {
          throw new Error(`${generateErrorString("GraphQL - no data", json)}`);
        }
        return json.data;
      });

    function generateErrorString(label: string, json: any) {
      return `\n${"-".repeat(30)}\n[${label}] ${JSON.stringify(json, null, 2)}`;
    }
  }

  // **************************************************************************
  // **************************************************************************

  static initialize(gatewayId: GatewayId = "arweave.net") {
    // --- appInfo ---
    this.appInfo = {
      name: String(process.env.REACT_APP_NAME),
      version: String(process.env.REACT_APP_VERSION),
      logo: String(process.env.REACT_APP_LOGO_URL),
    };

    // --- activeGateway ---
    this.activeGateway = {
      id: gatewayId,
      host: gateways[gatewayId].host,
      port: gateways[gatewayId].port,
      protocol: gateways[gatewayId].protocol,
      url: `${gateways[gatewayId].protocol}://${gateways[gatewayId].host}:${gateways[gatewayId].port}`,
      isLocal: gatewayId === "arlocal" || gatewayId === "arlocal.zephyrdev.xyz",
    };

    // --- contractIds ---
    switch (this.activeGateway.id) {
      case "arlocal":
        this.contractIds = {
          recycleBinInstance: String(process.env.REACT_APP_RECYCLE_BIN_INSTANCE_TXID_LOCAL),
          shellSrc: String(process.env.REACT_APP_SHELL_SRC_TXID_LOCAL),
        };
        break;
      case "arlocal.zephyrdev.xyz":
        this.contractIds = {
          recycleBinInstance: String(process.env.REACT_APP_RECYCLE_BIN_INSTANCE_TXID_ZEPPY),
          shellSrc: String(process.env.REACT_APP_SHELL_SRC_TXID_ZEPPY),
        };
        break;
      default:
        this.contractIds = {
          recycleBinInstance: String(process.env.REACT_APP_RECYCLE_BIN_INSTANCE_TXID_GLOBE),
          shellSrc: String(process.env.REACT_APP_SHELL_SRC_TXID_GLOBE),
        };
        break;
    }

    // --- arweave ---
    this.arweave = Arweave.init({
      host: gateways[gatewayId].host,
      port: gateways[gatewayId].port,
      protocol: gateways[gatewayId].protocol,
      timeout: 20000,
      logging: false,
    });

    // --- account ---
    const accountGateway = this.activeGateway.isLocal ? "arweave.net" : gatewayId;
    this.account.initialize(accountGateway);

    // --- settings ---
    this.settings = new SettingsMgr(gatewayId, false);
  }

  private constructor() {}
}

export default Ar;
