import { extract, FetchOptions, Params } from "@extractus/oembed-extractor";
import { Theme } from "redux/settings/settingsSlice.types";
import { convertResponseToJson } from "utils/fetch";

const THUMBNAIL_WIDTH = 640;
const THUMBNAIL_HEIGHT = 480;

// ****************************************************************************
// oembed-extractor
// ****************************************************************************

const OEMBED_FETCH_TIMEOUT_MS = 5000;

export async function fetchOembedFromProvider(url: string, theme: Theme) {
  const params: Params = {
    maxwidth: THUMBNAIL_WIDTH,
    maxheight: THUMBNAIL_HEIGHT,
    theme: theme,
    lang: "en",
  };

  const fetchOptions: FetchOptions = {
    signal: AbortSignal.timeout(OEMBED_FETCH_TIMEOUT_MS),
  };

  return extract(url, params, fetchOptions);
}

// ****************************************************************************
// Embedly
// ****************************************************************************

const API_KEY = process.env.REACT_APP_EMBEDLY_API_KEY;

export async function fetchOembedFromEmbedly(url: string) {
  const params = new URLSearchParams({
    key: String(API_KEY),
    url: url, // TODO: need to encode this?
    width: String(THUMBNAIL_WIDTH),
    height: String(THUMBNAIL_HEIGHT),
  });

  return fetch(`https://api.embed.ly/1/oembed?${params.toString()}`).then(convertResponseToJson);
}
