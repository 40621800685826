import Ar from "arweave/ar";
import postsSlice from "redux/posts/postsSlice";
import { gqlDeletedItems } from "redux/recycleBin/query";
import recycleBinSlice from "redux/recycleBin/slice";
import { AppDispatch, GetState } from "redux/store";
import { warp } from "warp/warpServer";

export function fetchDeletionState(payload: { txId: TxId; userId?: TxId }[]) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    return warp.contract(Ar.contractIds.recycleBinInstance).viewState({ function: "isInBin", payload });
  };
}

export function fetchMyBin() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      const state = getState();
      const address = state.wallet.address;

      if (!address) {
        dispatch(recycleBinSlice.actions.myBinFetchFailed("Wallet not connected"));
        return;
      }

      if (state.recycleBin.myBin.fetching) {
        return;
      }

      dispatch(recycleBinSlice.actions.myBinFetchStarted());

      await Ar.checkInitialization();
      await Ar.checkWalletExistence();

      const contract = warp.contract(Ar.contractIds.recycleBinInstance);
      const list: { result: any } = await contract.viewState({ function: "list", userId: address });

      const ids = Object.keys(list.result);
      const meta = await Ar.fetchGraphQL(gqlDeletedItems(ids));
      dispatch(postsSlice.actions.storePosts(meta.transactions)); // [ ] Assume it is all posts for now, no comments.

      dispatch(recycleBinSlice.actions.myBinFetchDone({ list: list.result }));
    } catch (err: any) {
      dispatch(recycleBinSlice.actions.myBinFetchFailed(err.message));
    }
  };
  // [ ] Pagination
}

export function deleteItem(txId: TxId) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      dispatch(recycleBinSlice.actions.deleteStarted(txId));

      await warp
        .contract(Ar.contractIds.recycleBinInstance)
        .connect("use_wallet")
        .writeInteraction({ function: "addToBin", txId: txId, timestampMs: Date.now() });

      dispatch(postsSlice.actions.removePost(txId));
      dispatch(recycleBinSlice.actions.deleteDone(txId));
    } catch (err: any) {
      dispatch(recycleBinSlice.actions.deleteFailed(txId));
      throw err;
    }
  };
}

export function restoreItem(txId: TxId) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      dispatch(recycleBinSlice.actions.restoreStarted(txId));

      await warp
        .contract(Ar.contractIds.recycleBinInstance)
        .connect("use_wallet")
        .writeInteraction({ function: "removeFromBin", txId: txId });

      dispatch(recycleBinSlice.actions.restoreDone(txId));
    } catch (err: any) {
      dispatch(recycleBinSlice.actions.restoreFailed(txId));
      throw err;
    }
  };
}
