import RecycleBin from "pages/dev/recycleBin";
import React from "react";

import Account from "./account";
import GatewaySelect from "./gatewaySelect";
import Log from "./log";
import SaveSettings from "./saveSettings";
import "./style.scss";

const DevPage = () => {
  return (
    <div className="dev-page-bg">
      <div className="dev-page">
        <h1>Dev Page</h1>
        <div className="dev-page__app-version">{process.env.REACT_APP_BUILD_REV}</div>
        <GatewaySelect />
        <RecycleBin />
        <SaveSettings />
        <Account />
        <Log />
      </div>
    </div>
  );
};

export default DevPage;
