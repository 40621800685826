import Comment from "components/posts/post/comment";
import React, { useEffect } from "react";
import { getCommentQueryKey } from "redux/comments/helpers";
import { fetchTxs, USE_FLAT_STYLE_REPLIES } from "redux/comments/slice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { hideThread } from "redux/ui/uiSlice";

import "./style.scss";

interface Props {
  postId: string;
  commentId: string;
}

const Replies = (props: Props) => {
  const { postId, commentId } = props;
  const dispatch = useAppDispatch();

  const queryKey = getCommentQueryKey(postId, "", commentId); // Use groupId for flat-style replies
  const commentIds = useAppSelector((state) => state.comments.byQuery.commentIds[queryKey]);
  const hasMore = useAppSelector((state) => Boolean(state.comments.byQuery.cursor[queryKey]));
  const replyCount = useAppSelector((state) => state.comments.byId.replyCount[commentId]);

  const numComments = commentIds ? commentIds.length : 0;
  const [prevNumComments, setPrevNumComments] = React.useState(numComments);

  const showMore = React.useCallback(() => {
    dispatch(fetchTxs(postId, "", commentId));
  }, [dispatch, postId, commentId]);

  useEffect(() => {
    dispatch(fetchTxs(postId, "", commentId));
  }, [dispatch, postId, commentId]);

  useEffect(() => {
    if (prevNumComments !== numComments) {
      setPrevNumComments(numComments);
    }
  }, [prevNumComments, numComments]);

  return (
    <div className="replies">
      <div className="replies__back" onClick={() => dispatch(hideThread({ postId }))}>
        {`<- Replies (${replyCount})`}
      </div>

      <div className="replies__wrapper">
        <div className="replies__main">
          <Comment commentId={commentId} groupId={commentId} hideReplyCount />
        </div>

        <div className="replies__list">
          {commentIds?.map((id: string) => (
            <Comment key={id} commentId={id} groupId={commentId} hideReplyCount={USE_FLAT_STYLE_REPLIES} />
          ))}
        </div>
      </div>

      {hasMore && (
        <button className="replies__load-more" onClick={showMore}>
          More
        </button>
      )}
    </div>
  );
};

export default Replies;
