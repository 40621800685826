import "./style.scss";
import { ConnectButton } from "arweave-wallet-kit";
import Ar from "arweave/ar";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface Props {}

function WalletConnect(props: Props) {
  const connected = useAppSelector((state) => state.wallet.connected);
  const lastMintedTs = useAppSelector((state) => state.wallet.lastMintedTs);

  // TODO: move this to userSlice -----
  const [profile, setProfile] = React.useState<ArProfile | null>(null);
  const [handle, setHandle] = React.useState<string>("");
  // ----------------------------------

  const avatarUrl = (profile?.avatar || "").replace("ar://", "https://arweave.net/");

  React.useEffect(() => {
    if (connected) {
      Ar.account
        .getActiveAccount()
        .then((account: ArAccount) => {
          setProfile(account.profile);
          setHandle(account.handle);
        })
        .catch((err) => {
          console.log(err.message);
          setProfile(null);
          setHandle("");
        });
    } else {
      setProfile(null);
      setHandle("");
    }
  }, [connected]);

  return (
    <div className="wallet-connect__container">
      <ConnectButton
        key={lastMintedTs}
        profileModal
        showBalance
        showProfilePicture
        useAns={false}
        avatarUrl={avatarUrl}
        uniqueHandle={handle}
        othentApiID={process.env.REACT_APP_OTHENT_API_ID}
      />
    </div>
  );
}

export default WalletConnect;
