/**
 * Wrapper over <img> that handles `ar://` images, progressively trying all
 * gateways.
 */
import React from "react";

interface Props {
  src: any;
  fallback?: string;
  [key: string]: any;
}

function Image(props: Props) {
  const { src, fallback, onError, ...rest } = props;

  const [srcList, setSrcList] = React.useState<string[]>([]);

  React.useEffect(() => {
    const list = [];
    if (src) {
      if (src.startsWith("ar://")) {
        // TODO: optimize this. Don't need to try all if not using testnet.
        list.push(src.replace("ar://", "http://localhost:1984/"));
        list.push(src.replace("ar://", "https://arlocal.zephyrdev.xyz/"));
        list.push(src.replace("ar://", "https://arweave.net/"));
      }
    }

    list.push(src);

    if (fallback) {
      list.push(fallback);
    }

    setSrcList(list);
  }, [src, fallback]);

  return (
    <img
      src={srcList[0]}
      {...rest}
      onError={() => {
        if (onError) {
          onError();
        }
        if (srcList.length > 1) {
          setSrcList(srcList.slice(1));
        }
      }}
    />
  );
}

export default Image;
