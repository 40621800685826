import "./style.scss";
import Ar from "arweave/ar";
import CommentAdd from "components/posts/post/commentAdd";
import dayjs from "lib/dayjs";
import React from "react";
import { Link } from "react-router-dom";
import Icon from "components/icons";
import ShellName from "components/shellName";
import { fetchContent } from "redux/comments/slice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { showThread } from "redux/ui/uiSlice";
import { fetchUserByAddress } from "redux/users/usersSlice";

interface Props {
  commentId: string;
  groupId?: string; // @see CommentTags
  hideReplyCount?: boolean;
}

const Comment = (props: Props) => {
  const { commentId, groupId, hideReplyCount } = props;

  const [likes] = React.useState(() => Math.floor(Math.random() * 301));

  const dispatch = useAppDispatch();
  const comment = useAppSelector((state) => state.comments.byId.comments[commentId]);
  const replyCount = useAppSelector((state) => state.comments.byId.replyCount[commentId]);
  const directParentComment = useAppSelector((state) =>
    comment.parentId ? state.comments.byId.comments[comment.parentId] : null
  );

  const profile = useAppSelector((state) => state.users.profilesById[comment.ownerAddress]);
  const userHandle = Ar.account.getUserHandle(profile)!;
  const avatarUrl = Ar.images.resolveAvatar(profile?.profile.avatar);

  const dt = dayjs(comment.timestampMs);
  const timestampDisplay = comment.timestampMs ? `${dt.fromNow()}` : "";
  const timestampTooltip = comment.timestampMs ? `${dt.format("HH:mm • MMM D, YYYY")}` : "";

  const [replying, setReplying] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchContent(commentId));
  }, [commentId, dispatch]);

  React.useEffect(() => {
    dispatch(fetchUserByAddress(comment.ownerAddress));
  }, [comment.ownerAddress, dispatch]);

  // [x] Hide comments with empty profiles. All zephyr users should have a profile from the sign-up flow.

  return (
    <div className="comment">
      {profile && (
        <>
          <Link to={userHandle}>
            <img src={avatarUrl} className="comment__author-thumb" loading="lazy" />
          </Link>
          <div className="comment__text">
            <div className="comment__author-name">
              <ShellName
                shell={{
                  type: "user",
                  title: userHandle,
                  id: profile.addr,
                  avatar: avatarUrl,
                }}
              />
            </div>
            <div className="comment__date" title={timestampTooltip}>
              {timestampDisplay}
            </div>
            <p>{comment.content}</p>
            {directParentComment && directParentComment.id !== groupId && (
              <ReplyTargetComment targetCommentId={directParentComment.id} />
            )}
            <div className="comment__actions">
              {hideReplyCount ? null : (
                <>
                  {replyCount !== undefined && (
                    <div
                      className="comment__reply-count"
                      onClick={() => dispatch(showThread({ postId: comment.postId, threadId: commentId }))}
                    >
                      {replyCount === 1 ? "Reply" : "Replies"} ({replyCount})
                    </div>
                  )}
                </>
              )}
              <div className="comment__action comment__action--reply" onClick={() => setReplying(true)}>
                <Icon icon="reply" />
                Reply
              </div>
              <div className="comment__action comment__action--rate">
                <Icon icon="like" />
                {likes}
                <Icon icon="dislike" />
              </div>
            </div>

            {replying && (
              <div className="comment__add-reply">
                <CommentAdd postId={comment.postId} parentId={commentId} groupId={groupId} setReplying={setReplying} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

// ****************************************************************************
// ReplyTargetComment
// ****************************************************************************

interface RTCProps {
  targetCommentId: string;
}

function ReplyTargetComment(props: RTCProps) {
  const { targetCommentId } = props;

  const comment = useAppSelector((state) => state.comments.byId.comments[targetCommentId]);
  const profile = useAppSelector((state) => state.users.profilesById[comment.ownerAddress]);
  const userHandle = Ar.account.getUserHandle(profile)!;
  const avatarUrl = Ar.images.resolveAvatar(profile?.profile.avatar);

  return profile ? (
    <div className="reply-target">
      ↳
      <div className="reply-target__data">
        <img src={avatarUrl} className="reply-target__avatar" loading="lazy" />
        <div className="reply-target__author-name">{userHandle}</div>
        <div className="reply-target__message">{comment.content}</div>
      </div>
    </div>
  ) : null;
}

export default Comment;
