import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { fetchOembedFromEmbedly, fetchOembedFromProvider } from "redux/oembed/helpers";
import { GetState } from "redux/store";
import { MakeState } from "redux/utils";

export interface OembedState {
  byUrl: { [url: string]: OembedDataTypes };
}

const oembedSlice = createSlice({
  name: "oembed",
  initialState: MakeState<OembedState>({
    byUrl: {},
  }),
  reducers: {
    oembedFetched: (state, action) => {
      const { url, oembed } = action.payload;
      state.byUrl[url] = oembed;
    },
  },
});

// ****************************************************************************
// fetchOembedForUrl
// ****************************************************************************

export function fetchOembedForUrl(url: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    if (!url) {
      throw new Error("null url");
    } else if (state.oembed.byUrl[url]) {
      return state.oembed.byUrl[url];
    }

    return fetchOembedFromEmbedly(url)
      .then((embedlyData) => {
        dispatch(oembedSlice.actions.oembedFetched({ url, oembed: embedlyData }));
        return embedlyData;
      })
      .catch((embedlyError) => {
        console.log(embedlyError);

        return fetchOembedFromProvider(url, state.settings.theme)
          .then((providerData) => {
            dispatch(oembedSlice.actions.oembedFetched({ url, oembed: providerData }));
            return providerData;
          })
          .catch((providerError) => {
            throw providerError;
          });
      });
  };
}

// ****************************************************************************
// ****************************************************************************

export default oembedSlice;
