import React from "react";
import * as AR_CFG from "arweave/config";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { loadSettings, saveSettings } from "redux/settings/settingsSlice";
import { appendDevPageLog as append } from "redux/ui/uiSlice";

function SaveSettings() {
  const dispatch = useAppDispatch();
  const gatewayId = useAppSelector((state) => state.settings.gatewayId);

  function handleSaveSettings() {
    const gateway = AR_CFG.gateways[gatewayId];
    const urlPrefix = `${gateway.protocol}://${gateway.host}:${gateway.port}/`;

    dispatch(saveSettings())
      .unwrap()
      .then((txId) => dispatch(append(`${urlPrefix}${txId}`)))
      .then(() => dispatch(append({ log: "Settings saved.", separate: false })))
      .catch((err) => dispatch(append(err.message)));
  }

  function handleLoadSettings() {
    dispatch(loadSettings())
      .unwrap()
      .then((fetchedSettings) => dispatch(append(fetchedSettings)))
      .then(() => dispatch(append({ log: "Settings loaded.", separate: false })))
      .catch((err) => dispatch(append(err.message)));
  }

  return (
    <div className="dev-page__row">
      <label>Settings (with ArConnect Extension only)</label>
      <button onClick={handleSaveSettings}>Save to chain</button>
      <button onClick={handleLoadSettings}>Load from chain</button>
    </div>
  );
}

export default SaveSettings;
