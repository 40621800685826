import { createSlice } from "@reduxjs/toolkit";
import Ar from "arweave/ar";
import { AppDispatch, GetState } from "redux/store";
import { MakeState } from "redux/utils";

import {
  checkArweaveApiResponse,
  createPendingComment,
  getCommentQueryKey,
  getTransactionsFromResponse,
  resolveSortBy,
} from "./helpers";
import { CommentsState, Comment, FetchContentDone, FetchTxsFailed, FetchTxsDone, CreateDone } from "./types";
import { getCommentsQuery, getReplyCountQuery } from "./query";

export const COMMENTS_PAGE_SIZE = 10;
export const USE_FLAT_STYLE_REPLIES = true;

const commentsSlice = createSlice({
  name: "comments",
  initialState: MakeState<CommentsState>({
    byId: {
      comments: {},
      replyCount: {},
    },
    byQuery: {
      isFetching: {},
      cursor: {},
      commentIds: {},
    },
    createStarted: false,
    createError: null,
    postsUi: {},
  }),
  reducers: {
    createStarted: (state) => {
      state.createStarted = true;
    },
    createDone: (state, action: CreateDone) => {
      const { pendingComment: pc } = action.payload;
      const queryKey = getCommentQueryKey(pc.postId, pc.parentId, pc.groupId);
      const flatQueryKey = getCommentQueryKey(pc.postId, "", pc.groupId);

      state.createStarted = false;
      state.byId.comments[pc.id] = pc;

      if (state.byQuery.commentIds[queryKey]) {
        state.byQuery.commentIds[queryKey].splice(0, 0, pc.id);
      } else {
        state.byQuery.commentIds[queryKey] = [pc.id];
      }

      if (state.byQuery.commentIds[flatQueryKey]) {
        state.byQuery.commentIds[flatQueryKey].splice(0, 0, pc.id);
      } else {
        state.byQuery.commentIds[flatQueryKey] = [pc.id];
      }

      if (pc.parentId) {
        if (state.byId.replyCount[pc.parentId]) {
          state.byId.replyCount[pc.parentId]! += 1;
        } else {
          state.byId.replyCount[pc.parentId] = 1;
        }
      }
    },
    createFailed: (state, action) => {
      state.createError = action.payload;
      state.createStarted = false;
    },
    fetchTxsStarted: (state, action) => {
      const { queryKey: key } = action.payload;
      state.byQuery.isFetching[key] = true;
    },
    fetchTxsDone: (state, action: FetchTxsDone) => {
      const { queryKey: key, result } = action.payload;
      const comments = { ...state.byId.comments };
      const commentIdsForKey = (state.byQuery.commentIds[key] || []).slice();
      let cursor;

      result.edges.forEach((edge: any) => {
        const comment: Comment = {
          id: edge.node.id,
          ownerAddress: edge.node.owner.address,
          contentType: edge.node.tags.find((tag: any) => tag.name === "Content-Type")?.value,
          postId: edge.node.tags.find((tag: any) => tag.name === "Root-Source")?.value,
          parentId: edge.node.tags.find((tag: any) => tag.name === "Data-Source")?.value,
          groupId: edge.node.tags.find((tag: any) => tag.name === "Group-Source")?.value,
          timestampMs: edge.node.tags.find((tag: any) => tag.name === "Unix-Time")?.value * 1000,
          cursor: edge.cursor,
        };

        comments[comment.id] = comment;
        if (!commentIdsForKey.includes(comment.id)) {
          commentIdsForKey.push(comment.id);
        }
        cursor = edge.cursor;
      });

      return {
        ...state,
        byQuery: {
          ...state.byQuery,
          isFetching: { ...state.byQuery.isFetching, [key]: false },
          cursor: { ...state.byQuery.cursor, [key]: result.pageInfo.hasNextPage ? cursor : null },
          commentIds: { ...state.byQuery.commentIds, [key]: commentIdsForKey },
        },
        byId: { ...state.byId, comments },
      };
    },
    fetchTxsFailed: (state, action: FetchTxsFailed) => {
      const { queryKey: key } = action.payload;
      state.byQuery.isFetching[key] = false;
    },
    fetchReplyCountDone: (state, action) => {
      const { transactions } = action.payload;
      transactions?.edges.forEach((edge: any) => {
        const parentId = edge.node.tags.find((tag: any) => tag.name === "Group-Source")?.value;
        if (parentId) {
          if (state.byId.replyCount[parentId]) {
            state.byId.replyCount[parentId]! += 1;
          } else {
            state.byId.replyCount[parentId] = 1;
          }
        }
      });
    },
    fetchContentDone: (state, action: FetchContentDone) => {
      const { commentId, content } = action.payload;
      if (state.byId.comments[commentId]) {
        state.byId.comments[commentId].content = content;
      }
    },
    clearQuery: (state, action) => {
      const { queryKey: key } = action.payload;
      delete state.byQuery.isFetching[key];
      delete state.byQuery.cursor[key];
      (state.byQuery.commentIds[key] || []).forEach((id) => {
        delete state.byId.replyCount[id];
      });
      delete state.byQuery.commentIds[key];
    },
    togglePostSortBy: (state, action) => {
      const { postId } = action.payload;
      const curSortBy = state.postsUi[postId]?.sortBy || "desc";
      return {
        ...state,
        postsUi: {
          ...state.postsUi,
          [postId]: {
            ...state.postsUi[postId],
            sortBy: curSortBy === "desc" ? "asc" : "desc",
          },
        },
      };
    },
  },
});

// ****************************************************************************
// create
// ****************************************************************************

/**
 * Creates an Ar-transaction comment.
 *
 * @param text The comment text.
 * @param postId The Post ID being commented on.
 * @param parentId Direct parent's commentId, or the postId if it's a top-level comment.
 * @param groupId If it's a reply (could be several levels down), provide the ancestor top-level commentId.
 */
export function create(text: string, postId: string, parentId: string, groupId?: string) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const usingArlocal = state.settings.gatewayId.startsWith("arlocal");

    try {
      // -- Construct transaction --
      const tags: CommentTags = {
        "App-Name": Ar.appInfo.name,
        "App-Version": Ar.appInfo.version,
        "Unix-Time": Math.floor(Date.now() / 1000).toString(),
        "Content-Type": "text/plain",
        "Data-Protocol": "Comment",
        "Root-Source": postId,
        "Data-Source": parentId,
        "Group-Source": groupId ? groupId : parentId,
      };

      dispatch(commentsSlice.actions.createStarted());
      await Ar.checkWalletExistence();
      const tx = await Ar.arweave.createTransaction({ data: text });
      Object.entries(tags).forEach(([name, value]) => value && tx.addTag(name, value));

      // -- Sign and post --
      if (usingArlocal) {
        await Ar.arweave.transactions.sign(tx);
        await Ar.arweave.transactions.post(tx);
      } else {
        await window.arweaveWallet.dispatch(tx);
      }

      // -- Create local pending comment for immediate feedback --
      const ownerAddress = await Ar.arweave.wallets.getAddress();
      const pendingComment = createPendingComment(tx.id, ownerAddress, "text/plain", postId, parentId, groupId);

      // -- Store result --
      dispatch(commentsSlice.actions.createDone({ pendingComment }));
    } catch (err: any) {
      dispatch(commentsSlice.actions.createFailed(err));
      throw err;
    }
  };
}

// ****************************************************************************
// fetchTxs
// ****************************************************************************

export function fetchTxs(postId: string, parentId: string, groupId?: string) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const queryKey = getCommentQueryKey(postId, parentId, groupId);
    const isFetching = state.comments.byQuery.isFetching[queryKey];
    const cursor = state.comments.byQuery.cursor[queryKey];
    const hasNextPage = cursor !== null;

    if (!hasNextPage || isFetching) {
      return;
    }

    dispatch(commentsSlice.actions.fetchTxsStarted({ queryKey }));
    const sortBy = resolveSortBy(postId, parentId, state);

    try {
      const query = getCommentsQuery({ cursor, postId, parentId, groupId, sortBy });
      const response = await Ar.arweave.api.post("/graphql", { query });
      await checkArweaveApiResponse(response);
      const result = response?.data?.data?.transactions;
      dispatch(commentsSlice.actions.fetchTxsDone({ queryKey, result }));
      dispatch(fetchReplyCount(result.edges.map((edge: any) => edge.node.id)));
      return result;
    } catch (error) {
      dispatch(commentsSlice.actions.fetchTxsFailed({ queryKey }));
      throw error;
    }
  };
}

// ****************************************************************************
// fetchReplyCount
// ****************************************************************************

/**
 * Given the list of comment IDs, determine the number of replies for each.
 *
 * [ ] This doesn't scale and won't be correct beyond 100 entries anyway due to
 *     graphQL limit. It's here just to get things going.
 *
 * @param commentIds
 */
export function fetchReplyCount(commentIds: string[]) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      const response = await Ar.arweave.api.post("/graphql", { query: getReplyCountQuery(commentIds) });
      await checkArweaveApiResponse(response);
      const transactions = await getTransactionsFromResponse(response);
      dispatch(commentsSlice.actions.fetchReplyCountDone({ transactions }));
    } catch (error) {
      throw error;
    }
  };
}

// ****************************************************************************
// fetchContent
// ****************************************************************************

export function fetchContent(commentId: string) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const comment = state.comments.byId.comments[commentId];

    if (!comment || comment.content) {
      // Transaction not fetched first, or Content already fetched.
      return;
    }

    try {
      const response = await Ar.arweave.api.get(`/${commentId}`);
      await checkArweaveApiResponse(response);
      dispatch(commentsSlice.actions.fetchContentDone({ commentId, content: response.data }));
    } catch (error) {
      throw error;
    }
  };
}

// ****************************************************************************
// toggleTopLevelOrder
// ****************************************************************************

export function toggleTopLevelOrder(postId: string) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const queryKey = getCommentQueryKey(postId, postId, postId);
    dispatch(commentsSlice.actions.clearQuery({ queryKey }));
    dispatch(commentsSlice.actions.togglePostSortBy({ postId }));
    dispatch(fetchTxs(postId, postId, postId));
  };
}

// ****************************************************************************
// ****************************************************************************

export default commentsSlice;
