import "./style.scss";
import Ar from "arweave/ar";
import Icon from "components/icons";
import Image from "components/image";
import Spinner from "components/spinner";
import React from "react";
import ReactDOM from "react-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import searchSlice from "redux/search/slice";
import { searchPosts, searchShells, searchUsers } from "redux/search/thunks";
import { useDebouncedCallback } from "use-debounce";
import DEFAULT_POST_THUMB from "zephyr-resources/defaultPost.jpg";

const SEARCH_NAME = {
  POST: "topSearchBar__post",
  SHELL: "topSearchBar__shell",
  USERS: "topSearchBar__users",
};

const Search = () => {
  const dispatch = useAppDispatch();
  const [query, setQuery] = React.useState("");
  const [showPopup, setShowPopup] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const fuzzyState = React.useState(false);
  const [fuzzy] = fuzzyState;

  const isSearching = useAppSelector((state) => state.search.isSearching);
  const isSearchingAny = isSearching[SEARCH_NAME.POST]; // || isSearching[SEARCH_NAME.SHELL];
  const postIds = useAppSelector((state) => state.search.results[SEARCH_NAME.POST]?.txIds) || [];
  const shellIds = useAppSelector((state) => state.search.results[SEARCH_NAME.SHELL]?.txIds) || [];
  const userIds = useAppSelector((state) => state.search.results[SEARCH_NAME.USERS]?.txIds) || [];

  const setQueryDebounced = useDebouncedCallback((value) => {
    setQuery(value);
  }, 1000);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isSearching = e.target.value.length > 0;
    setShowPopup(isSearching);
    dispatch(searchSlice.actions.setIsSearching({ name: SEARCH_NAME.POST, isSearching: true }));
    setQueryDebounced(e.target.value);
  };

  const handleInputReset = () => {
    setShowPopup(false);
    if (inputRef.current) {
      inputRef.current.value = "";
      setQuery("");
    }
  };

  React.useEffect(() => {
    dispatch(searchPosts(SEARCH_NAME.POST, query, !fuzzy));
    dispatch(searchShells(SEARCH_NAME.SHELL, query, !fuzzy));
    dispatch(searchUsers(SEARCH_NAME.USERS, query, !fuzzy));
  }, [dispatch, fuzzy, query]);

  return (
    <>
      <div className="search-bar">
        <Icon icon="search" color="white" size={16} />
        <input type="text" ref={inputRef} onChange={handleInputChange} />
        {query && <ClearButton onClick={handleInputReset} />}
      </div>
      {showPopup
        ? ReactDOM.createPortal(
          <div className="search-popup" onClick={() => setShowPopup(false)}>
            <div className="search-popup__content" onClick={(e) => e.stopPropagation()}>
              <div className="search-popup__header">
                {isSearchingAny && <Spinner forceDarkBg size="small" />}
                <SearchMode state={fuzzyState} />
              </div>
              <div className="search-popup__section">
                <SectionTitle title="Posts" />
                {postIds.length > 0 && postIds.map((id) => <PostPreview id={id} key={id} />)}
                {postIds.length < 1 && "No posts found."}
              </div>
              <div className="search-popup__section">
                <SectionTitle title="Shells" />
                {shellIds.length > 0 && shellIds.map((id) => <ShellPreview id={id} key={id} />)}
                {shellIds.length < 1 && "No shells found."}
              </div>
              <div className="search-popup__section">
                <SectionTitle title="Users" />
                {userIds.length > 0 && userIds.map((id) => <UserPreview id={id} key={id} />)}
                {userIds.length < 1 && "No users found."}
              </div>
              <div className="search-popup__footer">
                {fuzzy && <div>[Note] Goldsky is main-net only</div>}
                <div>[Debug] {Ar.activeGateway.url}</div>
              </div>
            </div>
          </div>,
          document.body
        )
        : null}
    </>
  );
};

export default Search;

// ****************************************************************************
// ****************************************************************************

function ClearButton(props: { onClick: () => void }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="input-reset"
      viewBox="0 0 24 24"
      width="16"
      height="16"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden="true"
      onClick={props.onClick}
    >
      <g>
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </g>
    </svg>
  );
}

function SectionTitle(props: { title: string }) {
  return (
    <div className="search-popup__section-title">
      <label>{props.title}</label>
      <hr />
    </div>
  );
}

function SearchMode(props: { state: React.ComponentState }) {
  const [fuzzy, setFuzzy] = props.state;
  return (
    <div className="search-mode">
      <div className={`search-mode__text ${fuzzy ? "search-mode__text--active" : ""}`}>Fuzzy(Goldsky)</div>
      <label className="switch">
        <input type="checkbox" checked={fuzzy} onChange={() => setFuzzy((prev: boolean) => !prev)} />
        <span className="slider"></span>
      </label>
    </div>
  );
}

function PostPreview(props: { id: string }) {
  const post = useAppSelector((state) => state.posts.postsById[props.id]);

  const postTags: PostTags = React.useMemo(() => {
    return post?.tags.reduce((acc: any, tag) => {
      acc[tag.name] = tag.value;
      return acc;
    }, {});
  }, [post]);

  const ownerId = post?.owner?.address;
  const profileData = useAppSelector((state) => state.users.profilesById[ownerId]);
  const authorName = Ar.account.getUserHandle(profileData)!;

  return (
    <div className="post-preview" title={props.id}>
      <div className="post-preview__thumb">
        <Image src={postTags["Thumbnail-URL"]} fallback={DEFAULT_POST_THUMB} />
      </div>
      <div>
        <div className="post-preview__title">{postTags["Title"]}</div>
        <div className="post-preview__author">u/{authorName}</div>
      </div>
    </div>
  );
}

function ShellPreview(props: { id: string }) {
  const SHELL_NAME_TAG = process.env.REACT_APP_SHELL_CAT_TAG_NAME!;
  const shellMetas = useAppSelector((state) => state.search.shellMetasTEMP[props.id]);

  const tags = React.useMemo(() => {
    return shellMetas?.tags.reduce((acc: any, tag) => {
      acc[tag.name] = tag.value;
      return acc;
    }, {});
  }, [shellMetas]);

  const ownerId = shellMetas?.owner?.address;
  const profileData = useAppSelector((state) => state.users.profilesById[ownerId]);
  const authorName = Ar.account.getUserHandle(profileData)!;

  return (
    <div className="post-preview" title={JSON.stringify(shellMetas, null, 2)}>
      <div className="post-preview__thumb">
        <Image src="" fallback={DEFAULT_POST_THUMB} />
      </div>
      <div>
        <div className="post-preview__title">Shell Name: {tags[SHELL_NAME_TAG]}</div>
        <div className="post-preview__author">u/{authorName}</div>
      </div>
    </div>
  );
}

function UserPreview(props: { id: string }) {
  const profile = useAppSelector((state) => state.users.profilesById[props.id]);
  const userHandle = Ar.account.getUserHandle(profile)!;

  return profile ? (
    <div className="post-preview" title={JSON.stringify(profile, null, 2)}>
      <div className="post-preview__thumb">
        <Image src={profile?.profile.avatar} fallback={DEFAULT_POST_THUMB} />
      </div>
      <div>
        <div className="post-preview__author">u/{userHandle}</div>
      </div>
    </div>
  ) : null;
}
