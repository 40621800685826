import { useEffect } from "react";
import PostButtons from "components/postButtons";
import ContentContainer from "components/contentContainer";
import { utilSetWallpaper } from "utils/setTheme";
import { fetchPosts } from "redux/posts/postsSlice";
import { getOptions } from "arweave/txQuery";
import { useAppDispatch } from "redux/hooks";

const HomePage = () => {
  const appDispatch = useAppDispatch();

  useEffect(() => {
    appDispatch(fetchPosts(getOptions(undefined, undefined)));
  }, [appDispatch]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    utilSetWallpaper(`wp0`);
  }, []);

  return (
    <>
      <PostButtons />
      <h1>Hatching Now...</h1>
      <ContentContainer contentId={""} />
    </>
  );
};

export default HomePage;
