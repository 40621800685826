import classnames from "classnames";
import React from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import uploadImage from "redux/uploadImage/slice";

interface Props {
  id: string;
}

export default function Tab(props: Props) {
  const { id } = props;
  const dispatch = useAppDispatch();
  const mode = useAppSelector((state) => state.uploadImage[id]?.mode);

  return (
    <div className="upload-image__tab-section">
      <div
        className={classnames("upload-image__tab", { "upload-image__tab--on": mode === "upload" })}
        onClick={() => dispatch(uploadImage.actions.setMode({ id, mode: "upload" }))}
      >
        Upload File
      </div>
      <div
        className={classnames("upload-image__tab", { "upload-image__tab--on": mode === "url" })}
        onClick={() => dispatch(uploadImage.actions.setMode({ id, mode: "url" }))}
      >
        Enter URL
      </div>
    </div>
  );
}
