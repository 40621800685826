import { combineReducers } from "@reduxjs/toolkit";
import localForage from "localforage";
import { persistReducer } from "redux-persist";
import commentsSlice from "redux/comments/slice";
import oembedSlice from "redux/oembed/slice";
import * as postForm from "redux/postForm/slice";
import postsSlice from "redux/posts/postsSlice";
import recycleBinSlice from "redux/recycleBin/slice";
import searchSlice from "redux/search/slice";
import settingsSlice from "redux/settings/settingsSlice";
import usersSlice from "redux/users/usersSlice";
import modalSlice from "redux/modal/modalSlice";
import shellFormSlice from "redux/shellForm/slice";
import uiSlice from "redux/ui/uiSlice";
import uploadImageSlice from "redux/uploadImage/slice";
import walletSlice from "redux/wallet/slice";
import shellsReducer from "redux/shells/slice";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import createFilter from "redux-persist-transform-filter";

// ****************************************************************************
// Persistence configuration
// ****************************************************************************

const rootPersistConfig = {
  key: "root",
  version: 1,
  storage: localForage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["settings"],
  transforms: [
    createFilter("shells", ["recentShells", "ownShells", "modShells"]),
    /* createBlacklistFilter('settings', ['loadedLanguages']), */
  ],
};

// ****************************************************************************
// Root reducer
// ****************************************************************************

const rootReducer = combineReducers({
  comments: commentsSlice.reducer,
  modal: modalSlice.reducer,
  oembed: oembedSlice.reducer,
  postForm: persistReducer<postForm.ReducerType>(postForm.persistCfg, postForm.reducer),
  posts: postsSlice.reducer,
  recycleBin: recycleBinSlice.reducer,
  search: searchSlice.reducer,
  settings: settingsSlice.reducer,
  shellForm: shellFormSlice.reducer,
  shells: shellsReducer.reducer,
  ui: uiSlice.reducer,
  uploadImage: uploadImageSlice.reducer,
  users: usersSlice.reducer,
  wallet: walletSlice.reducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;

// ****************************************************************************
// ****************************************************************************

const finalReducer = persistReducer<RootReducer>(rootPersistConfig, rootReducer);
export default finalReducer;
