import classnames from "classnames";
import { useAppSelector } from "redux/hooks";
import { selectProfileForId } from "redux/users/usersSlice";
import usePathPage from "effects/use-path-page-name";
import { useGetScreenOrientation, useGetScreenSize } from "effects/use-screen-details";
import ShellMeta from "pages/shell/meta";
import UserMeta from "pages/user/meta";
import Posts from "components/posts";
import PostsFilter from "components/posts/filter";
import "./style.scss";

interface Props {
  contentId: string;
}

const ContentContainer = (props: Props) => {
  const { contentId } = props;
  const page = usePathPage();
  const size = useGetScreenSize();
  const orientation = useGetScreenOrientation();
  const shellData = useAppSelector((state) => selectProfileForId(state, contentId));

  return (
    <section className="shell-page__content">
      <div
        className={classnames("shell-page__content__main", {
          "shell-page__content__main--home": page === "home",
        })}
      >
        {page === "home" && <PostsFilter />}
        <Posts page={page} param={contentId} />
      </div>
      {/* can add a homepage meta here. homepage feed currently passes no contentHandle*/}
      {contentId && page === "shell" && size !== "mobile" && orientation !== "portrait" && (
        <div className="shell-page__content__meta">
          <ShellMeta handle={contentId} />
        </div>
      )}
      {contentId && page === "user" && size !== "mobile" && orientation !== "portrait" && (
        <div className="shell-page__content__meta">
          <UserMeta user={shellData} />
        </div>
      )}
    </section>
  );
};

export default ContentContainer;
