import { useParams } from "react-router-dom";
import PostButtons from "components/postButtons";
import ContentContainer from "components/contentContainer";
import { utilSetWallpaper } from "utils/setTheme";
import { useAppDispatch } from "redux/hooks";
import "./style.scss";
import { useEffect } from "react";
// import { useSelector } from "react-redux";

import {
  // PostsState,
  // selectBottomCursorForId,
  // selectErrorForId,
  // selectHasNextPageForId,
  // selectItemsForId,
  // selectLoadingForId,
  fetchPosts,
} from "redux/posts/postsSlice";
import { getOptions } from "arweave/txQuery";

const ShellPage = () => {
  const { shellHandle = "" } = useParams();
  const appDispatch = useAppDispatch();

  // use these for pagination
  // const posts = useSelector((state: RootState) => selectItemsForId(state, shellKey));
  // const endCursor = useSelector((state: RootState) => selectBottomCursorForId(state, shellKey));
  // const hasNextPage = useSelector((state: RootState) => selectHasNextPageForId(state, shellKey));
  // const loading = useSelector((state: RootState) => selectLoadingForId(state, shellKey));
  // const error = useSelector((state: RootState) => selectErrorForId(state, shellKey));

  useEffect(() => {
    utilSetWallpaper(`wp${Math.floor(Math.random() * 8)}`);
    window.scrollTo({ top: 0 });
  }, []);

  // initial fetch
  useEffect(() => {
    appDispatch(fetchPosts(getOptions(undefined, shellHandle)));
  }, [appDispatch, shellHandle]);

  // ^-- move all side effects out of render function

  return (
    <>
      <div className="shell-page">
        <PostButtons />
        {/*TODO: shell will be in state of the smart contract*/}
        <h1>{shellHandle}</h1>

        <ContentContainer contentId={shellHandle} />
      </div>
    </>
  );
};

export default ShellPage;
