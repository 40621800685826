import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MakeState } from "redux/utils";

type TimestampMs = number;
type BinList = { [id: TxId]: TimestampMs };

export interface RecycleBinState {
  isDeleted: { [id: TxId]: boolean };
  myBin: { list: BinList; error: string; fetching: boolean };
  deletingTxIds: TxId[];
  restoringTxIds: TxId[];
}

const recycleBinSlice = createSlice({
  name: "recycleBin",
  initialState: MakeState<RecycleBinState>({
    isDeleted: {},
    myBin: { list: {}, error: "", fetching: false },
    deletingTxIds: [],
    restoringTxIds: [],
  }),
  reducers: {
    myBinFetchStarted: (state) => {
      state.myBin.fetching = true;
    },
    myBinFetchFailed: (state, action: PayloadAction<string>) => {
      state.myBin.list = {};
      state.myBin.error = action.payload;
      state.myBin.fetching = false;
    },
    myBinFetchDone: (state, action: PayloadAction<{ list: BinList }>) => {
      state.myBin.list = action.payload.list;
      state.myBin.error = "";
      state.myBin.fetching = false;
    },
    deleteStarted: (state, action: PayloadAction<TxId>) => {
      state.deletingTxIds.push(action.payload);
    },
    deleteFailed: (state, action: PayloadAction<TxId>) => {
      state.deletingTxIds = state.deletingTxIds.filter((txId) => txId !== action.payload);
    },
    deleteDone: (state, action: PayloadAction<TxId>) => {
      state.deletingTxIds = state.deletingTxIds.filter((txId) => txId !== action.payload);
      state.isDeleted[action.payload] = true;
    },
    restoreStarted: (state, action: PayloadAction<TxId>) => {
      state.restoringTxIds.push(action.payload);
    },
    restoreFailed: (state, action: PayloadAction<TxId>) => {
      state.restoringTxIds = state.restoringTxIds.filter((txId) => txId !== action.payload);
    },
    restoreDone: (state, action: PayloadAction<TxId>) => {
      state.restoringTxIds = state.restoringTxIds.filter((txId) => txId !== action.payload);
      delete state.isDeleted[action.payload];
      delete state.myBin.list[action.payload];
    },
  },
});

export default recycleBinSlice;
