import "./style.scss";
import BlockEditor from "components/blockEditor";
import DialogConfirm from "components/dialogConfirm";
import Spinner from "components/spinner";
import UploadImage from "components/uploadImage";
import { TOPICS } from "constants/topics";
import { mockShells } from "mock/mockShells";
import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import Oembed from "components/oembed";
import Icon from "components/icons";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { closeDialog, openDialog } from "redux/modal/modalSlice";
import postFormSlice, { submitPost } from "redux/postForm/slice";
import { CommonFields, FileOnlyFields, LinkOnlyFields, TextOnlyFields } from "redux/postForm/types";
import { MakeState } from "redux/utils";
import { useDebouncedCallback } from "use-debounce";

const { clearForm, switchTab } = postFormSlice.actions;

// https://odysee-workspace.slack.com/archives/C0559N668PM/p1689183230610159?thread_ts=1689178747.021079&cid=C0559N668PM

// ****************************************************************************
// Configuration
// ****************************************************************************

type PostFormData = {
  common: CommonFields;
  text: TextOnlyFields;
  link: LinkOnlyFields;
  file: FileOnlyFields;
};

// prettier-ignore
const CONFIG = {
  defaultValues: MakeState<PostFormData>({
    common: { ...postFormSlice.getInitialState().common },
    text: { ...postFormSlice.getInitialState().text },
    link: { ...postFormSlice.getInitialState().link },
    file: { ...postFormSlice.getInitialState().file },
  }),
  useBrowserErrorPopup: false,
  thumbnailUploadId: "PostFormTextThumbnail",
};

// ****************************************************************************
// PostForm
// ****************************************************************************

type FH = UseFormReturn<PostFormData>;

export interface Props {}

export default function PostForm(props: Props) {
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector((state) => state.postForm.ui.tab);
  const submitting = useAppSelector((state) => state.postForm.ui.submitting);
  const thumbnailState = useAppSelector((state) => state.uploadImage[CONFIG.thumbnailUploadId]);

  const formHook = useForm<PostFormData>({
    defaultValues: CONFIG.defaultValues,
    delayError: 500,
    mode: "onChange",
    shouldUseNativeValidation: CONFIG.useBrowserErrorPopup,
  });

  const onSubmit = (data: any) => {
    dispatch(syncToStore);
    dispatch(
      openDialog(DialogConfirm, {
        title: "Submit Post",
        message: "Are you sure?",
        onOk: async () => {
          try {
            await dispatch(submitPost(activeTab));
            dispatch(clearForm());
            dispatch(closeDialog());
          } catch (err: any) {
            dispatch(openDialog(DialogConfirm, { title: "Failed", message: err.message, hideCancel: true }));
          }
        },
      })
    );
  };

  const onInvalid = (errors: any) => {
    dispatch(syncToStore);
  };

  function loadFromStore(dispatch: AppDispatch, getState: GetState) {
    const postFormState = getState().postForm;
    const { ui, ...rest } = postFormState;
    formHook.reset({ ...rest });
  }

  function syncToStore(dispatch: AppDispatch, getState: GetState) {
    dispatch(postFormSlice.actions.updateForm(formHook.getValues()));
  }

  React.useEffect(() => {
    if (thumbnailState) {
      switch (thumbnailState.mode) {
        case "url":
          formHook.setValue("common.thumbnailUrl", thumbnailState.url);
          break;
        case "upload":
          const uploadTxId = thumbnailState.uploadTxId;
          formHook.setValue("common.thumbnailUrl", uploadTxId ? `ar://${uploadTxId}` : "");
          break;
        default:
          formHook.setValue("common.thumbnailUrl", "");
          break;
      }
      formHook.trigger("common.thumbnailUrl");
    }
  }, [thumbnailState, formHook]);

  React.useEffect(() => {
    dispatch(loadFromStore);
    formHook.register("common.thumbnailUrl", { required: "Thumbnail required" });
    return () => dispatch(syncToStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on mount
  }, []);

  return (
    <form className="post-form__container" onSubmit={formHook.handleSubmit(onSubmit, onInvalid)}>
      <div className="post-form__header">
        <h1>Make a Post</h1>
        <img
          src="https://j8z7q7k3.stackpathcdn.com/optimize/s:0:0/quality:85/plain/https://player.odycdn.com/speech/3099c0c1b1cd6fcd:8.png"
          loading="lazy"
        />
      </div>
      <div className="post-form__tabs">
        <div
          className={`post-form__tab ${activeTab === "link" ? "post-form__tab--active" : ""}`}
          onClick={() => dispatch(switchTab("link"))}
        >
          <div className="icon-wrapper">
            <Icon icon="link" size={18} />
          </div>
          <label>Link</label>
        </div>
        <div
          className={`post-form__tab ${activeTab === "text" ? "post-form__tab--active" : ""}`}
          onClick={() => dispatch(switchTab("text"))}
        >
          <div className="icon-wrapper">
            <Icon icon="text" size={18} width={18} height={20} />
          </div>
          <label>Post</label>
        </div>
        <div
          className={`post-form__tab ${activeTab === "file" ? "post-form__tab--active" : ""}`}
          onClick={() => dispatch(switchTab("file"))}
        >
          <div className="icon-wrapper">
            <Icon icon="video" size={18} height={16} />
          </div>
          <label>File</label>
        </div>
      </div>

      <TopicSelect formHook={formHook} />

      <Title formHook={formHook} />

      {activeTab === "link" && <LinkPostUrl formHook={formHook} />}

      {activeTab === "text" && (
        <>
          <div className="post-form__group" id="thumbnail">
            <label>Thumbnail</label>
            <div className="post-form__value">
              <UploadImage id={CONFIG.thumbnailUploadId} className="post-form__upload-image" />
              <Error message={formHook.formState.errors.common?.thumbnailUrl?.message} />
            </div>
          </div>
          <TextPostBody formHook={formHook} />
          <PromoInfo formHook={formHook} />
        </>
      )}

      {activeTab === "file" && (
        <>
          <FilePostUrl formHook={formHook} />
          <FileDescription formHook={formHook} />
          <PromoInfo formHook={formHook} />
        </>
      )}

      <ShellSelect formHook={formHook} />

      <div className="post-form__group">
        {submitting && <Spinner />}
        {!submitting && <input className="btn btn-primary" type="submit" />}
      </div>
    </form>
  );
}

// ****************************************************************************
// ****************************************************************************

function Title(props: { formHook: FH }) {
  const { formHook } = props;

  return (
    <div className="post-form__group">
      <label>Title</label>
      <div className="post-form__value">
        <input type="text" placeholder="Title" {...formHook.register("common.title", { required: "Title required" })} />
        <Error message={formHook.formState.errors.common?.title?.message} />
      </div>
    </div>
  );
}

// ****************************************************************************
// ****************************************************************************

/**
 * 1. User enters URL.
 * 2. `<Oembed>` tries to fetch metadata and stores result in redux.
 * 3. `state.oembed.byUrl[url]` indicates success or failure.
 */
function LinkPostUrl(props: { formHook: FH }) {
  const { formHook } = props;

  const storedUrl = useAppSelector((state) => state.postForm.link.url);
  const url = formHook.watch("link.url");
  const isUrlOembedValid = useAppSelector((state) => Boolean(state.oembed.byUrl[url]));

  const update = useDebouncedCallback((value) => {
    formHook.setValue("link.url", value);
  }, 1000);

  return (
    <>
      <div className="post-form__group">
        <label>URL</label>
        <input type="text" defaultValue={storedUrl} onChange={(e) => update(e.target.value)} />
      </div>
      <div className="post-form__group">
        <label>Preview</label>
        <div className="post-form__value">
          <Oembed url={url} failureElem={<div>🚫 No preview available</div>} />
          <Error message={isUrlOembedValid ? null : "Invalid link"} />
        </div>
      </div>
    </>
  );
}

// ****************************************************************************
// ****************************************************************************

function TextPostBody(props: { formHook: FH }) {
  const { formHook } = props;

  type Json = any;
  const [initialContent, setInitialContent] = React.useState<undefined | Json>(undefined);

  // [ ] `format` is hardcoded for demo; maybe include a dropdown later.
  // [ ] Maybe debounce the update.
  const update = React.useCallback(
    (jsonStr: string) => {
      formHook.setValue("text.body", jsonStr);
      formHook.setValue("text.format", "json");
    },
    [formHook]
  );

  React.useEffect(function resolveInitialContent() {
    Promise.resolve()
      .then(() => JSON.parse(formHook.getValues().text.body))
      .then((json) => setInitialContent(json))
      .catch((err) => setInitialContent(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on mount
  }, []);

  return (
    <div className="post-form__group">
      <label>Text Body</label>
      {initialContent !== undefined && <BlockEditor onChange={update} initialContent={initialContent} />}
    </div>
  );
}

// ****************************************************************************
// ****************************************************************************

function PromoInfo(props: { formHook: FH }) {
  const { formHook } = props;

  return (
    <div className="post-form__group">
      <label>Promotional Info</label>
      <div className="post-form__value">
        <textarea className="form-control" {...formHook.register("text.promoInfo")} />
      </div>
    </div>
  );
}

// ****************************************************************************
// ****************************************************************************

function TopicSelect(props: { formHook: FH }) {
  // [ ] Change from dropdown to buttons, per Figma.
  const { formHook } = props;

  return (
    <div className="post-form__group">
      <label>Topic</label>
      <div className="post-form__value">
        <select {...formHook.register("common.topic", { required: "Select a topic" })}>
          <option key="none" value="">
            {"---"}
          </option>
          {TOPICS.map((t) => (
            <option key={t} value={t}>
              {t}
            </option>
          ))}
        </select>
        <Error message={formHook.formState.errors.common?.topic?.message} />
      </div>
    </div>
  );
}

// ****************************************************************************
// ****************************************************************************

function ShellSelect(props: { formHook: FH }) {
  // [ ] Add shell-search UI, per Figma.
  // [ ] Change `mockShells` to actual per-user shells.
  const { formHook } = props;
  const myShells = mockShells;

  return (
    <div className="post-form__group">
      <label>Shell</label>
      <div className="post-form__value">
        <select {...formHook.register("text.shellId", { required: "Select a shell" })}>
          <option key="none" value="">
            {"---"}
          </option>
          {myShells.map((s) => (
            <option key={s.id} value={s.id}>
              {s.name.handle}
            </option>
          ))}
        </select>
        <Error message={formHook.formState.errors.text?.shellId?.message} />
      </div>
    </div>
  );
}

function FilePostUrl(props: { formHook: FH }) {
  const { formHook } = props;

  return (
    <div className="post-form__group">
      <label>URL</label>
      <div className="post-form__value">
        <input
          type="text"
          {...formHook.register("file.url", {
            validate: {
              checkUrl: () => {
                // [ ] Need to debounce if we're going to fetch the URL.
                // [ ] Do we actually need to fetch the URL, or just regex it?
                return "Validation not implemented";
              },
            },
          })}
        />
        <Error message={formHook.formState.errors.file?.url?.message} />
      </div>
    </div>
  );
}

function FileDescription(props: { formHook: FH }) {
  const { formHook } = props;

  return (
    <div className="post-form__group">
      <label>Description</label>
      <div className="post-form__value">
        <textarea
          className="form-control"
          {...formHook.register("file.description", { required: "Add a description" })}
        />
        <Error message={formHook.formState.errors.file?.description?.message} />
      </div>
    </div>
  );
}

// ****************************************************************************
// ****************************************************************************

function Error(props: { message: string | undefined | null }) {
  const show = !CONFIG.useBrowserErrorPopup && props.message;
  return show ? <div className="post-form__error">{props.message}</div> : null;
}
