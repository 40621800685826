import { useAppDispatch, useAppSelector } from "redux/hooks";
import React from "react";
import { setDevPageProfile } from "redux/ui/uiSlice";

interface Props {
  field: string;
}

const ProfileField = (props: Props) => {
  const { field } = props;
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.ui.devPage.profile);
  const value = profile[field] || "";

  return (
    <div className="dev-page__profile-fields">
      <label>{props.field}</label>
      <input
        type="text"
        placeholder={props.field}
        value={value}
        onChange={(e) =>
          dispatch(
            setDevPageProfile({
              ...profile,
              [field]: e.target.value,
            })
          )
        }
      />
    </div>
  );
};

export default ProfileField;
