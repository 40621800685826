import { useState, useEffect, useRef } from "react";
import { MockShell } from "mock/mockShells";
import Icon from "components/icons";
import classnames from "classnames";
import "./styles.scss";
import ShellGroup from "./shellGroup";

interface Props {
  users: Array<MockShell>;
}

const UserPreview = (user: any) => {
  return (
    <div className="dock__tile">
      <div className="dock__tile-title">
        {user.user.name.first} {user.user.name.last}
      </div>
      <img src={user.user.picture.thumbnail} loading="lazy" />
    </div>
  );
};

const Dock = (props: Props) => {
  const { users } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [tab, setTab] = useState("recentlyactive");
  const dock = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (dock.current && !dock.current.contains(e.target)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const section = document.getElementById(tab);

    if (section && dock.current) {
      const containerTop = dock.current.getBoundingClientRect().top;
      const elementTop = section.getBoundingClientRect().top;
      const offset = elementTop - containerTop - 126;

      dock.current.scrollTo({
        top: dock.current.scrollTop + offset,
        behavior: "smooth",
      });
    }
  }, [tab]);

  return (
    <div
      ref={dock}
      onClick={!isExpanded ? () => setIsExpanded(!isExpanded) : undefined}
      className={classnames("dock", {
        "dock--expanded": isExpanded,
      })}
    >
      <div className="dock__row dock__row--top">
        <div className="dock__tile">
          {isExpanded && (
            <div className="dock__button" onClick={() => setIsExpanded(!isExpanded)}>
              <Icon icon="whatever" width={19} />
            </div>
          )}
        </div>
        <div className="dock__tile" />
        <div className="dock__tile" />
        <div className="dock__tile" />
        <div className="dock__tile" />
        <div className="dock__tile" />
        <div className="dock__tile" />
        <div className="dock__tile" />
        <div className="dock__tile" />
        <div className="dock__tile">
          <div className="dock__button" onClick={() => setIsExpanded(!isExpanded)}>
            <Icon icon="arrow_up" height={14} />
          </div>
        </div>

        <div className="dock__tabs__container">
          <div className="dock__tabs">
            <div
              className={tab === "recentlyactive" ? "dock__tab dock__tab--active" : "dock__tab"}
              onClick={() => setTab("recentlyactive")}
            >
              Recently Active
            </div>
            <div
              className={tab === "superfollowing" ? "dock__tab dock__tab--active" : "dock__tab"}
              onClick={() => setTab("superfollowing")}
            >
              Super Following
            </div>
            <div
              className={tab === "following" ? "dock__tab dock__tab--active" : "dock__tab"}
              onClick={() => setTab("following")}
            >
              Following
            </div>
          </div>
        </div>
      </div>
      <div className="dock__row-title" id="recentlyactive">
        Recently Active
      </div>
      <div className="dock__row">
        {users.length >= 0 &&
          users.map((u: MockShell, i) => i < (!isExpanded ? 11 : 12) && <UserPreview user={u} key={u.email} />)}
        <div className="dock__tile" />
      </div>

      <div className="dock__row-title" id="superfollowing">
        Super Following
      </div>
      <div className="dock__row">
        {users.length >= 0 &&
          users.map((u: MockShell, i) => i >= 13 && i < 37 && <UserPreview user={u} key={u.email} />)}
      </div>

      <div className="dock__row-title" id="following">
        Following
      </div>
      <div className="dock__row">
        {users.length >= 0 &&
          users.map((u: MockShell, i) => i >= 38 && i < 62 && <UserPreview user={u} key={u.email} />)}
      </div>
      <div className="dock__row-title" id="following">
        My Shells
      </div>
      <ShellGroup shellGroup={"ownShells"} />
    </div>
  );
};

export default Dock;
