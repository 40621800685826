import { useAppDispatch } from "redux/hooks";
import { openDialog } from "redux/modal/modalSlice";
import { MenuEntry } from "components/menu";
import ShellForm, { Props as ShellFormProps } from "components/shellForm";
import "./style.scss";

export interface Props {}

const ShellMenu = (props: Props) => {
  const dispatch = useAppDispatch();

  function openShellForm() {
    dispatch(openDialog<ShellFormProps>(ShellForm, {}));
  }

  return (
    <>
      <MenuEntry title="My Shells" disabled />
      <MenuEntry title="Create a Shell" subtitle="Start a new Community" action={openShellForm} />
    </>
  );
};

export default ShellMenu;
