import Routes from "pages/routes";
import useSupportsHardwareAcceleration from "effects/use-browser-details";
import "./App.scss";

import Playground from "components/playground";
import Header from "components/header";
import Dock from "components/dock";

import { getSomeUsers } from "mock/mockShells";

function App() {
  const users = getSomeUsers(80);
  useSupportsHardwareAcceleration();

  return (
    <div className="app">
      <Playground />
      <Header />
      <div className="container">
        <main className="content">
          <Routes />
        </main>
      </div>
      <Dock users={users as any} />
    </div>
  );
}

export default App;
