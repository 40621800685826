import React from "react";
import Ar from "arweave/ar";
import * as AR_CFG from "arweave/config";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setGatewayId } from "redux/settings/settingsSlice";
import { appendDevPageLog as append } from "redux/ui/uiSlice";
import Wallet from "redux/wallet/slice";

function GatewaySelect() {
  const dispatch = useAppDispatch();
  const gatewayId = useAppSelector((state) => state.settings.gatewayId);

  function changeGateway(gatewayId: AR_CFG.GatewayId) {
    dispatch(setGatewayId(gatewayId));
    // Most arweave objects don't respond to gateway changes at the moment,
    // so rebooting is the easiest path for now.
    setTimeout(() => window.location.reload(), 50);
  }

  const MintToken = () => {
    return (
      <button
        onClick={async () => {
          if (!window.arweaveWallet) {
            dispatch(append("Connect wallet first"));
            return;
          }

          const tokens = Ar.arweave.ar.arToWinston("1");
          const addr = await window.arweaveWallet.getActiveAddress();

          dispatch(append(await Ar.arweave.api.get(`mint/${addr}/${tokens}`)));
          dispatch(append(await Ar.arweave.api.get("mine")));
          dispatch(Wallet.actions.markLastMintedTs());
        }}
      >
        Mint 1 AR
      </button>
    );
  };

  const ArLocalLink = () => {
    const g = AR_CFG.gateways[gatewayId];
    const graphqlUrl = `${g.protocol}://${g.host}:${g.port}/graphql`;
    return (
      <a href={graphqlUrl} target="_blank" rel="noreferrer">
        Open ArLocal
      </a>
    );
  };

  return (
    <div className="dev-page__row">
      <label>Gateway</label>

      <select name="gateway" value={gatewayId} onChange={(e) => changeGateway(e.target.value as AR_CFG.GatewayId)}>
        {Object.keys(AR_CFG.gateways).map((id) => (
          <option key={id} value={id}>
            {id}
          </option>
        ))}
      </select>

      {gatewayId.startsWith("arlocal") && <MintToken />}

      {gatewayId.startsWith("arlocal") && <ArLocalLink />}
    </div>
  );
}

export default GatewaySelect;
