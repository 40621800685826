import Spinner from "components/spinner";
import React from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchOembedForUrl } from "redux/oembed/slice";

import "./style.scss";

interface Props {
  url: string;
  failureElem?: React.ReactNode;
}

const Oembed = (props: Props) => {
  const { url, failureElem } = props;
  const dispatch = useAppDispatch();
  const data: OembedDataTypes | null = useAppSelector((state) => state.oembed.byUrl[url]);
  const [error, setError] = React.useState<Error | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setError(null);
    setIsLoading(false);

    if (url) {
      Promise.resolve()
        .then(() => setIsLoading(true))
        .then(() => dispatch(fetchOembedForUrl(url)))
        .then(() => setError(null))
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, [url, dispatch]);

  return (
    <div className="oembed">
      {process.env.REACT_APP_BLOCK_OEMBED === "true" ? (
        <div className="oembed__blocked" />
      ) : isLoading ? (
        <div className="oembed__spinner">
          <Spinner />
        </div>
      ) : !data ? (
        <div className="oembed__error">{failureElem || <p>Unable to fetch link data ({url})</p>}</div>
      ) : (
        <>
          {data.type === "video" && getVideoEmbed(data as VideoTypeData)}
          {data.type === "photo" && getPhotoEmbed(data as PhotoTypeData)}
          {data.type === "link" && getLinkEmbed(url, data as LinkTypeData)}
          {data.type === "rich" && getRichEmbed(data as RichTypeData)}
        </>
      )}
      {process.env.NODE_ENV === "development" && error && (
        <div className="oembed__debug">
          <p>[Debug] {error.message}</p>
        </div>
      )}
    </div>
  );
};

// ****************************************************************************
// ****************************************************************************

function getVideoEmbed(data: VideoTypeData) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(data.html, "text/html");
  const iframe = doc.querySelector("iframe");

  if (iframe) {
    const hasSandbox = iframe.hasAttribute("sandbox");
    if (hasSandbox) {
      console.log("original sandbox:", iframe.getAttribute("sandbox"));
      iframe.removeAttribute("sandbox");
    }
    iframe.setAttribute("sandbox", "allow-scripts allow-same-origin allow-popups allow-presentation");
  }

  // The argument is that the iframe is already sanitized by the oembed
  // provider, so dangerouslySetInnerHTML should be fine. If not coming from
  // a reputable source, we need to sanitize.
  return <div dangerouslySetInnerHTML={{ __html: doc.documentElement.outerHTML }} />;
}

// ****************************************************************************
// ****************************************************************************

function getPhotoEmbed(data: PhotoTypeData) {
  return <img src={data.url} width={data.width} height={data.height} />;
}

// ****************************************************************************
// ****************************************************************************

function getLinkEmbed(url: string, data: LinkTypeData) {
  return (
    <a href={url} className="oembed__link-container" target="_blank" rel="noopener noreferrer">
      <div className="oembed__link-thumbnail">
        <img src={data.thumbnail_url} />
      </div>
      <div className="oembed__link-details">
        <div className="oembed__link-title">{data.title}</div>
        <div className="oembed__link-url">{url}</div>
        <div className="oembed__link-description">{data.description}</div>
      </div>
    </a>
  );
}

// ****************************************************************************
// ****************************************************************************

function getRichEmbed(data: RichTypeData) {
  return "Rich embed not implemented"; // How to do it safely?
}

// ****************************************************************************
// ****************************************************************************

export default Oembed;
