import { createSlice } from "@reduxjs/toolkit";
import { ModalState } from "./modalSlice.types";

/**
 * === Notes ===
 * - Ideally, there should be `dialog` and `modalDialogs`. For now, all dialogs
 *   are modal, and stackable in the order they are opened.
 */

// ****************************************************************************
// Actions
// ****************************************************************************

export function openDialog<T>(component: React.ComponentType<T>, props: T) {
  return { type: "modal/openDialog", payload: { component, props } };
}

/**
 * Closes the topmost modal dialog.
 * At the moment, there is no need to close a specific dialog -- the active
 * modal is blocking, so it should just dismiss itself.
 */
export function closeDialog() {
  return { type: "modal/closeDialog" };
}

export function openContextMenu<T>(component: React.ComponentType<T>, props: T) {
  return { type: "modal/openContextMenu", payload: { component, props } };
}

export function closeContextMenu() {
  return { type: "modal/closeContextMenu" };
}

export function showShellPreview<T>(props: T) {
  return { type: "modal/showShellPreview", payload: { props } };
}

export function hideShellPreview() {
  return { type: "modal/hideShellPreview" };
}

// ****************************************************************************
// Selectors
// ****************************************************************************

export function selectIsDialogOpen(state: AppState) {
  return !!state.modal.dialog;
}

export function selectIsContextMenuOpen(state: AppState) {
  return !!state.modal.contextMenu;
}

export function selectIsShellPreviewVisible(state: AppState) {
  return !!state.modal.shellPreview;
}

// ****************************************************************************
// modalSlice
// ****************************************************************************

const initialState = {
  dialog: [],
  dialogProps: [],
  dialogAttr: [],
  contextMenu: null,
  contextMenuProps: null,
  shellPreview: null,
  shellPreviewProps: null,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase("modal/openDialog", (state: ModalState, action: any) => {
      state.dialog.push(action.payload.component);
      state.dialogProps.push(action.payload.props);
      state.contextMenu = null;
      state.contextMenuProps = null;
      state.shellPreview = null;
      state.shellPreviewProps = null;
    });
    builder.addCase("modal/closeDialog", (state: ModalState) => {
      state.dialog.pop();
      state.dialogProps.pop();
      state.contextMenuProps = null;
      state.shellPreview = null;
      state.shellPreviewProps = null;
    });
    builder.addCase("modal/openContextMenu", (state: ModalState, action: any) => {
      //-- Opening a dialog will make the context menu close, but I think
      //-- the other way around is not necessary?
      // state.dialog = [];
      // state.dialogProps = [];
      // state.dialogAttr = {};
      state.contextMenu = action.payload.component;
      state.contextMenuProps = action.payload.props;
      state.shellPreview = null;
      state.shellPreviewProps = null;
    });
    builder.addCase("modal/closeContextMenu", (state: ModalState) => {
      state.contextMenu = null;
      state.contextMenuProps = null;
      state.shellPreview = null;
      state.shellPreviewProps = null;
    });
    builder.addCase("modal/showShellPreview", (state: ModalState, action: any) => {
      state.shellPreview = undefined;
      state.shellPreviewProps = action.payload.props;
    });
    builder.addCase("modal/hideShellPreview", (state: ModalState) => {
      state.shellPreview = null;
      state.shellPreviewProps = null;
    });
  },
});

export default modalSlice;
