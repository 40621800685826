import React from "react";

function useSupportsHardwareAcceleration() {
  React.useEffect(() => {
    const testElement = document.createElement("div");
    testElement.style.transform = "translate3d(0, 0, 0)";
    const accelerated = testElement.style.transform !== "";
    if (!accelerated || process.env.REACT_APP_SIMPLE_GRAPHICS === "true") {
      document.body.classList.add("no-hardware-acceleration");
    }
  }, []);
}

export default useSupportsHardwareAcceleration;
