import { InjectedArweaveSigner as DeploySigner } from "warp-contracts-plugin-deploy";
// @ts-ignore // can't find types for below, but can for above.
import { InjectedArweaveSigner as SignatureSigner } from "warp-contracts-plugin-signature";
import { Tag } from "warp-contracts";
import { warp } from "../warpServer";
import Ar from "arweave/ar";
const shellStateTemplate = require("./initialShell.json");

export async function shellDeployFromTx(shellName: string) {
  const deploySigner = new DeploySigner(window.arweaveWallet);
  await deploySigner.setPublicKey();
  const userAddress = await Ar.account.getActiveAddress();

  // We can deployFromSourceTx() which takes 'srcTxId' instead of 'src'
  // in that case, devs produce and publish the current contract
  // and provide a button to update it to mods/owners
  const contractSourceTx = Ar.contractIds.shellSrc;
  const appCategoryTag = process.env.REACT_APP_SHELL_CAT_TAG_NAME;
  const appContractTypeTag = process.env.REACT_APP_CONTRACT_TYPE_TAG_NAME;

  if (!contractSourceTx) {
    throw new Error("missing env: REACT_APP_SHELL_SRC_TXID_[ENV]");
  }
  if (!appCategoryTag) {
    throw new Error("missing env: REACT_APP_SHELL_CAT_TAG_NAME");
  }
  if (!appContractTypeTag) {
    throw new Error("missing env: REACT_APP_CONTRACT_TYPE_TAG_NAME");
  }
  if (!userAddress) {
    throw new Error("No active address. Sign in is required.");
  }

  const initialShell = {
    ...shellStateTemplate,
    ...{ name: shellName },
    ...{ owner: userAddress },
  };
  const data = {
    // @ts-ignore // InjectedArweaveSigner is not a Signer?
    wallet: Ar.activeGateway.isLocal ? window.arweaveWallet : deploySigner,
    initState: JSON.stringify(initialShell),
    srcTxId: contractSourceTx,
    tags: [new Tag(appCategoryTag, shellName.toString()), new Tag(appContractTypeTag, "Shell")],
  };

  // @ts-ignore // test
  const res = await warp.deployFromSourceTx(data);

  return res.contractTxId;
}

export async function writeShell(shellTx: string, fnName: string, value: any) {
  const connectSigner = new SignatureSigner(window.arweaveWallet);
  await connectSigner.setPublicKey();

  const contract = warp.contract(shellTx).connect(connectSigner);
  let data;
  if (value) {
    data = { value };
  }
  await contract.writeInteraction({
    function: fnName,
    data: data,
  });
}

export async function readShell(shellTx: string) {
  const connectSigner = new SignatureSigner(window.arweaveWallet);
  await connectSigner.setPublicKey();

  const contract = warp.contract(shellTx).connect(connectSigner);
  const state = await contract.readState();
  return state;
}
