import React from "react";
import { updateShellForm } from "redux/shellForm/slice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Icon from "components/icons";
import "./style.scss";
import { clearDeploy, doDeployShell } from "redux/shells/slice";
import { closeDialog } from "redux/modal/modalSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

export interface Props {}

export default function ShellForm(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = React.useState(0);

  const deploying = useAppSelector((state) => state.shells.isDeploying);
  const deployedTxid = useAppSelector((state) => state.shells.deployedTxid);
  const name = useAppSelector((state) => state.shellForm.name);

  const handleDeploySubmit = (e: any) => {
    e.preventDefault();
    dispatch(doDeployShell(name))
      .then(unwrapResult)
      .then((r) => {
        setStep(step + 1);
      });
  };

  function closeModal() {
    dispatch(clearDeploy());
    dispatch(closeDialog());
  }

  function handleManage() {
    closeModal();
    navigate(`/s/${name}:${deployedTxid}`);
  }

  if (step === 1) {
    return (
      <div className={"shell-form__container"}>
        <div className="shell-form__header">
          <h1>SUCCESS</h1>
        </div>
        <div className="shell-form__body--center">
          <div>{`s/${name}`} Created</div>
        </div>
        <div className="shell-form__actions">
          <button className={"button__primary"} onClick={() => handleManage()}>
            Manage
          </button>
          <button className={"button__secondary"} onClick={() => closeModal()}>
            Done
          </button>
        </div>
      </div>
    );
  }
  return (
    <form className="shell-form__container" onSubmit={handleDeploySubmit}>
      <div className="shell-form__header">
        <h1>Create A Community</h1>
      </div>
      <div className="shell-form__body">
        <Name />
        <Visibility />
        <Nsfw />
      </div>
      <div className="shell-form__actions">
        <button className={"button__primary"} type="submit" disabled={deploying}>
          {deploying ? "Creating" : "Create"}
        </button>
      </div>
    </form>
  );
}

// ****************************************************************************
// ****************************************************************************

function Name() {
  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => state.shellForm.name);

  const updateName = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateShellForm({ name: e.target.value }));
    },
    [dispatch]
  );

  return (
    <div className="shell-form__group">
      <label>Name</label>
      <div className="shell-form__value">
        <input type="text" value={name} onChange={updateName} />
        <p className="shell-form__subtitle">
          A shell is an interest group dedicated to the discussion of a particular topic, e.g. Technology, Philosophy,
          Gaming, Science.
        </p>
      </div>
    </div>
  );
}

// ****************************************************************************
// ****************************************************************************

function Visibility() {
  const dispatch = useAppDispatch();
  const visibility = useAppSelector((state) => state.shellForm.visibility);

  const update = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateShellForm({ visibility: e.target.value as "public" | "private" }));
    },
    [dispatch]
  );

  return (
    <div className="shell-form__group shell-form__group--column">
      <label>Type</label>
      <div className="shell-form__value-group">
        <div className="shell-form__value">
          <input
            className="shell-form__name"
            type="radio"
            checked={visibility === "public"}
            value="public"
            onChange={update}
          />
          <Icon icon="public" />
          Public
        </div>
        <div className="shell-form__value">
          <input
            className="shell-form__name"
            type="radio"
            checked={visibility === "private"}
            value="private"
            onChange={update}
          />
          <Icon icon="private" width={20} />
          Private
        </div>
      </div>
    </div>
  );
}

// ****************************************************************************
// ****************************************************************************

function Nsfw() {
  const dispatch = useAppDispatch();
  const nsfw = useAppSelector((state) => state.shellForm.nsfw);

  const update = React.useCallback(() => {
    dispatch(updateShellForm({ nsfw: !nsfw }));
  }, [dispatch, nsfw]);

  return (
    <div className="shell-form__group">
      <label>NSFW</label>
      <div className="shell-form__value">
        SFW / NSFW
        <label className="shell-form__nsfw switch">
          <input type="checkbox" checked={nsfw} value="public" onChange={update} />
          <span className="slider"></span>
        </label>
      </div>
    </div>
  );
}
