import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import reducers from "redux/reducers";
import serializableCheck from "redux/serializableCheck";

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: serializableCheck,
    }),
});

// @ts-ignore
window.store = store;
export const persistor = persistStore(store);

export type GetState = typeof store.getState;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
