import "./style.scss";

interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
}

const Switch = (props: Props) => {
  return (
    <label className="switch">
      <input type="checkbox" />
      <span className="slider" />
      <label className="on">ON</label>
      <label className="off">OFF</label>
    </label>
  );
};

export default Switch;
