import React, { useRef } from "react";
const DEFAULT_SCREEN_SIZE = 1080;

function useHasWindowWidthChangedEnough(comparisonFn: (windowSize: number) => string) {
  const isWindowClient = typeof window === "object";
  const initialState = isWindowClient ? comparisonFn(window.innerWidth) : comparisonFn(DEFAULT_SCREEN_SIZE);
  const [windowSize, setWindowSize] = React.useState(initialState);
  const prev = useRef(initialState);

  React.useEffect(() => {
    function setSize() {
      const curr = comparisonFn(window.innerWidth);
      if (prev.current !== curr) {
        setWindowSize(curr);
        prev.current = curr;
      }
    }

    if (isWindowClient) {
      window.addEventListener("resize", setSize);

      return () => window.removeEventListener("resize", setSize);
    }
  }, [isWindowClient, comparisonFn]);

  return windowSize;
}

export function useScreenOrientation() {
  const isWindowClient = typeof window === "object";
  const [orientation, setOrientation] = React.useState(calculateOrientation());

  function calculateOrientation() {
    if (window.matchMedia) {
      if (window.matchMedia("(orientation: landscape)").matches) {
        return "landscape";
      } else if (window.matchMedia("(orientation: portrait)").matches) {
        return "portrait";
      }
    }

    if (window.screen.orientation) {
      return window.screen.orientation.type.includes("landscape") ? "landscape" : "portrait";
    } else if (window.orientation !== undefined) {
      return window.orientation === 90 || window.orientation === -90 ? "landscape" : "portrait";
    } else {
      console.log("HELP!");
      return "null";
    }
  }

  React.useEffect(() => {
    function handleResize() {
      setOrientation(calculateOrientation());
    }

    if (isWindowClient) {
      setOrientation(calculateOrientation());

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }
  }, [isWindowClient]);

  return orientation;
}

export function useGetScreenSize(): string {
  return useHasWindowWidthChangedEnough((windowSize) =>
    windowSize < 901 ? "mobile" : windowSize < 1151 ? "medium" : "large"
  );
}

export function useGetScreenOrientation(): string {
  return useScreenOrientation();
}
