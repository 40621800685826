export const TOPICS = Object.freeze([
  "NEWS",
  "TWEET",
  "VIDEO",
  "BLOG",
  "SHOPPING",
  "OTHER",
  "MUSIC",
  "XXX",
  "ARTICLE",
  "WTF",
]);
