import classnames from "classnames";
import { useAppSelector } from "redux/hooks";
import { Theme } from "redux/settings/settingsSlice.types";
import "./style.scss";

interface Props {
  type?: "pulse"; // | "rotate"
  size?: "large" | "small";
  pad?: "none" | "small" | "large";
  forceDarkBg?: boolean;
}

function Spinner(props: Props) {
  const { type = "pulse", size = "large", pad = "none", forceDarkBg } = props;

  const theme: Theme = useAppSelector((state) => state.settings.theme);
  const darkBg = forceDarkBg ? true : theme === "dark";

  return (
    <div
      className={classnames("spinner", {
        "spinner--small": size === "small",
        "spinner--dark": darkBg,
        "spinner--light": !darkBg,
        "spinner--pad-small": pad === "small",
        "spinner--pad-large": pad === "large",
      })}
    >
      {type === "pulse" && <PulseSpinner />}
    </div>
  );
}

function PulseSpinner() {
  return (
    <div className="pulse-spinner">
      <div className="rect rect1" />
      <div className="rect rect2" />
      <div className="rect rect3" />
    </div>
  );
}

export default Spinner;
