import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MakeState } from "redux/utils";

type TxId = string;

export interface UploadImageState {
  [id: string]: {
    mode: "upload" | "url"; // The mode that the user selected
    url: string; // The URL that the user entered for URL mode.
    file: File | null; // The file that the user selected for UPLOAD mode.
    uploadTxId?: TxId; // The transaction ID of the successful upload.
    uploadErr?: Error; // The error that occurred during upload.
  };
}

const slice = createSlice({
  name: "uploadImage",
  initialState: MakeState<UploadImageState>({}),
  reducers: {
    initStateForId: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      if (!state[id]) {
        state[id] = { mode: "upload", url: "", file: null };
      }
    },
    setMode: (state, action: PayloadAction<{ id: string; mode: "upload" | "url" }>) => {
      const { id, mode } = action.payload;
      state[id].mode = mode;
    },
    setFile: (state, action: PayloadAction<{ id: string; file: File }>) => {
      const { id, file } = action.payload;
      state[id].file = file;
      delete state[id].uploadTxId;
      delete state[id].uploadErr;
    },
    setUrl: (state, action: PayloadAction<{ id: string; url: string }>) => {
      const { id, url } = action.payload;
      state[id].url = url;
    },
    uploadDone: (state, action: PayloadAction<{ id: string; txId: TxId }>) => {
      const { id, txId } = action.payload;
      state[id].uploadTxId = txId;
      delete state[id].uploadErr;
    },
    uploadFail: (state, action: PayloadAction<{ id: string; err: Error }>) => {
      const { id, err } = action.payload;
      delete state[id].uploadTxId;
      state[id].uploadErr = err;
    },
  },
});

export default slice;
