import { createSlice } from "@reduxjs/toolkit";
import { MakeState } from "redux/utils";

export interface WalletState {
  connected: boolean;
  address: string;
  lastMintedTs: number;
}

const walletSlice = createSlice({
  name: "wallet",
  initialState: MakeState<WalletState>({
    connected: false,
    address: "",
    lastMintedTs: 0,
  }),
  reducers: {
    setConnected: (state, action) => void (state.connected = action.payload.connected),
    setAddress: (state, action) => void (state.address = action.payload.address),
    markLastMintedTs: (state) => void (state.lastMintedTs = Date.now()),
  },
});

export default walletSlice;
