import { getOptions } from "arweave/txQuery";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import Navigation from "components/navigation";
import Search from "components/input/search";
import { useAppDispatch } from "redux/hooks";
import { fetchPosts, purgePosts } from "redux/posts/postsSlice";
import "./style.scss";

const Header = () => {
  const dispatch = useAppDispatch();

  const handleLogoClicked = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(purgePosts("home"));
    dispatch(fetchPosts(getOptions(undefined, undefined)));
  }, [dispatch]);

  return (
    <>
      <header className="header">
        <div className="header__content">
          <div className="header__item-left">
            <Link to="/">
              <div className="header__logo" onClick={handleLogoClicked}>
                EARTHoF.
              </div>
            </Link>
          </div>
          <div className="header__item-center">
            <Search />
          </div>
          <div className="header__item-right">
            <Navigation />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
