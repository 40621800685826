import { useState, useEffect } from "react";
import { utilSetWallpaper } from "utils/setTheme";
import Switch from "components/common/switch";
import "./style.scss";

export interface Props {}

const SettingsPage = (props: Props) => {
  const [tab, setTab] = useState("account");

  useEffect(() => {
    utilSetWallpaper(`black`);
    const searchBar = document.getElementsByClassName("search-bar")[0];
    searchBar.classList.add("search-bar--hidden");
    const settingsIcon = document.getElementsByClassName("navbar__item--settings")[0];
    settingsIcon.classList.add("navbar__item--settings-active");
    return () => {
      searchBar.classList.remove("search-bar--hidden");
      settingsIcon.classList.remove("navbar__item--settings-active");
    };
  }, []);

  function toggleSwitch(id: string, value: boolean) {}

  return (
    <div className="settings__content">
      <h2>Account Settings</h2>

      <div className="settings__tabs">
        <div
          className={`settings__tab ${tab === "account" && "settings__tab--active"}`}
          onClick={() => setTab("account")}
        >
          Account
        </div>
        <div
          className={`settings__tab ${tab === "profile" && "settings__tab--active"}`}
          onClick={() => setTab("profile")}
        >
          Profile
        </div>
        <div
          className={`settings__tab ${tab === "safety_privacy" && "settings__tab--active"}`}
          onClick={() => setTab("safety_privacy")}
        >
          Safety & Privacy
        </div>
        <div
          className={`settings__tab ${tab === "content" && "settings__tab--active"}`}
          onClick={() => setTab("content")}
        >
          Content
        </div>
        <div
          className={`settings__tab ${tab === "notifications" && "settings__tab--active"}`}
          onClick={() => setTab("notifications")}
        >
          Notifications
        </div>
        <div
          className={`settings__tab ${tab === "super_following" && "settings__tab--active"}`}
          onClick={() => setTab("super_following")}
        >
          Super Following
        </div>
        <div className={`settings__tab ${tab === "chat" && "settings__tab--active"}`} onClick={() => setTab("chat")}>
          Chat
        </div>
      </div>

      <div className="settings__content">
        {tab === "account" && (
          <div className="settings__group">
            <div className="settings__row">
              <div className="settings__row-title--uppercase">Email Address</div>
              <div className="settings__row-action">
                <input type="button" value="Change" />
              </div>
            </div>
            <div className="settings__row">
              <div className="settings__row-title--uppercase">Country</div>
              <div className="settings__row-action">
                <input type="button" value="United States" />
              </div>
            </div>
          </div>
        )}
        {tab === "profile" && (
          <div className="settings__group">
            <div className="dev-note">Note by Rave: No template abailable.</div>
          </div>
        )}
        {tab === "safety_privacy" && (
          <div className="settings__group">
            <div className="settings__group-title">Users you have muted</div>
            <div className="settings__group-subtitle">Posts and activity from muted users won't be visible to you.</div>
            <div className="settings__row">
              <div className="settings__row-title">u/whatever</div>
              <div className="settings__row-action--right">
                <Switch value={true} onChange={(value) => toggleSwitch("id", value)} />
              </div>
            </div>
            <div className="dev-note">
              Note by Rave: I don't think these toggle buttons make sense here. On a page like this you would want to
              remove and potentially even add users & shells to a mote/block list...unless we intend to list every
              single user & shell that a user follows, but that doesn't seem like a good long term solution because that
              approach would quickly turn into a confusing mess.
            </div>
          </div>
        )}
        {tab === "content" && (
          <>
            <div className="settings__group">
              <div className="settings__group-title">NSFW</div>
              <div className="settings__group-subtitle">
                Enable to view NSFW content in your feed and search results.
              </div>
              <div className="settings__row">
                <div className="settings__row-title">NSFW</div>
                <div className="settings__row-action--right">
                  <Switch value={true} onChange={(value) => toggleSwitch("id", value)} />
                </div>
              </div>
            </div>

            <div className="settings__group">
              <div className="settings__group-title">XXX</div>
              <div className="settings__group-subtitle">
                Enable to view XXX content in your feed and search results.
              </div>
              <div className="settings__row">
                <div className="settings__row-title">XXX</div>
                <div className="settings__row-action--right">
                  <Switch value={true} onChange={(value) => toggleSwitch("id", value)} />
                </div>
              </div>
            </div>

            <div className="settings__group">
              <div className="settings__group-title">Safe browsing mode</div>
              <div className="settings__group-subtitle">
                Blur thumbnails, images, and media previews for all NSFW & XXX content.
              </div>
              <div className="settings__row">
                <div className="settings__row-title">SAFE MODE</div>
                <div className="settings__row-action--right">
                  <Switch value={true} onChange={(value) => toggleSwitch("id", value)} />
                </div>
              </div>
            </div>
          </>
        )}
        {tab === "notifications" && (
          <div className="settings__group">
            <div className="dev-note">Note by Rave: No template abailable.</div>
          </div>
        )}
        {tab === "super_following" && (
          <div className="settings__group">
            <div className="dev-note">Note by Rave: No template abailable.</div>
          </div>
        )}
        {tab === "chat" && (
          <div className="settings__group">
            <div className="settings__group-title">Who can send you chat requests</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;
