import { getOptions, getTxQueryKey } from "arweave/txQuery";
import { useAppSelector } from "redux/hooks";
import { selectItemsForId } from "redux/posts/postsSlice";
import Post from "./post";
import "./style.scss";

interface Props {
  page: string; // fix null type
  param?: string; // this may be key or query
}

const Posts = (props: Props) => {
  const { page, param } = props;
  const options = getOptions(page === "user" ? param : undefined, page === "shell" ? param : undefined);
  const itemsKey = getTxQueryKey(options);

  const reduxPosts = useAppSelector((state) => selectItemsForId(state, itemsKey));

  return (
    <section className="posts">
      {reduxPosts.length > 0 && reduxPosts.map((post: any, i: number) => <Post post={post} key={i} />)}
    </section>
  );
};

export default Posts;
