import { Route, Routes as Switch } from "react-router-dom";

import { PAGES } from "constants/pages";
import HomePage from "pages/home";
import UserPage from "pages/user";
import RecycleBinPage from "pages/recycleBin";
import ShellPage from "pages/shell";
import SettingsPage from "pages/settings";
import LandingPage from "pages/landing";
import ShellsPage from "pages/shells";

function Routes() {
  return (
    <Switch>
      <Route path="/" element={<HomePage />} />
      <Route path="/s/:shellHandle" element={<ShellPage />} />
      <Route path="/u/:userAddress" element={<UserPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/landing" element={<LandingPage />} />
      <Route path="/shells" element={<ShellsPage />} />
      <Route path={`/${PAGES.RECYCLE_BIN}`} element={<RecycleBinPage />} />
    </Switch>
  );
}

export default Routes;
