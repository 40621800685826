import DEFAULT_POST_THUMB from "zephyr-resources/defaultPost.jpg";

export function getThumbnail(id: TxId, postsById: any) {
  const postMeta = postsById[id];
  const thumbnailTag = postMeta.tags.find((tag: any) => tag.name === "Thumbnail-URL");
  return thumbnailTag ? thumbnailTag.value : DEFAULT_POST_THUMB;
}

export function getTitle(id: TxId, postsById: any) {
  const postMeta = postsById[id];
  const tag = postMeta.tags.find((tag: any) => tag.name === "Title");
  return tag ? tag.value : "??";
}

export function getCreationTs(id: TxId, postsById: any) {
  const postMeta = postsById[id];
  const tag = postMeta.tags.find((tag: any) => tag.name === "Unix-Time");
  return tag ? tag.value * 1000 : 0;
}
