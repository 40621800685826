import classnames from "classnames";
import DialogConfirm from "components/dialogConfirm";
import React from "react";
import { create } from "redux/comments/slice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { openDialog } from "redux/modal/modalSlice";
import "./style.scss";

interface Props {
  postId: string;
  parentId: string; // Direct parent's commentId, or the postId if it's a top-level comment.
  groupId?: string; // If it's a reply (could be several levels down), provide the ancestor top-level commentId.
  setReplying?: (status: boolean) => void;
}

const CommentAdd = (props: Props) => {
  const dispatch = useAppDispatch();
  const { postId, parentId, groupId, setReplying } = props;
  const isCreating = useAppSelector((state) => state.comments.createStarted);
  const textRef = React.useRef<HTMLInputElement>(null);

  const isReply = !!parentId && postId !== parentId;
  const label = isReply ? "Reply" : "Comment";

  function sendComment() {
    if (textRef.current?.value) {
      dispatch(create(textRef.current.value, postId, parentId, groupId))
        .then(() => (textRef.current!.value = ""))
        .catch((error) =>
          dispatch(
            openDialog(DialogConfirm, {
              title: "Failed to post comment",
              message: error.message,
              hideCancel: true,
            })
          )
        );
    }
  }

  function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      sendComment();
    }
  }

  React.useEffect(function focusReplyInputOnMount() {
    if (isReply && textRef.current) {
      textRef.current.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classnames("card__post-comment", {
        "card__post-comment--reply": isReply,
        "card__post-comment--disabled": isCreating,
      })}
    >
      <input type="text" placeholder={label} ref={textRef} onKeyUp={handleKeyUp} />
      <input type="button" value={label} onClick={sendComment} />
      {isReply && setReplying && (
        <div className="comment__cancel-reply" title="Cancel" onClick={() => setReplying(false)}>
          𝘅
        </div>
      )}
    </div>
  );
};

export default CommentAdd;
