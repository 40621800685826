function getUnknownError(e: unknown) {
  let message;
  if (typeof e === "string") {
    // console.log(e);
    message = e;
  } else if (e instanceof Error) {
    // console.log(e.message);
    message = e.message;
  }
  return message;
}

export default getUnknownError;
