import { TxNode } from "arweave/types";

class Time {
  /**
   * resolveTimestamp
   * @param input
   * @return Unix epoch | null (could not determine) | undefined (input invalid)
   */
  static resolveTimestamp(input: TxNode | null | undefined) {
    if (!input) {
      return undefined;
    }

    const tsTags = [
      { name: "Unix-Time", toMsMultiplier: 1000 }, // standard
      { name: "Timestamp", toMsMultiplier: 1 }, // deprecated, for old arlocal data
    ];

    for (let i = 0; i < tsTags.length; ++i) {
      const tsTag = input.tags?.find((tag) => tag.name === tsTags[i].name);
      if (tsTag) {
        return parseInt(tsTag.value) * tsTags[i].toMsMultiplier;
      }
    }

    return null;
  }
}

export default Time;
