import { GatewayId } from "arweave/config";
import { Warp, WarpFactory } from "warp-contracts";
import { DeployPlugin } from "warp-contracts-plugin-deploy";

const environment = process.env.REACT_APP_WARPENV || "testnet";
let warp: Warp;

export function initializeWarp(gatewayId: GatewayId, arweave: any) {
  switch (gatewayId) {
    case "arweave.net":
    case "g8way.io":
      if (environment === "mainnet") {
        warp = WarpFactory.forMainnet().use(new DeployPlugin());
      } else if (environment === "testnet") {
        warp = WarpFactory.forTestnet().use(new DeployPlugin());
      }
      break;

    case "arlocal.zephyrdev.xyz":
      warp = WarpFactory.forLocal(443, arweave).use(new DeployPlugin());
      break;

    case "arlocal":
      warp = WarpFactory.forLocal(1984, arweave).use(new DeployPlugin());
      break;

    default:
      throw new Error(`Invalid gateway: ${gatewayId}`);
  }
}

export { warp };
