import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Ar from "arweave/ar";
import getUnknownError from "utils/unknownError";

type OwnerId = string; // user wallet address

interface UsersDict {
  [id: OwnerId]: ArAccount | null;
}

export interface UsersState {
  profilesById: UsersDict;
  fetchingProfilesById: OwnerId[];
}

const initialState: UsersState = {
  profilesById: {},
  fetchingProfilesById: [],
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserByAddress.pending, (state, action) => {
      const ownerId = action.meta.arg;
      const fetching = state.fetchingProfilesById;
      if (!fetching.includes(ownerId)) {
        fetching.push(ownerId);
      }
      state.fetchingProfilesById = fetching;
    });
    builder.addCase(fetchUserByAddress.fulfilled, (state, action) => {
      const { user } = action.payload;
      if (user) {
        const ownerId = action.meta.arg;
        state.profilesById[ownerId] = user;
      }
    });
    builder.addCase(fetchUserByAddress.rejected, (state, action) => {
      const ownerId = action.meta.arg;
      state.profilesById[ownerId] = null;
    });
  },
});

export const fetchUserByAddress = createAsyncThunk(
  "users/fetchUserByAddress",
  async (ownerId: OwnerId) => {
    try {
      const user = await Ar.account.getAccountsByAddress(ownerId);
      if (user) {
        return { user: user };
      } else {
        throw new Error("could not fetch user");
      }
    } catch (e: unknown) {
      const message = getUnknownError(e);
      return { error: message };
    }
  },
  {
    condition: (ownerId, { getState }) => {
      const { users } = getState() as { users: UsersState };
      const profileExists = Boolean(users.profilesById[ownerId]);
      const fetching = users.fetchingProfilesById.includes(ownerId);
      return !profileExists && !fetching;
    },
  }
);

export const selectProfileForId = (state: { users: UsersState }, id: OwnerId): ArAccount | null =>
  state.users.profilesById[id] || null;

export default usersSlice;
