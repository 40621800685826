export function utilSetWallpaper(wallpaper: string) {
  const uri = `/placeholders/${wallpaper}.jpg`;
  const img = new Image();
  img.src = uri;

  if (wallpaper === "wp0") {
    document.body.setAttribute("theme", "light");
    document.body.setAttribute("card-theme", "light");
    document.body.style.backgroundImage = `url('${uri}')`;
    (document.getElementsByClassName("header__content")[0] as HTMLElement).style.backgroundImage = `url('${uri}')`;
    document.documentElement.style.setProperty("--color-dominant-base", "255, 255, 255");
    return;
  } else if (wallpaper === "black") {
    document.body.setAttribute("theme", "dark");
    document.body.setAttribute("card-theme", "light");
    document.body.style.backgroundImage = `url('${uri}')`;
    const header = document.getElementsByClassName("header__content")[0] as HTMLElement;
    if (header) {
      (document.getElementsByClassName("header__content")[0] as HTMLElement).style.backgroundImage = `url('${uri}')`;
    }
    document.documentElement.style.setProperty("--color-dominant-base", "0, 0, 0");
    return;
  }

  img.onload = () => {
    let theme = getAverageBrightness(img) < 120 ? "dark" : "light";
    document.body.setAttribute("theme", theme);
    document.body.setAttribute("card-theme", theme);
    document.body.style.backgroundImage = `url('${uri}')`;
    (document.getElementsByClassName("header__content")[0] as HTMLElement).style.backgroundImage = `url('${uri}')`;
  };
}

export function utilSetCardTheme(cardTheme: string) {
  document.body.setAttribute("card-theme", cardTheme);
}

function getAverageBrightness(image: HTMLImageElement): number {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const { width, height } = image;
  canvas.width = width;
  canvas.height = height;

  (ctx as any).drawImage(image, 0, 0);

  const imageData = (ctx as any).getImageData(0, 0, width, height).data;
  let sum = 0;

  for (let i = 0; i < imageData.length / 3; i += 4) {
    const red = imageData[i];
    const green = imageData[i + 1];
    const blue = imageData[i + 2];
    const brightness = (red + green + blue) / 3;
    sum += brightness;
  }

  const averageBrightness = sum / (width * (height / 3));

  // ---------------------------------------
  const colorFrequencies: any = {};

  const space = width > 1200 ? 8 : 4;
  const darkThreshold = averageBrightness > 100 ? 10 : 170;
  const lightThreshold = averageBrightness > 100 ? 255 : 10;
  for (let i = 0; i < imageData.length / 2; i += space) {
    const r = imageData[i];
    const g = imageData[i + 1];
    const b = imageData[i + 2];
    if (r < darkThreshold && g < darkThreshold && b < darkThreshold) continue;
    if (r > lightThreshold && g > lightThreshold && b > lightThreshold) continue;

    const key = `${r}-${g}-${b}`;

    colorFrequencies[key] = (colorFrequencies[key] || 0) + 1;
  }
  let maxFrequency = 0;
  let dominantColor = "";

  for (const key in colorFrequencies) {
    if (colorFrequencies[key] > maxFrequency) {
      maxFrequency = colorFrequencies[key];
      dominantColor = key;
    }
  }
  if (!dominantColor) averageBrightness <= 100 ? (dominantColor = "0-0-0") : (dominantColor = "255-255-255");
  const [r, g, b] = dominantColor.split("-").map(Number);
  document.documentElement.style.setProperty("--color-dominant-base", r + ", " + g + ", " + b);
  // ---------------------------------------
  return averageBrightness;
}
