import "./style.scss";
import FileUploadMode from "components/uploadImage/internal/fileUploadMode";
import PreviewImage from "components/uploadImage/internal/previewImage";
import Tab from "components/uploadImage/internal/tab";
import UrlMode from "components/uploadImage/internal/urlMode";
import React from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import uploadImage from "redux/uploadImage/slice";

// ============================================================================
// How to use <UploadImage>:
// ============================================================================
// 1. Provide a unique id for each instance.
//      <UploadImage id="PostFormThumb" />
//      <UploadImage id="ShellAvatar" />
//      <UploadImage id="ShellBanner" />
// 2. The component allows the user to either upload an image to Arweave,
//    or provide a URL to an image.
// 3. The uploaded txId or entered URL can be obtained/observed from Redux
//    `state.uploadImage[id].xxx`.
//    See `UploadImageState` for all states that you can listen to.
// ============================================================================

interface Props {
  id: string;
  className?: string;
}

function UploadImage(props: Props) {
  const { id, className } = props;
  const dispatch = useAppDispatch();
  const mode = useAppSelector((state) => state.uploadImage[id]?.mode);

  React.useEffect(() => {
    dispatch(uploadImage.actions.initStateForId({ id }));
  }, [id, dispatch]);

  return mode ? (
    <div className={`upload-image ${className}`}>
      <PreviewImage id={id} />
      <div className="upload-image__body">
        <Tab id={id} />
        {mode === "upload" && <FileUploadMode id={id} />}
        {mode === "url" && <UrlMode id={id} />}
      </div>
    </div>
  ) : null;
}

export default UploadImage;
