import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setTheme, setWallpaper } from "redux/settings/settingsSlice";
import { Theme } from "redux/settings/settingsSlice.types";
import { utilSetWallpaper, utilSetCardTheme } from "utils/setTheme";
import classnames from "classnames";
import "./style.scss";

export interface Props {}

const Playground = (props: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardTheme = useAppSelector((state) => state.settings.theme);
  const wallpaper = useAppSelector((state) => state.settings.wallpaper);
  const [gridLayout, setGridLayout] = useState("list");
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    utilSetWallpaper("wp" + wallpaper);
  }, [wallpaper]);

  useEffect(() => {
    utilSetCardTheme(cardTheme);
  }, [cardTheme]);

  useEffect(() => {
    const elements = document.getElementsByClassName("shell-page__content")[0];
    if (elements) {
      if (gridLayout === "tiles") {
        elements.classList.add("shell-page__content--tiles");
      } else {
        elements.classList.remove("shell-page__content--tiles");
      }
    }
  }, [gridLayout]);

  useEffect(() => {
    if (location.pathname === "/") {
      utilSetWallpaper("wp" + wallpaper);
    }
  }, [location, wallpaper]);

  return (
    <div
      className={classnames("playground-container", {
        "playground-container--closed": !isVisible,
      })}
    >
      <h4 onClick={() => (!isVisible ? setIsVisible(true) : setIsVisible(false))}>Playground</h4>
      <label htmlFor="wallpaper">Wallpaper</label>
      <select name="wallpaper" value={wallpaper} onChange={(e) => dispatch(setWallpaper(e.target.value))}>
        <option disabled>-----------------------</option>
        <option value="0">None</option>
        <option disabled>-----------------------</option>
        <option value="1">Dark, Photo</option>
        <option value="2">Dark, Stylistic A</option>
        {/*<option value="3">3. Dark, Stylystic B</option>*/}
        {/*<option value="4">4. Dark, Stylystic C</option>*/}
        {/*<option value="5">5. Dark, Stylystic D</option>*/}

        <option disabled>-----------------------</option>
        <option value="3">Bright, Photo</option>
        <option value="4">Bright, Stylistic A</option>
        <option value="5">Bright, Stylistic B</option>

        <option disabled>-----------------------</option>
        <option value="6">Mixed A</option>

        <option disabled>-----------------------</option>
        <option value="7">Dark, Tetris</option>
        {/*<option value="11">11. Dark, Pacman</option>*/}
        <option value="8">Mixed, Mario</option>
      </select>

      <br />
      <br />
      <label htmlFor="theme">Theme</label>
      <select name="theme" value={cardTheme} onChange={(e) => dispatch(setTheme(e.target.value as Theme))}>
        <option value="light">Light</option>
        <option value="dark">Dark</option>
      </select>

      <br />
      <br />
      <label htmlFor="layout">Layout</label>
      <select name="layout" value={gridLayout} onChange={(e) => setGridLayout(e.target.value)}>
        <option value="list">List</option>
        <option value="tiles">Tiles</option>
      </select>
    </div>
  );
};

export default Playground;
