import DEFAULT_AVATAR from "zephyr-resources/defaultAvatar.png";
import DEFAULT_SHELL from "zephyr-resources/defaultShell.jpg";
import DEFAULT_POST from "zephyr-resources/defaultPost.jpg";

const AR_PROFILE_DEFAULT_AVATAR = "ar://OrG-ZG2WN3wdcwvpjz1ihPe4MI24QBJUpsJGIdL85wA";

class Images {
  static readonly imageGateway = "https://arweave.net/"; // can't use arlocal

  static resolveAvatar(avatarThumbnailUrl?: string) {
    const url = avatarThumbnailUrl || DEFAULT_AVATAR;

    if (url === AR_PROFILE_DEFAULT_AVATAR) {
      return DEFAULT_AVATAR;
    }

    return url.startsWith("ar://") ? url.replace("ar://", this.imageGateway) : url;
  }

  static resolvePost(postThumbnailUrl?: string) {
    const url = postThumbnailUrl || DEFAULT_POST;
    return url.startsWith("ar://") ? url.replace("ar://", this.imageGateway) : url;
  }

  static resolveShell(shellThumbnailUrl?: string) {
    const url = shellThumbnailUrl || DEFAULT_SHELL;
    return url.startsWith("ar://") ? url.replace("ar://", this.imageGateway) : url;
  }
}

export default Images;
