import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.scss";

export interface Props {
  user: any;
}

const UserMeta = (props: Props) => {
  const { user } = props;
  const userMeta = useRef<HTMLDivElement>(null);
  const prevUser = useRef<HTMLDivElement | null>(null);

  const info = useRef<HTMLDivElement>(null);
  const infoHeight = useRef(0);
  const infoMinHeight = 48;

  const headerHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--header-height"), 10);
  const spacingS = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--spacing-s"));
  const topOffset = headerHeight + spacingS * parseFloat(getComputedStyle(document.documentElement).fontSize);

  // TODO: fixme ts ignore
  useEffect(() => {
    // @ts-ignore
    if (user && info.current && user !== prevUser.current) {
      info.current.classList.remove("shell-meta__card--minimal");
      info.current.style.height = "auto";
      setTimeout(() => {
        if (info.current) {
          info.current.style.height = "auto";
          const { height } = info.current.getBoundingClientRect();
          infoHeight.current = height;
          info.current.style.height = infoHeight.current + "px";
        }
      }, 400);
      // @ts-ignore
      prevUser.current = user;
    }
  }, [user]);

  useEffect(() => {
    const handleScroll = () => {
      if (userMeta.current && info.current) {
        const { top } = userMeta.current.getBoundingClientRect();

        if (top <= topOffset) {
          info.current.classList.add("shell-meta__card--minimal");
          info.current.style.height = infoMinHeight + "px";
        } else {
          info.current.classList.remove("shell-meta__card--minimal");
          info.current.style.height = infoHeight.current + "px";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [infoHeight, topOffset]);

  if (user) {
    return (
      <div className="shell-meta" ref={userMeta}>
        <div className="shell-meta__card" ref={info}>
          {/* This will be better when we stop getting author info from the post itself */}
          <h2>u/{user.profile.name}</h2>
          <img src={`${user.profile.avatarURL}`} loading="lazy" />
          <p>About: {`${user.profile.bio}`}</p>
          <button>Follow</button>
        </div>
        <div className="shell-meta__card">
          <h3>Shell Rules</h3>
          <ol>
            <li>Get triggered by deviating opinions and behave like a child</li>
            <li>Please try to insult as many users as possible</li>
          </ol>
        </div>
        <div className="shell-meta__card">
          <h3>Favorites</h3>
          <ul className={"shell-meta__favorites"}>
            <li>
              <Link to={`/s/finalfantasy`}>
                <img src={`placeholders/shells/finalfantasy_avatar.jpg`} loading="lazy" />
              </Link>
            </li>
            <li>
              <Link to={`/s/animals`}>
                <img src={`placeholders/shells/animals_avatar.jpg`} loading="lazy" />
              </Link>
            </li>
            <li>
              <Link to={`/s/music`}>
                <img src={`placeholders/shells/music_avatar.jpg`} loading="lazy" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return <h1>oops</h1>;
};

export default UserMeta;
