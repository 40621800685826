import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MakeState } from "redux/utils";

interface SearchData {
  name: string; // Unique name for the search (e.g. Wunderbar, ChannelSearch, etc.).
  query: string; // The query string that user entered.
  txIds: TxId[];
}

export interface SearchState {
  results: { [name: string]: SearchData };
  isSearching: { [name: string]: boolean };
  shellMetasTEMP: { [id: TxId]: TxNode }; // [TODO] Move to shellsSlice.
}

// ****************************************************************************
// searchSlice
// ****************************************************************************

const searchSlice = createSlice({
  name: "search",
  initialState: MakeState<SearchState>({
    results: {},
    isSearching: {},
    shellMetasTEMP: {},
  }),
  reducers: {
    setIsSearching: (state, action: PayloadAction<{ name: string; isSearching: boolean }>) => {
      const { name, isSearching } = action.payload;
      state.isSearching[name] = isSearching;
    },
    storeResults: (state, action: PayloadAction<SearchData>) => {
      const { name, query, txIds } = action.payload;
      state.results[name] = { name, query, txIds };
    },
    storeShellMetasTEMP: (state, action: PayloadAction<{ metas: TxNode[] }>) => {
      const { metas } = action.payload;
      metas.forEach((meta) => (state.shellMetasTEMP[meta.id] = meta));
    },
  },
});

export default searchSlice;
