import { Comment } from "./types";

export function getCommentQueryKey(postId: string, parentId: string, groupId?: string) {
  // prettier-ignore
  return (
    `${postId ? `postId=${postId};` : ""}` +
    `${parentId ? `parentId=${parentId};` : ""}` +
    `${groupId ? `groupId=${groupId};` : ""}`
  );
}

function getErrorHeader(label: string) {
  return `\n${"-".repeat(30)}\n[${label}]`;
}

export async function checkArweaveApiResponse(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    // @ts-ignore
    const responseData = response.data;
    const errDetails = responseData.errors?.map((err: any) => err.message).join("\n") || "";
    throw new Error(`${getErrorHeader("GraphQL")} ${response.statusText} ${errDetails ? `\n  • ${errDetails}` : ""}`);
  }
}

export async function getTransactionsFromResponse(response: any) {
  return response?.data?.data?.transactions;
}

export function createPendingComment(
  txid: string,
  ownerAddr: string,
  contentType: "text/plain" | "text/markdown",
  postId: string,
  parentId: string,
  groupId?: string
) {
  const comment: Comment = {
    id: txid,
    ownerAddress: ownerAddr,
    contentType: contentType,
    postId,
    parentId,
    groupId: groupId || parentId,
    timestampMs: Date.now(),
    cursor: "",
  };
  return comment;
}

export function resolveSortBy(postId: string, parentId: string, state: AppState) {
  const isTopLevelComment = parentId === postId;
  if (isTopLevelComment) {
    return state.comments.postsUi[postId]?.sortBy || "desc";
  } else {
    return "asc"; // Replies always ascending
  }
}
