import { useRef, useState, useEffect, createElement, useCallback } from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { closeContextMenu } from "redux/modal/modalSlice";
import "./style.scss";

export const Menu = (props: any) => {
  const { children } = props;
  return <div>{children}</div>;
};

export interface MenuEntryProps {
  title: string;
  subtitle?: string;
  icon?: string;
  disabled?: boolean;
  action?: () => void;
}

export const MenuEntry = (props: MenuEntryProps) => {
  const { title, subtitle, action, disabled } = props;
  const dispatch = useAppDispatch();

  const onMenuEntrySelect = (action: any) => {
    action();
    dispatch(closeContextMenu());
  };

  return (
    <div
      className={classnames("menu-entry", {
        "menu-entry--disabled": disabled,
      })}
      onClick={() => onMenuEntrySelect(action)}
    >
      <div className="menu-entry__title">{title}</div>
      <div className="menu-entry__subtitle">{subtitle}</div>
    </div>
  );
};

export interface Props {}

const MenuWrapper = (props: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const contextMenu = useAppSelector((state) => state.modal.contextMenu);
  const contextMenuProps = useAppSelector((state) => state.modal.contextMenuProps);
  const isOpen = !!contextMenu;
  const [menuWidth, setMenuWidth] = useState(0);

  const target = (contextMenuProps as any) && (contextMenuProps as any).trigger.target;
  const top = (target && target.getBoundingClientRect().y + target.getBoundingClientRect().height + 10) || 0;
  const left =
    (target && target.getBoundingClientRect().x + target.getBoundingClientRect().width / 2 - menuWidth / 2) || 0;

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target as Node) &&
        target &&
        !target.contains(e.target as Node)
      ) {
        target.removeAttribute("active");
        dispatch(closeContextMenu());
      }
    },
    [dispatch, target]
  );

  useEffect(() => {
    const handleClick = (e: any) => {
      handleClickOutside(e);
    };

    if (isOpen) {
      if (menuRef.current) setMenuWidth(menuRef.current.getBoundingClientRect().width);
      document.addEventListener("click", handleClick);
    } else {
      document.removeEventListener("click", handleClick);
    }
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [isOpen, handleClickOutside]);

  if (isOpen) {
    return ReactDOM.createPortal(
      <div className="menu" style={{ top: top, left: left }} ref={menuRef}>
        {contextMenu && createElement(contextMenu, contextMenuProps)}
      </div>,
      document.body
    );
  } else {
    return null;
  }
};

export default MenuWrapper;
