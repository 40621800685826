import "@blocknote/core/style.css";
import { Theme } from "@blocknote/react";

export const darkTheme = {
  colors: {
    editor: {
      text: "#CFCFCF",
      background: "#1F1F1F",
    },
    menu: {
      text: "#CFCFCF",
      background: "#1F1F1F",
    },
    tooltip: {
      text: "#CFCFCF",
      background: "#161616",
    },
    hovered: {
      text: "#CFCFCF",
      background: "#161616",
    },
    selected: {
      text: "#CFCFCF",
      background: "#0F0F0F",
    },
    disabled: {
      text: "#3F3F3F",
      background: "#161616",
    },
    shadow: "#0F0F0F",
    border: "#161616",
    sideMenu: "#7F7F7F",
    highlightColors: {
      gray: {
        text: "#bebdb8",
        background: "#9b9a97",
      },
      brown: {
        text: "#8e6552",
        background: "#64473a",
      },
      red: {
        text: "#ec4040",
        background: "#be3434",
      },
      orange: {
        text: "#e3790d",
        background: "#b7600a",
      },
      yellow: {
        text: "#dfab01",
        background: "#b58b00",
      },
      green: {
        text: "#6b8b87",
        background: "#4d6461",
      },
      blue: {
        text: "#0e87bc",
        background: "#0b6e99",
      },
      purple: {
        text: "#8552d7",
        background: "#6940a5",
      },
      pink: {
        text: "#da208f",
        background: "#ad1a72",
      },
    },
  },
  borderRadius: 0,
  fontFamily: "Press Start 2P, IBM Plex Mono, monospace", // TODO: doesn't work?
} satisfies Theme;
