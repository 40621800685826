// import { useState, useRef } from "react";
import "./style.scss";

const PostsFilter = () => {
  // const { post } = props;

  return (
    <div className="posts-filter">
      <div className="posts-filter__entry">Hot</div>
      <div className="posts-filter__entry posts-filter__entry--active">New</div>
      <div className="posts-filter__entry">Top</div>
    </div>
  );
};

export default PostsFilter;
