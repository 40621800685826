import { AppInfo, Gateway } from "./config.types";

// ***************************************************************************
// Main
// ***************************************************************************

export const permissions: Array<string> = [
  "ACCESS_ADDRESS",
  "SIGN_TRANSACTION",
  "DISPATCH",
  "ENCRYPT",
  "DECRYPT",
  "ACCESS_PUBLIC_KEY",
  "SIGNATURE",
];

// @deprecated Use Ar.appInfo instead
export const appInfo: AppInfo = {
  name: process.env.REACT_APP_NAME,
  logo: process.env.REACT_APP_LOGO_URL,
};

export type GatewayId = "arweave.net" | "arlocal.zephyrdev.xyz" | "g8way.io" | "arlocal";

export const gateways: { [id: string]: Gateway } = {
  "arweave.net": {
    host: "arweave.net",
    port: 443,
    protocol: "https",
  },
  "arlocal.zephyrdev.xyz": {
    host: "arlocal.zephyrdev.xyz",
    port: 443,
    protocol: "https",
  },
  "g8way.io": {
    host: "g8way.io",
    port: 443,
    protocol: "https",
  },
  arlocal: {
    host: "localhost",
    port: 1984,
    protocol: "http",
  },
};

// ***************************************************************************
// ***************************************************************************
