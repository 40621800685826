import Account from "arweave-account";
import { GatewayId, gateways } from "arweave/config";

// ****************************************************************************
// AccountMgr
// ****************************************************************************

/**
 * Wrapper for managing and searching Arweave accounts/profiles per ArProfile
 * spec. It currently uses `arweave-account` under the hood.
 */
class AccountMgr {
  private static account: Account;

  private constructor() {
    // Singleton
  }

  static initialize(gatewayId: GatewayId = "arweave.net") {
    AccountMgr.account = new Account({
      cacheIsActivated: false,
      cacheSize: 100,
      cacheTime: 60000,
      gateway: {
        host: gateways[gatewayId].host,
        port: gateways[gatewayId].port,
        protocol: gateways[gatewayId].protocol,
        timeout: 20000,
        logging: false,
      },
    });
  }

  static async getActiveAddress() {
    if (window.arweaveWallet) {
      return window.arweaveWallet.getActiveAddress();
    } else {
      throw new Error("Connect to an Arweave wallet first.");
    }
  }

  static async connect() {
    return this.account.connect();
  }

  static async getActiveAccount() {
    return this.account.get(await this.getActiveAddress());
  }

  static async getAccountsByAddress(address: string): Promise<ArAccount> {
    return this.account.get(address);
  }

  /**
   * @param handle Format: `@${name}#${address_first_3_chars}${address_last_3_chars}`
   */
  static async getAccountByUniqueHandle(handle: string): Promise<ArAccount | null> {
    return this.account.find(handle);
  }

  static async findAccountsByName(name: string): Promise<ArAccount[]> {
    // `arweave-account` calls it `handleName`.
    return this.account.search(name);
  }

  static async writeProfile(profile: ArProfile) {
    return this.account.updateProfile(profile);
  }

  static getUserHandle(account: ArAccount | null) {
    if (account) {
      const { handle, profile } = account;
      const { handleName, name } = profile;
      return handleName || name || handle;
    }
    return null;
  }
}

// DEPRECATED (use getUserHandle above)
export function getHandleNameForProfile(profileData: ArAccount) {
  // this is tricky. some ppl have no names.
  // generate name from address in a clever way?
  // example of a real profile in the wild:
  /*
  {
  "txid": null,
  "addr": "xxx",
  "handle": "xxxxx...xxxxx",
  "profile": {
    "handleName": "",
    "avatar": "ar://xxx",
    "avatarURL": "https://arweave.net:443/xxx",
    "banner": "ar://xxx",
    "bannerURL": "https://arweave.net:443/xxx",
    "name": "",
    "bio": "",
    "email": "",
    "links": {},
    "wallets": {}
  }
}
   */
  if (profileData) {
    const { handle, profile } = profileData;
    const { handleName, name } = profile;

    return handleName || name || handle;
  }
  return null;
}

export default AccountMgr;
