import React from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { clearDevPageLogs } from "redux/ui/uiSlice";

function Log() {
  const dispatch = useAppDispatch();
  const logs = useAppSelector((state) => state.ui.devPage.logs);

  if (!logs) {
    return null;
  }

  return (
    <div className="dev-page__row">
      <div className="dev-page__log">{logs}</div>
      <button className="dev-page__clear" onClick={() => dispatch(clearDevPageLogs())}>
        Clear
      </button>
    </div>
  );
}

export default Log;
