import { useAppSelector } from "redux/hooks";

const ShellsPage = () => {
  const shellsObj = useAppSelector((state) => state.shells.shellsById);
  const shells = Object.entries(shellsObj);

  return (
    <>
      <ul>
        {shells.map((s) => (
          <li key={s[1].owner}>{s[1].name}</li>
        ))}
      </ul>
    </>
  );
};

export default ShellsPage;
