import * as AR_CFG from "arweave/config";
import classnames from "classnames";
import DialogConfirm from "components/dialogConfirm";
import Spinner from "components/spinner";
import React, { useRef } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { openDialog } from "redux/modal/modalSlice";
import uploadImage from "redux/uploadImage/slice";
import { resizeImage, uploadImageToArweave } from "redux/uploadImage/thunks";

interface Props {
  id: string;
}

export default function FileUploadMode(props: Props) {
  const { id } = props;
  const dispatch = useAppDispatch();
  const file = useAppSelector((state) => state.uploadImage[id]?.file);
  const uploadTxId = useAppSelector((state) => state.uploadImage[id]?.uploadTxId);
  const uploadErr = useAppSelector((state) => state.uploadImage[id]?.uploadErr);
  const gatewayId = useAppSelector((state) => state.settings.gatewayId);
  const inputRef = useRef<HTMLInputElement>(document.createElement("input"));
  const [uploading, setUploading] = React.useState(false);

  const getFilenameElem = () => {
    if (!uploadTxId) {
      return file ? file.name : "Select File To Upload";
    }
  };

  const getUploadedElem = () => {
    if (uploadTxId) {
      const gateway = AR_CFG.gateways[gatewayId];
      const urlBase = `${gateway.protocol}://${gateway.host}:${gateway.port}`;
      return (
        <>
          <div>File uploaded ✔️</div>
          <a href={`${urlBase}/${uploadTxId}`} target="_blank" rel="noopener noreferrer">
            {uploadTxId}
          </a>
        </>
      );
    }
  };

  const getUploadErrElem = () => {
    if (uploadErr) {
      return uploadErr.message === "Failed to fetch" ? "Upload failed" : uploadErr.message;
    }
  };

  const resizeAndUpload = async () => {
    if (file) {
      try {
        setUploading(true);
        const imgFile = file.size < 100 * 1024 ? file : ((await dispatch(resizeImage(file))).photo.data as File);
        const txId = await dispatch(uploadImageToArweave(imgFile));
        dispatch(uploadImage.actions.uploadDone({ id, txId }));
      } catch (err: any) {
        dispatch(uploadImage.actions.uploadFail({ id, err }));
        throw err;
      } finally {
        setUploading(false);
      }
    } else {
      throw new Error("No file selected");
    }
  };

  const handleBrowse = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault(); // Don't trigger 'submit' if part of a <form>
    inputRef.current.click();
  };

  const handleFileChange = (e: any) => {
    dispatch(uploadImage.actions.setFile({ id, file: e.target.files[0] }));
  };

  const handleUpload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    dispatch(
      openDialog(DialogConfirm, {
        title: "Upload Image",
        message: file?.name,
        okText: "Upload",
        onOk: () => {
          resizeAndUpload()
            // .then(() => dispatch(openDialog(DialogConfirm, { title: "File uploaded", hideCancel: true })))
            .catch((err) =>
              dispatch(
                openDialog(DialogConfirm, {
                  title: "Upload failed",
                  message: err.cause ? JSON.stringify(err.cause, null, 2) : err.message,
                  hideCancel: true,
                })
              )
            );
        },
      })
    );
  };

  return (
    <div className="upload-image__file-mode">
      <div className="file-mode-info">
        {uploading ? (
          <Spinner forceDarkBg />
        ) : (
          <>
            <div className="file-mode-info__name">{getFilenameElem()}</div>
            <div className="file-mode-info__txid">{getUploadedElem()}</div>
            <div className="file-mode-info__fail">{getUploadErrElem()}</div>
          </>
        )}
      </div>
      <div className={classnames("upload-image__browse", { disabled: uploading })}>
        <input onChange={handleFileChange} type="file" accept="image/*" ref={inputRef} style={{ display: "none" }} />
        <button onClick={handleBrowse}>Browse</button>
        {file && <button onClick={handleUpload}>Upload</button>}
      </div>
    </div>
  );
}
