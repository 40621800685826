import Ar from "arweave/ar";
import { FetchOptions } from "redux/posts/postsSlice";
import { TxNode } from "./types";

export function getTag(post: TxNode, tagName: string) {
  // support multiple duplicate tags?
  const tag = post && post.tags.find((tag) => tag.name && tag.name === tagName);
  if (tag) {
    return tag.value;
  }
  return "";
}
function getOwnersFilter(ownerAddress: string | undefined) {
  return ownerAddress ? `owners: ["${ownerAddress}"],` : "";
}

function getShellFilter(shellHandle: string | undefined) {
  return shellHandle
    ? `{
    name: "Shell-Handle",
    values: ["${shellHandle}"]
  },`
    : "";
}

function getAppVersion() {
  // Ignore for now so that older posts in the testnet appears
  return "";

  // return `{
  //   name: "App-Version",
  //   values: ["${Ar.appInfo.version}"]
  // },`;
}

function getBeforeCursor(cursor: string | undefined) {
  return cursor ? `before: ${cursor},` : "";
}

function getAfterCursor(cursor: string | undefined) {
  return cursor ? `after: ${cursor},` : "";
}

function getFirst(first: string | undefined) {
  return first ? `first: ${first},` : "";
}

function getLast(last: string | undefined) {
  return last ? `last: ${last},` : "";
}

function getContentType(type: string | undefined) {
  // should probably take text:rant etc
  return type === "text"
    ? `{
    name: "Content-Type",
    values: ["text/plain"]
  },`
    : "";
}

export const getTxQueryKey = (options: FetchOptions) => {
  let key = "";
  if (options.address) {
    key = key + `${options.address}`;
  }
  if (options.shellHandle) {
    key = key + `${options.shellHandle}`;
  }
  if (options.contentType) {
    key = key + `${options.contentType}`;
  }
  return key || "home"; // const this
};

export const getOptions = (owner?: string, shellHandle?: string, cursor?: string): FetchOptions => {
  const options: FetchOptions = {};
  if (owner) {
    options.address = owner;
  }
  if (shellHandle) {
    options.shellHandle = shellHandle;
  }
  if (cursor) {
    options.endCursor = cursor;
  }
  options.firstCount = "10";
  return options;
};

export const getTxQuery = (options: FetchOptions) => {
  const { address, shellHandle, endCursor, beforeCursor, firstCount, lastCount, contentType } = options;
  return `{
    transactions(${getFirst(firstCount)}
      ${getLast(lastCount)}
      ${getBeforeCursor(beforeCursor)}
      ${getAfterCursor(endCursor)}
      ${getOwnersFilter(address)}
      
      tags: [
        {
          name: "App-Name",
          values: ["${Ar.appInfo.name}"]
        },
        ${getAppVersion()}
        {
          name: "Post-Type",
          values: ["link", "text", "file"]
        },
        ${getContentType(contentType)}
        ${getShellFilter(shellHandle)}
     ]
    )
    {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          owner {
            address
          }
          data {
            size
          }
          block {
            height
            timestamp
          }
          tags {
            name,
            value
          }
        }
      }
    }
  }`;
};
