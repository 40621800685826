import { getShellForHandle } from "mock/mockShells";
import { useRef, useEffect, useMemo } from "react";
import "./style.scss";

export interface Props {
  handle: string;
}

const ShellMeta = (props: Props) => {
  const { handle } = props;
  const shellMeta = useRef<HTMLDivElement>(null);
  const prevShell = useRef<HTMLDivElement | null>(null);

  const info = useRef<HTMLDivElement>(null);
  const infoHeight = useRef(0);
  const infoMinHeight = 48;

  const shell = useMemo(() => {
    return getShellForHandle(handle || "");
  }, [handle]);

  const headerHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--header-height"), 10);
  const spacingS = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--spacing-s"));
  const topOffset = headerHeight + spacingS * parseFloat(getComputedStyle(document.documentElement).fontSize);

  // TODO: Fixme
  useEffect(() => {
    // @ts-ignore
    if (shell && info.current && shell !== prevShell.current) {
      info.current.classList.remove("shell-meta__card--minimal");
      info.current.style.height = "auto";
      setTimeout(() => {
        if (info.current) {
          info.current.style.height = "auto";
          const { height } = info.current.getBoundingClientRect();
          infoHeight.current = height;
          info.current.style.height = infoHeight.current + "px";
        }
      }, 400);
      // @ts-ignore
      prevShell.current = shell;
    }
  }, [shell]);

  useEffect(() => {
    const handleScroll = () => {
      if (shellMeta.current && info.current) {
        const { top } = shellMeta.current.getBoundingClientRect();

        if (top <= topOffset) {
          info.current.classList.add("shell-meta__card--minimal");
          info.current.style.height = infoMinHeight + "px";
        } else {
          info.current.classList.remove("shell-meta__card--minimal");
          info.current.style.height = infoHeight.current + "px";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [infoHeight, topOffset]);
  if (handle) {
    return (
      <div className="shell-meta" ref={shellMeta}>
        <div className="shell-meta__card" ref={info}>
          <h2>{handle}</h2>
          <img src={`placeholders/shells/music_avatar.jpg`} loading="lazy" />
          <p>About: {`Stuff about ${handle}`}</p>
          <button>Follow</button>
        </div>
        <div className="shell-meta__card">
          <h3>Shell Rules</h3>
          <ol>
            <li>Get triggered by deviating opinions and behave like a child</li>
            <li>Please try to insult as many users as possible</li>
          </ol>
        </div>
        <div className="shell-meta__card">
          <h3>Shell Moderators</h3>
        </div>
      </div>
    );
  }

  return <h1>OOPS</h1>;
};

export default ShellMeta;
