import Ar from "arweave/ar";
import postsSlice from "redux/posts/postsSlice";
import { gqlSearchPost, gqlSearchShell, gqlSearchUser } from "redux/search/query";
import searchSlice from "redux/search/slice";
import { fetchUserByAddress } from "redux/users/usersSlice";
import { AppDispatch, GetState } from "redux/store";

export function searchPosts(name: string, query: string, exact: boolean = true) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    if (query) {
      try {
        dispatch(searchSlice.actions.setIsSearching({ name, isSearching: true }));
        const result = await Ar.fetchGraphQL(gqlSearchPost(query, exact), !exact);

        if (result.transactions) {
          const postIds = result.transactions.edges.map((edge: any) => edge.node.id);
          dispatch(searchSlice.actions.storeResults({ name, query, txIds: postIds }));
          dispatch(postsSlice.actions.storePosts(result.transactions));
        }
      } catch (error) {
        throw error;
      } finally {
        dispatch(searchSlice.actions.setIsSearching({ name, isSearching: false }));
      }
    }
  };
}

export function searchShells(name: string, query: string, exact: boolean = true) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    if (query) {
      try {
        const result = await Ar.fetchGraphQL(gqlSearchShell(query, exact), exact);

        if (result.transactions) {
          const shellIds: TxId[] = [];
          const shellMetasTEMP: TxNode[] = []; // [TODO] Move to shellsSlice.

          result.transactions.edges.forEach((edge: any) => {
            shellIds.push(edge.node.id);
            shellMetasTEMP.push(edge.node);
          });

          dispatch(searchSlice.actions.storeResults({ name, query, txIds: shellIds }));
          dispatch(searchSlice.actions.storeShellMetasTEMP({ metas: shellMetasTEMP }));
        }
      } catch (error) {
        throw error;
      }
    }
  };
}

export function searchUsers(name: string, query: string, exact: boolean = true) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    // If arlocal, hardcode to Goldsky since arlocal doesn't have users.
    const exactOverride = Ar.activeGateway.id.startsWith("arlocal") ? false : exact;

    try {
      dispatch(searchSlice.actions.setIsSearching({ name, isSearching: true }));
      const result = await Ar.fetchGraphQL(gqlSearchUser(query, exactOverride), !exactOverride);

      if (result.transactions) {
        const idSet: Set<string> = new Set();

        result.transactions.edges.forEach((edge: any) => {
          if (!idSet.has(edge.node.owner.address)) {
            idSet.add(edge.node.owner.address);
            dispatch(fetchUserByAddress(edge.node.owner.address)); // batch, at least for redux?
          }
        });

        dispatch(searchSlice.actions.storeResults({ name, query, txIds: Array.from(idSet) }));
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(searchSlice.actions.setIsSearching({ name, isSearching: false }));
    }
  };
}
