import "./style.scss";
import { BlockNoteView, useBlockNote } from "@blocknote/react";
import { darkTheme } from "./theme";

interface Props {
  content: any;
}

function BlockViewer(props: Props) {
  const { content } = props;

  const editor = useBlockNote({ editable: false, initialContent: content || null });

  return <div className="block-viewer">{editor && <BlockNoteView editor={editor} theme={darkTheme} />}</div>;
}

export default BlockViewer;
