/**
 * The UI slice serves as a general-purpose global state store.
 *
 * Uses:
 * - As a place to put global state that doesn't fit any existing slice.
 * - A replacement for React.Context.
 *     - Issues with Context: (1) is troublesome to set up (2) easily broken
 *       when the component is moved/re-used outside the context (3) requires
 *       the reader to figure out where it was set up in order to tweak, etc.
 *     - Sticking to Redux makes it easier -- one less state-management system.
 * - A replacement to passing useState objects around.
 *     - It's often annoying trying to traverse the tree to find the state source.
 * - As a place to share component status.
 *     - For example, a distant component might want to know if our component
 *       is active and what state it is in. The usual solution seen is to add a
 *       classname and then query the DOM at the other side. This is hackish and
 *       has subtle problems w.r.t React lifecycles.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UiState } from "./uiSlice.types";

// ****************************************************************************
// uiSlice
// ****************************************************************************

const initialState: UiState = {
  devPage: {
    profile: {},
    logs: "",
  },

  postCards: {},
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    // --- devPage ---
    setDevPageProfile(state, action) {
      state.devPage.profile = action.payload;
    },
    appendDevPageLog(state, action: PayloadAction<any | { log: string; separate: boolean }>) {
      const hasOptions = typeof action.payload === "object" && action.payload.hasOwnProperty("separate");
      const sep = !hasOptions || action.payload.separate ? "-".repeat(80) : "";
      const log = hasOptions ? action.payload.log : action.payload;
      state.devPage.logs = `${JSON.stringify(log, null, 2)}\n${sep}\n${state.devPage.logs}`;
    },
    clearDevPageLogs(state) {
      state.devPage.logs = "";
    },
    // --- postCards ---
    showThread(state, action: PayloadAction<{ postId: string; threadId: string }>) {
      const { postId, threadId } = action.payload;
      state.postCards[postId] = { activeThreadId: threadId };
    },
    hideThread(state, action: PayloadAction<{ postId: string }>) {
      const { postId } = action.payload;
      if (state.postCards[postId]) {
        delete state.postCards[postId].activeThreadId;
      }
    },
  },
});

export const { setDevPageProfile, appendDevPageLog, clearDevPageLogs, showThread, hideThread } = uiSlice.actions;
export default uiSlice;
