import BlockViewer from "components/blockViewer";
import React, { useState, useRef, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Link } from "react-router-dom";
import { hideThread } from "redux/ui/uiSlice";
import classnames from "classnames";
import Ar from "arweave/ar";
import { TxNode } from "arweave/types";
import dayjs from "lib/dayjs";
import { mockShells } from "mock/mockShells";
import { useGetScreenSize } from "effects/use-screen-details";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { openContextMenu } from "redux/modal/modalSlice";
import { fetchUserByAddress, selectProfileForId } from "redux/users/usersSlice";
import { selectPostForId, selectContentForId, fetchPostContent } from "redux/posts/postsSlice";
import Icon from "components/icons";
import ShellName from "components/shellName";
import Image from "components/image";
import Oembed from "components/oembed";
import Replies from "components/posts/post/replies";
import Comments from "./comments";
import CommentAdd from "./commentAdd";
import PostMenu from "./postMenu";
import DEFAULT_POST_THUMB from "zephyr-resources/defaultPost.jpg";
import "./style.scss";

const FALLBACK_SHELL = mockShells.find((shell) => shell.name.handle === "fallback");

export interface Props {
  post: string;
}

const Post = (props: Props) => {
  const dispatch = useAppDispatch();
  const { post } = props; //shellHandle?
  const size = useGetScreenSize();
  const [isExpandable, setIsExpandable] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const content = useRef<HTMLDivElement>(null);
  const card = useRef<HTMLDivElement>(null);
  const headerHeight = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--header-height"));
  const threshold = 124.6;
  const postMeta: TxNode | null = useAppSelector((state) => selectPostForId(state, post || ""));
  const deletingTxIds = useAppSelector((state) => state.recycleBin.deletingTxIds);

  const windows = useRef<HTMLDivElement>(null);

  const postTags: PostTags = React.useMemo(() => {
    return postMeta?.tags.reduce((acc: any, tag) => {
      acc[tag.name] = tag.value;
      return acc;
    }, {});
  }, [postMeta]);

  const Reactions = () => {
    return (
      <div className="card__reactions">
        <div className="card__reaction-group">
          <div className="card__reaction--icon">
            <Icon icon="like" size={24} />
          </div>
          <div className="card__reaction--total">{Math.floor(Math.random() * 301)}</div>
          <div className="card__reaction--icon">
            <Icon icon="dislike" size={24} />
          </div>
        </div>
        {size === "mobile" && (
          <div className="card__reaction-group">
            <div className="card__reaction--icon">
              <Icon icon="comments" size={24} /> {Math.floor(Math.random() * 301)}
            </div>
          </div>
        )}
      </div>
    );
  };

  const shell = mockShells.find((shell) => shell.id === postTags["Shell-ID"]) || FALLBACK_SHELL!;

  const ownerId = postMeta?.owner?.address || "";
  const postId = postMeta?.id || "";
  // const contentType = postTags["Content-Type"];
  const title = postTags.Title;
  const shellHandle = shell.name.handle;

  const postData = useAppSelector((state) => selectContentForId(state, post)) || "";
  // fetchingProfile
  const profileData = useAppSelector((state) => selectProfileForId(state, ownerId));
  const name = Ar.account.getUserHandle(profileData)!;
  // TODO: decide how to get handle names. arprofile handles are inconsistent.

  const avatarUrl = Ar.images.resolveAvatar(profileData?.profile.avatar);

  const timestamp = Ar.time.resolveTimestamp(postMeta);
  const dt = dayjs(timestamp);
  // TODO: tweak away, but should probably go into DateTime component later
  const timestampStr = timestamp ? `${dt.format("HH:mm, MMM D YYYY")} • ${dt.fromNow()}` : "---";

  const threadId = useAppSelector((state) => state.ui.postCards[postId]?.activeThreadId);

  useEffect(() => {
    if (windows.current) {
      if (threadId) {
        windows.current.style.marginLeft = "-100%";
      } else {
        windows.current.style.marginLeft = "0";
      }
    }
  }, [threadId]);

  useEffect(() => {
    if (postId && !postData) {
      dispatch(fetchPostContent(postId));
    }
    if (content.current && postData) {
      setIsExpandable(content.current.scrollHeight > 420 ? true : false);
    }
  }, [dispatch, postId, postData, content]);

  useEffect(() => {
    dispatch(fetchUserByAddress(ownerId));
  }, [dispatch, ownerId]);

  useEffect(() => {
    return () => {
      dispatch(hideThread({ postId }));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleExpandCard = () => {
    if (card.current) {
      if (isExpanded) {
        card.current.classList.remove("post__container--expanded");
        card.current.style.maxHeight = "650px";
        card.current.style.height = "unset";
        setIsExpanded(false);
      } else {
        if (card.current && content.current) {
          card.current.style.height = content.current.scrollHeight + threshold + "px";
          card.current.classList.add("post__container--expanded");
          const scrollPosition = window.scrollY + card.current.getBoundingClientRect().top - headerHeight - 4;
          card.current.style.maxHeight = "unset";
          window.scrollTo({ top: scrollPosition, behavior: "smooth" });
          setIsExpanded(true);
        }
      }
    }
  };

  function openPostMenu(e: any) {
    e.target.setAttribute("active", true);
    dispatch(openContextMenu(PostMenu, { postId, trigger: e }));
  }

  const Description = (props: any) => {
    if (props.contentType === "application/json" && props.text) {
      const fallback = (
        <div>
          <p>
            <i>Failed to display post</i>
          </p>
          <small>{postId}</small>
        </div>
      );

      return (
        <ErrorBoundary fallback={fallback}>
          <BlockViewer content={props.text} />
        </ErrorBoundary>
      );
    }

    return <p dangerouslySetInnerHTML={{ __html: props.text.replace(/\n/g, "<br />") }} />;
  };

  return (
    <div
      className={classnames("post", {
        "post--not-expandable": !isExpandable,
        "post--deleting": deletingTxIds.includes(postId),
      })}
    >
      <div
        className={classnames("post__expansion-button", {
          "post__expansion-button--expanded": isExpanded,
        })}
        onClick={handleExpandCard}
      >
        <Icon icon="expand" size={20} />
      </div>
      <div className="post__container" ref={card}>
        <div className="post__content">
          <div className="card">
            <div className="card__header">
              <Link to={`/s/${shellHandle}`}>
                <img className="card__shell-thumb" src={shell.picture.thumbnail} loading="lazy" />
              </Link>
              {shellHandle && (
                <Link to={`/u/${ownerId}`}>
                  <img className="card__shell-thumb-user" src={avatarUrl} loading="lazy" />
                </Link>
              )}
              <div className="card__shell-meta">
                <div className="card__shell-name">
                  {shellHandle && (
                    <>
                      <ShellName
                        shell={{ type: "group", title: shellHandle, id: ownerId, avatar: shell.picture.thumbnail }}
                      />
                      <span> posted by </span>
                    </>
                  )}

                  <ShellName shell={{ type: "user", title: name, id: ownerId, avatar: avatarUrl }} />
                </div>

                <div className="card__shell-date">{timestampStr}</div>
              </div>
              <div className="card__shell-menu" onClick={openPostMenu}>
                <Icon icon="menu" />
              </div>
            </div>
            <div
              className="card__content"
              ref={content}
              onClick={isExpandable && !isExpanded ? handleExpandCard : undefined}
            >
              {postTags["Post-Type"] === "link" && (
                <>
                  <div className="card__preview">
                    <div className="card__thumbnail">
                      <Oembed url={postData} />
                      <h2>{title}</h2>
                    </div>
                    {size !== "mobile" && <Reactions />}
                  </div>
                  <div className="card__text">
                    <Description text={postData} />
                  </div>
                </>
              )}
              {(postTags["Post-Type"] === "text" || postTags["Post-Type"] === undefined) && (
                <>
                  <div className="card__preview">
                    <div className="card__thumbnail">
                      <Image src={postTags["Thumbnail-URL"]} fallback={DEFAULT_POST_THUMB} />
                      <h2>{title}</h2>
                    </div>
                    {size !== "mobile" && <Reactions />}
                  </div>

                  <div className="card__text">
                    <Description text={postData} contentType={postTags["Content-Type"]} />
                  </div>
                </>
              )}
              {postTags["Post-Type"] === "file" && (
                <>
                  <div className="card__preview">
                    <div className="card__thumbnail">
                      <Oembed url={postData} />
                      <h2>{title}</h2>
                    </div>
                    {size !== "mobile" && <Reactions />}
                  </div>
                  <div className="card__text">{postTags.Description}</div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="post__comments">
          <div className="post__meta">
            <div className="post__label">{`${postTags["Post-Type"]}:${postTags.Topic}`}</div>
            <div className="post__shell">{shell.name.handle}</div>
          </div>
          <div className="comments-windows" ref={windows}>
            <div className="comments-window">
              <Comments postId={postId} />
              <CommentAdd postId={postId} parentId={postId} />
            </div>
            <div className="comments-window">{threadId && <Replies postId={postId} commentId={threadId} />}</div>
          </div>
        </div>
        {size === "mobile" && <Reactions />}
      </div>
    </div>
  );
};

export default Post;
