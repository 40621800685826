import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";

const serializableCheck = {
  ignoredPaths: [/^modal.*$/, /^uploadImage\..*\.file$/, /^uploadImage\..*\.uploadErr$/],
  ignoredActions: [
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    "modal/openDialog",
    "modal/openContextMenu",
    "modal/showShellPreview",
    "ui/appendDevPageLog",
    "uploadImage/setFile",
  ],
  ignoredActionPaths: ["payload.err"],
};

export default serializableCheck;
