import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Ar from "arweave/ar";
import { getTxQuery, getTxQueryKey } from "arweave/txQuery";
import { Transactions, TxNode } from "arweave/types";
import { fetchDeletionState } from "redux/recycleBin/thunks";
import { store } from "redux/store";
import getUnknownError from "utils/unknownError";

export interface FetchOptions {
  address?: string;
  shellHandle?: string;
  endCursor?: string;
  beforeCursor?: string;
  firstCount?: string;
  lastCount?: string;
  contentType?: string;
}

interface PostsDict {
  [key: string]: TxNode;
}

interface ContentDict {
  [key: string]: string;
}
interface QueryResultsDict {
  [key: string]: QueryResults;
}
interface QueryResults {
  items: string[];
  endCursor: string | undefined;
  hasNextPage: boolean;
  loading: string;
  error: string | undefined;
}

export interface PostsState {
  postsById: PostsDict;
  contentById: ContentDict;
  postIdsByQuery: QueryResultsDict;
}

const initialState: PostsState = {
  postsById: {},
  postIdsByQuery: {},
  contentById: {},
};

const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    removePost: (state, action: PayloadAction<TxId>) => {
      delete state.postsById[action.payload];
      delete state.contentById[action.payload];
      if (state.postIdsByQuery) {
        for (const query in state.postIdsByQuery) {
          const items = state.postIdsByQuery[query].items;
          const index = items.indexOf(action.payload);
          if (index > -1) {
            items.splice(index, 1);
          }
        }
      }
    },
    purgePosts: (state, action: PayloadAction<string>) => {
      delete state.postIdsByQuery[action.payload];
    },
    storePosts: (state, action: PayloadAction<Transactions>) => {
      const transactions = action.payload;
      transactions?.edges.forEach((edge) => {
        if (!state.postsById[edge.node.id]) {
          state.postsById[edge.node.id] = edge.node;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPosts.pending, (state, action) => {
      const options = action.meta.arg;
      const query = getTxQueryKey(options);
      const postIdsByQuery = state.postIdsByQuery;
      if (postIdsByQuery[query]) {
        postIdsByQuery[query].loading = "loading";
      } else {
        postIdsByQuery[query] = {
          error: undefined,
          items: [],
          endCursor: undefined,
          hasNextPage: false,
          loading: "loading",
        };
      }
    });
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      const { options, txs } = action.payload;
      const query = getTxQueryKey(options);
      if (txs) {
        const { pageInfo, edges } = txs;

        const postsById = state.postsById;
        const postIdsByQuery = state.postIdsByQuery;
        let endCursor: string = "";
        let hasNextPage = pageInfo.hasNextPage;
        let newItems: string[] = [];
        for (let i = 0; i < edges.length; i++) {
          const edge = edges[i];
          const { node, cursor } = edge;
          postsById[node.id] = node;
          newItems.push(node.id);
          endCursor = cursor;
        }
        if (postIdsByQuery[query] && postIdsByQuery[query].items.length) {
          postIdsByQuery[query] = {
            error: undefined,
            items: [...postIdsByQuery[query].items, ...newItems],
            endCursor: endCursor,
            hasNextPage: hasNextPage,
            loading: "idle",
          };
        } else {
          postIdsByQuery[query] = {
            error: undefined,
            items: newItems,
            endCursor: endCursor,
            hasNextPage: hasNextPage,
            loading: "idle",
          };
        }
        state.postsById = postsById;
        state.postIdsByQuery = postIdsByQuery;
      }
    });
    builder.addCase(fetchPosts.rejected, (state, action) => {});
    // builder.addCase(fetchPostContent.pending, (state, action) => {});
    builder.addCase(fetchPostContent.fulfilled, (state, action) => {
      const { data, id } = action.payload;
      const contentById = state.contentById;
      contentById[id] = data;
    });
    builder.addCase(fetchPostContent.rejected, (state, action) => {});
  },
});

export const fetchPostContent = createAsyncThunk("posts/fetchPostContent", async (postId: string) => {
  try {
    const response = await Ar.arweave.api.get(`/${postId}`);
    if (response.data) {
      return { data: response.data, id: postId };
    } else {
      throw new Error("could not fetch post content");
    }
  } catch (e: unknown) {
    const message = getUnknownError(e);
    return { error: message, id: postId };
  }
});

export const fetchPosts = createAsyncThunk(
  "posts/fetchPosts",
  async (options: FetchOptions) => {
    try {
      const response = await Ar.arweave.api.post("/graphql", {
        query: getTxQuery(options),
      });
      if (response.status >= 400) {
        throw new Error("request failed");
      }
      const txs: Transactions = response?.data?.data?.transactions;

      try {
        // - Note: not using ThunkAPI.dispatch() because it's not an RTK thunk.
        // - [ ] Cache deletion state in Redux.
        const resp: any = await store.dispatch(
          fetchDeletionState(
            txs.edges.map((edge) => ({
              txId: edge.node.id,
              userId: edge.node.owner.address,
            }))
          )
        );

        // Prune deleted posts before saving
        txs.edges = txs.edges.filter((edge) => resp.result[edge.node.id] === false);
      } catch (err: any) {
        console.error(`fetchPost: failed to fetch deletion state: ${err.message}`);
      }

      return { options, txs };
    } catch (e: unknown) {
      const message = getUnknownError(e);
      return { options, txs: null, error: message };
    }
  },
  {
    // cancel the action dispatch and avoid duplicate calls
    condition: (options, { getState, extra }) => {
      const { posts } = getState() as AppState;
      const query = getTxQueryKey(options);
      const postByQuery = posts.postIdsByQuery[query];
      if (postByQuery && postByQuery.loading === "loading") {
        return false;
      }
      if (postByQuery && !options.endCursor) {
        return false;
      }
      return true;
    },
  }
);

const EMPTY_POST_ITEMS: string[] = [];

export const selectItemsForId = (state: AppState, id: string): string[] =>
  state.posts.postIdsByQuery[id]?.items || EMPTY_POST_ITEMS;

export const selectBottomCursorForId = (state: AppState, id: string): string | undefined =>
  state.posts.postIdsByQuery[id]?.endCursor;

export const selectHasNextPageForId = (state: AppState, id: string): boolean | undefined =>
  state.posts.postIdsByQuery[id]?.hasNextPage;

export const selectLoadingForId = (state: AppState, id: string): string | undefined =>
  state.posts.postIdsByQuery[id]?.loading;

export const selectErrorForId = (state: AppState, id: string): string | undefined =>
  state.posts.postIdsByQuery[id]?.error;

export const selectPostForId = (state: AppState, id: string): TxNode | null => state.posts.postsById[id] || null;

export const selectContentForId = (state: AppState, id: string): string | null => state.posts.contentById[id] || null;

export const selectIsPostMine = (state: AppState, postId: TxId) => {
  const post = state.posts.postsById[postId];
  const myAddress = state.wallet.address;
  return myAddress && post && myAddress === post.owner.address;
};

export const { purgePosts } = postsSlice.actions;
export default postsSlice;
