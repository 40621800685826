import Icon from "components/icons";
import PostForm, { Props as PostFormProps } from "components/postForm";
import { useAppDispatch } from "redux/hooks";
import { openDialog } from "redux/modal/modalSlice";
import postFormSlice from "redux/postForm/slice";
import "./styles.scss";

export interface Props {
  onClick?: () => void; // optional override
}

const PostButtons = (props: Props) => {
  const dispatch = useAppDispatch();
  const { onClick } = props;

  function handlePostClicked(tab?: PostType) {
    if (onClick) {
      onClick();
    } else {
      if (tab) {
        dispatch(postFormSlice.actions.switchTab(tab));
      }
      dispatch(openDialog<PostFormProps>(PostForm, { onDone: () => {} }));
    }
  }

  return (
    <section className="shell-page__header">
      <div className="post__types">
        <button className="post__button post__button--icon" onClick={() => handlePostClicked("link")}>
          <Icon icon="link" />
        </button>
        <button className="post__button post__button--icon" onClick={() => handlePostClicked("text")}>
          <Icon icon="text" size={18} width={18} height={18} />
        </button>
        <button className="post__button post__button--icon" onClick={() => handlePostClicked("file")}>
          <Icon icon="video" height={16} />
        </button>
      </div>
      <button className="post__button post__button--main" onClick={() => handlePostClicked()}>
        POST
      </button>
    </section>
  );
};

export default PostButtons;
