// ****************************************************************************
// convertResponseToJson
// ****************************************************************************

export function convertResponseToJson(response: Response): Promise<any> {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      resolve(response.json());
    } else {
      response
        .text()
        .then((text) => reject(new Error(text)))
        .catch(() => reject(new Error(response.statusText)));
    }
  });
}

// ****************************************************************************
// ****************************************************************************
