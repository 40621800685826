import { createAsyncThunk, createSlice, Dispatch } from "@reduxjs/toolkit";
import { readShell, shellDeployFromTx, writeShell } from "warp/shells/shellApi";

export interface Shell {
  moderators: string[];
  contributors: string[];
  blockList: string[];
  owner: null;
  recommended: string[];
  pinned: string[];
  name: string;
}

export interface ShellsById {
  [key: string]: Shell;
}

export interface ShellGroupStatusDict {
  [key: string]: ShellGroupStatus; // timestamp | status
}

export interface ShellGroupStatus {
  lastFetched?: number; // timestamp
  fetching: boolean;
}
export interface ShellsState {
  recentShells: Array<string>;
  modShells: Array<string>;
  ownShells: Array<string>;
  shellsById: ShellsById;
  isDeploying: boolean;
  isWriting: boolean;
  deployError: string | null;
  writeError: string | null;
  deployedTxid: string | null; // this mainly tells the component it worked.
  fetchingById: Array<string>;
  fetchingByGroup: string[];
  fetchStatusByGroup: ShellGroupStatusDict;
}

export interface WriteData {
  shellTx: string;
  fnName: string;
  value: string;
}

export type GroupLabel = "ownShells" | "modShells" | "recentShells";

const initialState: ShellsState = {
  /*
    ShellsById: populated as searched
   */
  recentShells: [],
  modShells: [],
  ownShells: [],
  shellsById: {},
  isDeploying: false,
  isWriting: false,
  deployError: null,
  writeError: null,
  deployedTxid: null,
  fetchingById: [],
  fetchingByGroup: [],
  fetchStatusByGroup: {},
};

const shellsSlice = createSlice({
  name: "shells",
  initialState,
  reducers: {
    clearDeploy: (state) => {
      state.deployedTxid = null;
      state.isDeploying = false;
      state.deployError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("wallet/setConnected", (state, action: any) => {
      if (action.payload.connected) {
        state.fetchStatusByGroup = {};
      }
    });
    /* DEPLOY */
    builder.addCase(doDeployShell.pending, (state, action) => {
      state.isDeploying = true;
      state.deployError = null;
    });
    builder.addCase(doDeployShell.fulfilled, (state, action) => {
      const result = action.payload;
      const { shell, shellTx } = result;
      if (!state.ownShells.includes(shellTx)) {
        state.ownShells.push(shellTx);
      }

      let shellsById = state.shellsById;
      shellsById[shellTx] = shell;
      state.shellsById = shellsById;
      state.deployedTxid = shellTx;
    });
    builder.addCase(doDeployShell.rejected, (state, action) => {
      state.isDeploying = false;
      state.deployError = action.error.message || "Unknown Error Deploying";
    });
    /* WRITE */
    builder.addCase(doWriteShell.pending, (state, action) => {
      state.isWriting = true;
      state.writeError = null;
    });
    builder.addCase(doWriteShell.fulfilled, (state, action) => {
      const result = action.payload;
      const { shell, shellTx } = result;

      let shellsById = state.shellsById;
      shellsById[shellTx] = shell;
      state.shellsById = shellsById;
    });
    builder.addCase(doWriteShell.rejected, (state, action) => {
      state.isWriting = false;
      state.writeError = action.error.message || "Unknown Error Deploying";
    });
    /* READ */
    builder.addCase(doReadShell.pending, (state, action) => {
      const txid = action.meta.arg;
      state.fetchingById.push(txid);
    });
    builder.addCase(doReadShell.fulfilled, (state, action) => {
      const result = action.payload;
      const { shell, shellTx, address: userAddress } = result;

      let shellsById = state.shellsById;
      shellsById[shellTx] = shell;
      state.shellsById = shellsById;
      const fetchingById = state.fetchingById;
      const newFetchingById = fetchingById.filter((item) => item !== shellTx);
      state.fetchingById = newFetchingById;
      if (shell.owner === userAddress && !state.ownShells.includes(shellTx)) {
        state.ownShells.push(shellTx);
      }
      if (!state.recentShells.includes(shellTx)) {
        state.recentShells.push(shellTx);
      }
      if (shell.moderators.includes(userAddress) && !state.modShells.includes(userAddress)) {
        state.modShells.push(shellTx);
      }
    });
    builder.addCase(doReadShell.rejected, (state, action) => {
      const shellTx = action.meta.arg;
      const fetchingById = state.fetchingById;
      state.fetchingById = fetchingById.filter((item) => item !== shellTx);
      // record error / set null?
    });
    /* READ MANY */
    builder.addCase(doReadShells.pending, (state, action) => {
      const readShellsOptions = action.meta.arg;
      const { groupLabel } = readShellsOptions;
      const fetchStatusByGroup = state.fetchStatusByGroup;
      const currentStatus = fetchStatusByGroup[groupLabel] || {};
      currentStatus["fetching"] = true;
      state.fetchStatusByGroup[groupLabel] = currentStatus;
    });
    builder.addCase(doReadShells.fulfilled, (state, action) => {
      const groupLabel = action.payload;
      const fetchStatusByGroup = state.fetchStatusByGroup;
      const currentStatus = fetchStatusByGroup[groupLabel] || {};
      currentStatus["fetching"] = false;
      currentStatus["lastFetched"] = Date.now();
      state.fetchStatusByGroup[groupLabel] = currentStatus;
    });
    builder.addCase(doReadShells.rejected, (state, action) => {
      const readShellsOptions = action.meta.arg;
      const { groupLabel } = readShellsOptions;
      const fetchStatusByGroup = state.fetchStatusByGroup;
      const currentStatus = fetchStatusByGroup[groupLabel] || {};
      currentStatus["fetching"] = false;
      // TODO: handleError
      state.fetchStatusByGroup[groupLabel] = currentStatus;
    });
  },
});

export const doDeployShell = createAsyncThunk("shells/doDeployShell", async (shellName: string) => {
  const shellTx = await shellDeployFromTx(shellName);
  if (!window.arweaveWallet) {
    throw new Error("No wallet connected. Please sign in first.");
  }
  // Todo loop until timeout, retry read if necessary?
  await new Promise((r) => setTimeout(r, 1000));
  const state = await readShell(shellTx);
  // @ts-ignore
  const shellState: Shell = state.cachedValue.state;

  return { shell: shellState, shellTx };
});

export const doWriteShell = createAsyncThunk("shells/doWriteShell", async (writeData: WriteData) => {
  if (!window.arweaveWallet) {
    throw new Error("No wallet connected. Please sign in first.");
  }
  const { shellTx, fnName, value } = writeData;
  const res = await writeShell(shellTx, fnName, value); // should return
  console.log("write res", res);
  // Todo loop until timeout
  await new Promise((r) => setTimeout(r, 1000));

  const contractState = await readShell(shellTx);
  // @ts-ignore
  const shellState: Shell = contractState.cachedValue.state;

  return { shell: shellState, shellTx };
});

export const doReadShell = createAsyncThunk(
  "shells/doReadShell",
  async (shellTx: string) => {
    if (!window.arweaveWallet) {
      throw new Error("No wallet connected. Please sign in first.");
    }
    const address = await window.arweaveWallet.getActiveAddress();
    const contractState = await readShell(shellTx);
    // @ts-ignore
    const shellState: Shell = contractState.cachedValue.state;
    return { shell: shellState, shellTx, address }; // passing walletAddress here because it's needed in builder case
  },
  {
    condition: (shellTx, { getState, extra }) => {
      const { shells } = getState() as AppState;
      if (shells.fetchingById.includes(shellTx)) {
        return false;
      }
    },
  }
);

interface ReadShellsOptions {
  groupLabel: GroupLabel;
  // forceFetch: boolean // fetch whether exists or not
}

export const doReadShells = createAsyncThunk(
  "shells/doReadShells",
  async (readParams: ReadShellsOptions, { dispatch, getState }) => {
    if (!window.arweaveWallet) {
      throw new Error("No wallet connected. Please sign in first.");
    }
    const state = getState() as AppState;
    const shells: ShellsState = state.shells;

    const { groupLabel } = readParams;
    const shellTxs = shells[groupLabel];

    for (let i = 0; i < shellTxs.length; i++) {
      // TODO if it's not already loaded OR if it was loaded before timestamp...
      await dispatch(doReadShell(shellTxs[i]));
    }

    return groupLabel;
  },
  {
    condition: (readParams, { getState, extra }) => {
      const { shells } = getState() as AppState;
      if (shells.fetchStatusByGroup[readParams.groupLabel]?.fetching) {
        return false;
      }
    },
  }
);

export function clearDeploy() {
  return (dispatch: Dispatch) => {
    dispatch({ type: "shells/clearDeploy" });
  };
}

export const selectShellForId = (state: { shells: ShellsState }, id: string): Shell | null =>
  state.shells.shellsById[id] || null;

export const selectStatusForGroupLabel = (state: { shells: ShellsState }, group: GroupLabel): ShellGroupStatus =>
  state.shells.fetchStatusByGroup[group];

export const selectShellsForGroup = (state: { shells: ShellsState }, group: GroupLabel): any => {
  // TODO: cache selector
  const results: Shell[] = [];

  if (group) {
    state.shells[group].forEach((i) => {
      if (state.shells.shellsById[i]) {
        results.push(state.shells.shellsById[i]);
      }
    });
    return results;
  }
  return results;
};

export const selectIsDeployingShell = (state: { shells: ShellsState }, id: string): boolean => state.shells.isDeploying;

export const selectIsWritingShell = (state: { shells: ShellsState }, id: string): boolean => state.shells.isWriting;

export const selectDeployShellError = (state: { shells: ShellsState }, id: string): string | null =>
  state.shells.deployError;

export const selectWriteShellError = (state: { shells: ShellsState }, id: string): string | null =>
  state.shells.writeError;
export default shellsSlice;
