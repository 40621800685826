import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Ar from "arweave/ar";
import { GatewayId } from "arweave/config";
import { SettingsState, Theme } from "./settingsSlice.types";

// ****************************************************************************
// Thunk
// ****************************************************************************

export const saveSettings = createAsyncThunk("settings/saveSettings", async (_: void, thunkAPI) => {
  const { getState } = thunkAPI;

  // @ts-ignore
  const state: RootState = getState();
  const settings: SettingsState = state.settings;

  const txId = await Ar.settings.saveSettingsToChain(settings);
  return txId;
});

export const loadSettings = createAsyncThunk("settings/loadSettings", async (_: void, thunkAPI) => {
  const fetchedSettings = await Ar.settings.loadSettingsFromChain();
  // TODO: merge settings + overwrite, etc.
  return fetchedSettings;
});

// ****************************************************************************
// settingsSlice
// ****************************************************************************

const initialState: SettingsState = {
  gatewayId: "arlocal.zephyrdev.xyz",
  theme: "light",
  wallpaper: "0",
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setGatewayId: (state, action: PayloadAction<GatewayId>) => {
      state.gatewayId = action.payload;
    },
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },
    setWallpaper: (state, action: PayloadAction<string>) => {
      state.wallpaper = action.payload;
    },
  },
});

// ****************************************************************************
// ****************************************************************************

export const { setGatewayId, setTheme, setWallpaper } = settingsSlice.actions;

export default settingsSlice;
