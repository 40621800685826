/**
 * OK-cancel confirmation dialog with basic customizations.
 * For anything more specific to a use-case, create a new component.
 */

import { useAppDispatch } from "redux/hooks";
import { closeDialog } from "redux/modal/modalSlice";
import "./style.scss";

export interface Props {
  title: string;
  message?: string;
  onOk?: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
  hideCancel?: boolean;
}

const DialogConfirm = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    title,
    message,
    onOk = () => {},
    onCancel = () => {},
    okText = "OK",
    cancelText = "Cancel",
    hideCancel = false,
  } = props;

  const handleOk = () => {
    onOk();
    dispatch(closeDialog());
  };

  const handleCancel = () => {
    onCancel();
    dispatch(closeDialog());
  };

  return (
    <div className="dialog-confirm-bg">
      <div className="dialog-confirm">
        <h1>{title}</h1>
        {message && <p>{message}</p>}
        <div>
          <button onClick={handleOk}>{okText}</button>
          {!hideCancel && <button onClick={handleCancel}>{cancelText}</button>}
        </div>
      </div>
    </div>
  );
};

export default DialogConfirm;
