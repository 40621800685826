import Ar from "arweave/ar";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import React from "react";
import { appendDevPageLog as append, setDevPageProfile } from "redux/ui/uiSlice";
import ProfileField from "./profileField";

type Mode = "edit" | "find" | "hide";

interface SearchProps {
  placeholder: string;
  text: string;
  onClick?: (value: string) => void;
  disabled?: boolean;
}

function Account() {
  const dispatch = useAppDispatch();
  const [mode, setMode] = React.useState<Mode>("hide");
  const profile = useAppSelector((state) => state.ui.devPage.profile);

  function readAccount() {
    dispatch(append("Reading account..."));
    Ar.account
      .getActiveAccount()
      .then((account: ArAccount) => {
        dispatch(append(account));
        dispatch(setDevPageProfile(account.profile));
      })
      .catch((err) => dispatch(append(err.message)));
  }

  function findByAddress(value: string) {
    Ar.account
      .getAccountsByAddress(value)
      .then((res) => dispatch(append(res)))
      .catch((err) => dispatch(append(err.message)));
  }

  function findByName(value: string) {
    Ar.account
      .findAccountsByName(value)
      .then((res) => dispatch(append(res)))
      .catch((err) => dispatch(append(err.message)));
  }

  function findByHandle(value: string) {
    Ar.account
      .getAccountByUniqueHandle(value)
      .then((res) => dispatch(append(res)))
      .catch((err) => dispatch(append(err.message)));
  }

  function writeBio() {
    Ar.account
      .connect()
      .then(() => {
        return Ar.account.writeProfile(profile);
      })
      .then((res) => dispatch(append(res)))
      .catch((err) => dispatch(append(err.message)));
  }

  const Search = (props: SearchProps) => {
    const ref = React.useRef<HTMLInputElement>(null);
    return (
      <div className="dev-page__row dev-page__row--no-sep dev-page__row--tight">
        <input ref={ref} type="text" placeholder={props.placeholder} />
        {props.onClick && (
          <button
            disabled={props.disabled}
            onClick={() => {
              if (props.onClick) {
                props.onClick(ref.current?.value || "");
              }
            }}
          >
            {props.text}
          </button>
        )}
      </div>
    );
  };

  React.useEffect(() => {
    if (mode === "edit") {
      readAccount();
    }
    // eslint-disable-next-line -- only respond to "mode"
  }, [mode]);

  return (
    <div className="dev-page__account">
      <div className="dev-page__row dev-page__row--no-sep">
        <select name="mode" value={mode} onChange={(e) => setMode(e.target.value as Mode)}>
          <option value="hide">---</option>
          <option value="edit">Edit My Profile</option>
          <option value="find">Find Profiles</option>
        </select>
        <button onClick={readAccount}>Read account from wallet</button>
      </div>

      {mode === "edit" && (
        <div className="dev-page__edit">
          <ProfileField field="handleName" />
          <ProfileField field="avatar" />
          <ProfileField field="banner" />
          <ProfileField field="name" />
          <ProfileField field="bio" />
          <ProfileField field="email" />
          <button disabled={!profile.handleName} onClick={() => writeBio()}>
            Update Profile
          </button>
        </div>
      )}

      {mode === "find" && (
        <>
          <Search text="Find by address" placeholder="address" onClick={findByAddress} />
          <Search text="Find by name" placeholder="name (handleName)" onClick={findByName} />
          <Search text="Find by handle" placeholder="handle (name#uniqId)" onClick={findByHandle} />
        </>
      )}
    </div>
  );
}

export default Account;
