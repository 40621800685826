import { createSlice, DeepPartial, PayloadAction as PA } from "@reduxjs/toolkit";
import Ar from "arweave/ar";
import { getOptions } from "arweave/txQuery";
import localForage from "localforage";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { getPostContentEncoding, getPostContentType, getPostData, getTxTypeForPost } from "redux/postForm/helpers";
import { fetchPosts, purgePosts } from "redux/posts/postsSlice";
import { AppDispatch, GetState } from "redux/store";
import { PostFormState } from "./types";

const initialState: PostFormState = {
  ui: {
    tab: "text",
    submitting: false,
    submitResult: null,
    submitError: null,
  },
  common: {
    version: "0.1",
    title: "",
    topic: "",
    thumbnailUrl: "",
  },
  text: {
    format: "plain",
    body: "",
    promoInfo: "",
    shellId: "",
    shellHandle: "",
  },
  link: {
    url: "",
  },
  file: {
    description: "",
    url: "",
  },
};

const persistCfg = {
  key: "postForm",
  version: 1,
  storage: localForage,
  stateReconciler: autoMergeLevel2,
  transforms: [createBlacklistFilter("ui", ["submitting"])],
};

const postFormSlice = createSlice({
  name: "postForm",
  initialState,
  reducers: {
    switchTab: (state, action: PA<PostType>) => {
      state.ui.tab = action.payload;
    },
    prepareForm: {
      reducer: (state, action: PA<PostType>) => {
        const next = { ...initialState };
        next.ui.tab = action.payload;
        return next;
      },
      prepare: (tab: PostType) => {
        return { payload: tab }; // Anticipate more stuff here later ...
      },
    },
    updateForm: (state, action: PA<DeepPartial<PostFormState>>) => {
      if (action.payload.ui) {
        state.ui = { ...state.ui, ...action.payload.ui };
      }
      if (action.payload.common) {
        state.common = { ...state.common, ...action.payload.common };
      }
      if (action.payload.text) {
        state.text = { ...state.text, ...action.payload.text };
      }
      if (action.payload.link) {
        state.link = { ...state.link, ...action.payload.link };
      }
      if (action.payload.file) {
        state.file = { ...state.file, ...action.payload.file };
      }
    },
    submitFormStart: (state) => {
      state.ui.submitResult = null;
      state.ui.submitError = null;
      state.ui.submitting = true;
    },
    submitFormDone: (state, action) => {
      state.ui.submitResult = action.payload;
      state.ui.submitting = false;
    },
    submitFormFail: (state, action) => {
      state.ui.submitError = action.payload;
      state.ui.submitting = false;
    },
    clearForm: () => initialState,
  },
});

// ****************************************************************************
// ****************************************************************************

export function submitPost(type: PostType) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const postForm = state.postForm;
    const usingArlocal = state.settings.gatewayId.startsWith("arlocal");

    dispatch(postFormSlice.actions.submitFormStart());

    // prettier-ignore
    const tags: PostTags = {
      "App-Name": Ar.appInfo.name,
      "App-Version": Ar.appInfo.version,
      "Type": getTxTypeForPost(type),
      "Post-Type": type,
      "Content-Type": getPostContentType(type, postForm),
      "Content-Encoding": getPostContentEncoding(type, postForm),
      "Title": state.postForm.common.title,
      "Shell-ID": state.postForm.text.shellId,
      "Shell-Handle": state.postForm.text.shellHandle,
      "Topic": postForm.common.topic,
      "Unix-Time": Math.floor(Date.now() / 1000).toString(),
    };

    if (type === "text") {
      tags["Thumbnail-URL"] = state.postForm.common.thumbnailUrl;
    } else if (type === "file") {
      tags["Description"] = state.postForm.file.description;
    }

    try {
      const tx = await Ar.arweave.createTransaction({ data: getPostData(type, state.postForm) });

      Object.entries(tags).forEach(([key, value]) => {
        if (value) {
          tx.addTag(key, value);
        }
      });

      if (!window.arweaveWallet) {
        throw new Error("No wallet connected. Please sign in first.");
      }

      let result: any;

      if (usingArlocal) {
        await Ar.arweave.transactions.sign(tx);
        result = await Ar.arweave.transactions.post(tx);
        dispatch(postFormSlice.actions.submitFormDone(result));
      } else {
        result = await window.arweaveWallet.dispatch(tx);
        dispatch(postFormSlice.actions.submitFormDone(result));
      }

      if (result?.data?.error) {
        throw new Error(result.data.error.msg || result.data.error);
      }

      // -- TODO: fix pagination in `postSlice` and just fetch incrementally. --
      dispatch(purgePosts("home"));
      dispatch(fetchPosts(getOptions(undefined, undefined)));
      // -----------------------------------------------------------------------
    } catch (err) {
      dispatch(postFormSlice.actions.submitFormFail(err));
      throw err;
    }
  };
}

export const reducer = postFormSlice.reducer;
export type ReducerType = ReturnType<typeof postFormSlice.reducer>;
export { persistCfg };

export default postFormSlice;
