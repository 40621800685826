import "./style.scss";
import { BlockNoteView, useBlockNote } from "@blocknote/react";
import { darkTheme } from "./theme";

interface Props {
  initialContent?: any;
  onChange?: (jsonStr: string) => void;
}

function BlockEditor(props: Props) {
  const { initialContent, onChange } = props;

  const editor = useBlockNote({
    onEditorContentChange: (editor) => {
      if (onChange) {
        onChange(JSON.stringify(editor.topLevelBlocks));
      }
    },
    initialContent: initialContent || null,
  });

  return (
    <div className="block-editor">
      <BlockNoteView editor={editor} theme={darkTheme} />
    </div>
  );
}

export default BlockEditor;

// -- TODO --
// [ ] Custom block: image uploader, viewer.
// [ ] Custom block: video embed.
// [ ] Strip off collaboration bloat?
// -- ISSUE --
// [ ] Toolbar popup gets clipped off when the editor is within a Dialog/Portal.
//     Probably a Dialog css issue rather than Editor's.
// [ ] Theme editor can't seem to pick up our custom fonts.
