import { Link } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { showShellPreview } from "redux/modal/modalSlice";
import "./style.scss";

export interface Props {
  shell: any;
}

const ShellName = (props: Props) => {
  const { shell } = props;
  const { title, id } = shell;
  const type = shell.type === "user" ? "u" : "s";
  const dispatch = useAppDispatch();

  const handleMouseEnter = (e: any) => {
    const trigger = e.target;
    dispatch(showShellPreview({ shell: shell, trigger: trigger }));
  };

  return (
    <Link to={`/${type}/${id}`} onMouseMove={handleMouseEnter}>
      <div className="shell-name">
        {type}/{title}
      </div>
    </Link>
  );
};

export default ShellName;
