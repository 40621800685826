import Compress from "client-compress";
import Ar from "arweave/ar";
import { AppDispatch, GetState } from "redux/store";

type TxId = string;
type CCOptions = ClientCompressOptions;
type CCResult = ClientCompressResult;

// ****************************************************************************
// resizeImage
// ****************************************************************************

const Default: CCOptions = {
  targetSize: 0.1, // 100 KB
  quality: 1.0,
  minQuality: 0.1,
  qualityStepSize: 0.05,
  throwIfSizeNotReached: true,
};

export function resizeImage(imageFile: File, options: CCOptions = Default) {
  return async (dispatch: AppDispatch, getState: GetState): Promise<CCResult> => {
    const compress = new Compress(options);
    return compress.compress([imageFile]).then((result: CCResult[]) => {
      return result[0];
    });
  };
}

// ****************************************************************************
// uploadImageToArweave
// ****************************************************************************

export function uploadImageToArweave(imageFile: File) {
  return async (dispatch: AppDispatch, getState: GetState): Promise<TxId> => {
    const state = getState();
    const usingArlocal = state.settings.gatewayId.startsWith("arlocal");

    try {
      await Ar.checkWalletExistence();
      const imageBuffer = await imageFile.arrayBuffer();
      const tx = await Ar.arweave.createTransaction({ data: imageBuffer });

      const tags = { "Content-Type": imageFile.type };
      Object.entries(tags).forEach(([n, v]) => v && tx.addTag(n, v));

      let result;
      if (usingArlocal) {
        await Ar.arweave.transactions.sign(tx);
        result = await Ar.arweave.transactions.post(tx);
        if (result?.status === 200) {
          return tx.id;
        }
      } else {
        result = await window.arweaveWallet.dispatch(tx);
        if (result.id) {
          return result.id;
        }
      }

      throw new Error("Upload failed", { cause: result });
    } catch (err: any) {
      console.log("[uploadImageToArweave]", err, err.cause);
      throw err;
    }
  };
}
