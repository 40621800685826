import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import "./index.scss";
import Ar from "arweave/ar";
import { initializeWarp } from "warp/warpServer";
import { persistor, store } from "redux/store";
import App from "components/app/App";
import Dialog from "components/dialog";
import MenuWrapper from "components/menu";
import ShellPreview from "components/shellPreview";
import WalletKitProvider from "components/walletKitProvider";

function AppWrapper() {
  const Rehydrating = () => {
    return <div className="rehydrating" />;
  };

  function onReduxReady() {
    const gatewayId = store.getState().settings.gatewayId;
    Ar.initialize(gatewayId);
    initializeWarp(gatewayId, Ar.arweave);
  }

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<Rehydrating />} persistor={persistor} onBeforeLift={onReduxReady}>
          <HashRouter>
            <WalletKitProvider>
              <App />
              <Dialog />
              <MenuWrapper />
              <ShellPreview />
            </WalletKitProvider>
          </HashRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement as HTMLElement);
root.render(<AppWrapper />);
