// const URL = "https://randomuser.me/api/";

import users from "./users.json";
import shells from "./shells.json";
import posts from "./posts.json";

export interface MockShell {
  id: string;
  type: string;
  cell?: string;
  name: {
    title: string;
    first?: string;
    last?: string;
    handle: string;
  };
  email: string;
  picture: {
    large?: string;
    medium?: string;
    thumbnail?: string;
  };
  description?: string;
}

export const mockShells = Object.freeze(shells);

export const mockShellIds = Object.freeze(shells.map((shell) => shell.id));

for (let user of users.results) {
  (user as any).type = "user";
}

export function getSomeUsers(count: number) {
  let res = users.results;
  for (let i = res.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [res[i], res[j]] = [res[j], res[i]];
  }
  return res.slice(0, count);
}

export function getUser(handle: any) {
  return users.results.find((user) => user.login.username === handle);
}

export function getShell(handle: string) {
  for (let shell of shells) {
    if ((shell as any).name.handle === handle) return shell;
  }
  for (let shell of users.results) {
    if (shell.name.last === handle) {
      (shell as any).type = "user";
      shell.name.title = `${shell.name.first} ${shell.name.last}`;
      return shell;
    }
  }
  if (handle === "rave") {
    return {
      type: "user",
      name: {
        title: "Rave",
        handle: "rave",
      },
      picture: {
        thumbnail: "https://spee.ch/8/3099c0c1b1cd6fcd.png",
      },
    };
  }
}

export function getUserById(id: string) {
  return users.results.find((user) => user.login.uuid === id);
}

export function getShellForHandle(handle: string) {
  return shells.find((shell) => shell.name.handle === handle);
}

export function getPosts(page: string, handle?: string, max?: number | null) {
  let length = max || Math.floor(Math.random() * 30) + 5;
  let users = getSomeUsers(length);

  let res: any[] = [];
  let mockPosts: any[] = [];
  if (handle && page === "shell") {
    mockPosts = posts.filter((post) => post.shell && post.shell.handle === handle);
    // if (!mockPosts[0] && handle === "rave") mockPosts = posts.filter((post) => post.author.name.handle === handle);
    return mockPosts;
  } else if (handle && page === "user") {
    mockPosts = posts.filter((post) => post.author.name.handle === handle);
  }
  res = mockPosts;

  if (mockPosts[0]) {
    if (handle === "rave") {
      for (let i = 0; i < length; i++) {
        res.push({
          shell: {
            handle: "rave",
            title: "Rave's Shell",
          },
          author: {
            id: "905c7bc6-9c4d-4e3a-bff7-26bbf3de82e2",
            name: {
              handle: "rave",
              username: "rave",
            },
            thumbnail: "https://spee.ch/8/3099c0c1b1cd6fcd.png",
          },
          thumbnail: `https://picsum.photos/seed/${i}/500/281`,
          title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.".substring(
            0,
            Math.floor(Math.random() * 55)
          ),
          type: "text",
        });
      }
    } else {
      for (let user of users) {
        res.push({
          shell: {
            handle: "rave",
            title: "Rave's Shell",
          },
          author: {
            id: user.login.uuid,
            name: {
              handle: user.name.last,
              username: user.name.first,
            },
            thumbnail: user.picture.thumbnail,
          },
          thumbnail: `https://picsum.photos/seed/${user.name.last}/500/281`,
          title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.".substring(
            0,
            Math.floor(Math.random() * 55)
          ),
          type: "text",
        });
      }
    }
  } else {
    let user = getUser(handle);
    if (user) {
      res = [];
      for (let i = 0; i < length; i++) {
        res.push({
          shell: { handle: "us_election2024", title: "US Election 2024" },
          author: {
            id: user.login.uuid,
            name: {
              handle: user.login.username,
              username: user.login.username,
            },
            thumbnail: user.picture.thumbnail,
          },
          thumbnail: `https://picsum.photos/seed/${i}/500/281`,
          title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.".substring(
            0,
            Math.floor(Math.random() * 55)
          ),
          type: "text",
        });
      }
    } else {
      res = posts;
      for (let user of users) {
        res.push({
          shell: { handle: "us_election2024", title: "US Election 2024" },
          author: {
            id: user.login.uuid,
            name: {
              handle: user.login.username,
              username: user.login.username,
            },
            thumbnail: user.picture.thumbnail,
          },
          thumbnail: `https://picsum.photos/seed/${user.name.last}/500/281`,
          title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.".substring(
            0,
            Math.floor(Math.random() * 55)
          ),
          type: "text",
        });
      }
    }
  }

  if (max) {
    return res.slice(0, max);
  }

  return res;
}
