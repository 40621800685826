import SortBy from "components/posts/post/sortBy";
import React, { useEffect } from "react";
import { getCommentQueryKey } from "redux/comments/helpers";
import { fetchTxs } from "redux/comments/slice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Comment from "../comment";

import "./style.scss";

interface Props {
  postId: string;
}

// Currently only handles top-level comments.

const Comments = (props: Props) => {
  const { postId } = props;
  const dispatch = useAppDispatch();
  const queryKey = getCommentQueryKey(postId, postId, postId); // top-level trio
  const commentIds = useAppSelector((state) => state.comments.byQuery.commentIds[queryKey]);
  const hasMore = useAppSelector((state) => Boolean(state.comments.byQuery.cursor[queryKey]));
  const numComments = commentIds ? commentIds.length : 0;
  const [prevNumComments, setPrevNumComments] = React.useState(numComments);

  const scrollRef = React.useRef<HTMLDivElement>(null);

  const fetchComments = React.useCallback(() => {
    dispatch(fetchTxs(postId, postId, postId));
  }, [postId, dispatch]);

  useEffect(() => {
    fetchComments();
  }, [dispatch, fetchComments]);

  useEffect(() => {
    if (prevNumComments !== numComments) {
      setPrevNumComments(numComments);
      scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [prevNumComments, numComments]);

  // [ ] Not fetching "total" for now until the "tx vs. contract" is confirmed.

  return (
    <div className="comments" ref={scrollRef}>
      <div className="comments__header">
        <h3>
          Comments
          {numComments === 0 && <span>0</span>}
        </h3>
        {numComments !== 0 && <SortBy postId={postId} />}
      </div>
      <div className="comments__container">
        {commentIds?.map((id: string) => (
          <Comment key={id} commentId={id} />
        ))}
        {hasMore && (
          <button className="comments__load-more" onClick={fetchComments}>
            More
          </button>
        )}
      </div>
    </div>
  );
};

export default Comments;
