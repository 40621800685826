import DevPage from "pages/dev";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { openContextMenu, openDialog } from "redux/modal/modalSlice";
import Icon from "components/icons";
import WalletConnect from "components/walletConnect";
import ShellMenu from "./shellMenu";
import "./style.scss";

export interface Props {}

const Navigation = (props: Props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  function openShellMenu(e: any) {
    dispatch(openContextMenu(ShellMenu, { trigger: e }));
  }

  function openDevDialog(e: any) {
    dispatch(openDialog(DevPage, {}));
  }

  function onSettingsClick(e: any) {
    if (location.pathname === "/settings") {
      e.preventDefault();
      window.history.back();
    }
  }

  return (
    <>
      <nav className="navbar__container">
        <div className="navbar__item navbar__item--text-icon" onClick={openDevDialog}>
          <Icon icon="devmode" />
        </div>
        <div className="navbar__item" onClick={openShellMenu}>
          <Icon icon="egg" width={18} />
        </div>
        <div className="navbar__item">
          <WalletConnect />
        </div>
        <div className="navbar__item navbar__item--settings">
          <Link to="/settings" title="Settings" onClick={(e) => onSettingsClick(e)}>
            <Icon icon="settings" />
          </Link>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
