import Arweave from "arweave";
import * as AR_CFG from "arweave/config";
import { GatewayId } from "arweave/config";

const USE_BUNDLER = true;

// ****************************************************************************
// Buffer
// ****************************************************************************

const Buffer = {
  fromObj: (obj: {}) => new TextEncoder().encode(JSON.stringify(obj)),
  toObj: (buffer: Uint8Array) => JSON.parse(new TextDecoder().decode(buffer)),
  fromStr: (str: string) => Uint8Array.from(atob(str), (c) => c.charCodeAt(0)),
  toStr: (buffer: Uint8Array) => btoa(String.fromCharCode.apply(null, buffer as unknown as number[])),
};

// ****************************************************************************
// SettingsMgr
// ****************************************************************************

class SettingsMgr {
  private arweave: Arweave;
  private readonly gatewayId: GatewayId;
  private readonly debug: boolean = false;

  private readonly PARTITION_TAG = "Partition";

  constructor(gatewayId: GatewayId = "arweave.net", debug = false) {
    this.gatewayId = gatewayId;
    this.arweave = Arweave.init({ ...AR_CFG.gateways[gatewayId] });
    this.debug = debug;
  }

  async saveSettingsToChain(settings: {}) {
    const settingsBuffer = Buffer.fromObj(settings);

    const aesKey = this.generateAesKey();
    const settingsCipher = await this.encryptWithAes(settingsBuffer, aesKey);
    const aesKeyCipher = await this.encryptWithWallet(aesKey);

    const payload = Arweave.utils.concatBuffers([settingsCipher, aesKeyCipher]);

    const tx = await this.arweave.createTransaction({ data: payload });
    tx.addTag("App-Name", String(AR_CFG.appInfo.name));
    tx.addTag("Protocol-Name", "Encrypted-App-Data");
    tx.addTag("Protocol-Version", "0.1");
    tx.addTag("Content-Type", "application/octet-stream");
    tx.addTag(this.PARTITION_TAG, String(aesKeyCipher.length));

    const useBundler = USE_BUNDLER && !this.gatewayId.startsWith("arlocal");

    if (useBundler) {
      const r = await window.arweaveWallet.dispatch(tx);
      console.log("dispatched result:", r); // TODO: throw if failed.
    } else {
      await this.arweave.transactions.sign(tx);
      const result = await this.arweave.transactions.post(tx);

      if (result.data?.error?.msg) {
        throw new Error(result.data.error.msg);
      }
    }

    return tx.id;
  }

  async loadSettingsFromChain() {
    const transactionId = await this.fetchSettingsTransactionId();
    const transaction = await this.arweave.transactions.get(transactionId);

    const partitionTag = transaction.tags.find((tag: any) => {
      return tag.get("name", { decode: true, string: true }) === this.PARTITION_TAG;
    });

    if (!partitionTag) {
      throw new Error(`${this.PARTITION_TAG} tag not found`);
    }

    const partitionValue = partitionTag.get("value", { decode: true, string: true });
    const offset = transaction.data.length - Number(partitionValue);

    const settingsCipher = transaction.data.subarray(0, offset);
    const aesKeyCipher = transaction.data.subarray(offset);

    const aesKeyBuffer = await this.decryptWithWallet(aesKeyCipher);
    const settingsBuffer = await this.decryptWithAes(settingsCipher, aesKeyBuffer);

    const settings = Buffer.toObj(settingsBuffer);
    return this.result(settings);
  }

  // ----------------------------------
  // ----------------------------------

  private async fetchSettingsTransactionId() {
    const response = await this.arweave.api.post("graphql", {
      query: `{
        transactions(
          tags: [
            {
              name: "Protocol-Name",
              values: ["Encrypted-App-Data"],
            }
          ]
        )
        {
          edges {
            node {
              id
            }
          }
        }
      }`,
    });

    const { data } = response.data;
    const transactions = data.transactions.edges;

    if (transactions.length === 0) {
      throw new Error("No settings transaction found");
    }

    return transactions[0].node.id;
  }

  private generateAesKey() {
    const buffer = new Uint8Array(32);
    const key = window.crypto.getRandomValues(buffer);

    return this.result(key);
  }

  private async encryptWithWallet(plain: Uint8Array): Promise<Uint8Array> {
    const encrypted = await window.arweaveWallet.encrypt(Buffer.toStr(plain), {
      algorithm: "RSA-OAEP",
      hash: "SHA-256",
    });

    return this.result(encrypted);
  }

  private async decryptWithWallet(cipher: Uint8Array) {
    const plain = await window.arweaveWallet.decrypt(cipher, {
      algorithm: "RSA-OAEP",
      hash: "SHA-256",
    });

    return this.result(Buffer.fromStr(plain));
  }

  private async encryptWithAes(plain: Uint8Array, key: Uint8Array) {
    return this.arweave.crypto.encrypt(plain, key);
  }

  private async decryptWithAes(cipher: Uint8Array, key: Uint8Array) {
    return this.arweave.crypto.decrypt(cipher, key);
  }

  private result(input: any) {
    if (this.debug) {
      const caller = this.getCallerName(new Error());
      console.log(`[${caller}]:\n${input}`);
    }
    return input;
  }

  private getCallerName(error: any) {
    return error.stack?.split("\n")[2]?.trim().split(" ")[1] || "";
  }
}

export default SettingsMgr;
