import "./style.scss";
import classnames from "classnames";
import DialogConfirm from "components/dialogConfirm";
import { openDialog } from "redux/modal/modalSlice";
import { getCreationTs, getThumbnail, getTitle } from "./helpers";
import Spinner from "components/spinner";
import dayjs from "lib/dayjs";
import React from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { fetchMyBin, restoreItem } from "redux/recycleBin/thunks";
import { utilSetWallpaper } from "utils/setTheme";

function RecycleBinPage() {
  const dispatch = useAppDispatch();
  const walletOn = Boolean(useAppSelector((state) => state.wallet.address));
  const postsById = useAppSelector((state) => state.posts.postsById);
  const restoringIds = useAppSelector((state) => state.recycleBin.restoringTxIds);
  const { list, error, fetching } = useAppSelector((state) => state.recycleBin.myBin);
  const count = Object.keys(list).length;

  async function doRestoreItem(txId: TxId) {
    dispatch(restoreItem(txId)).catch((err) => {
      dispatch(
        openDialog(DialogConfirm, {
          title: "Failed to restore item",
          message: err.message,
          hideCancel: true,
        })
      );
    });
  }

  React.useEffect(() => {
    utilSetWallpaper("recycleBinBackground");
  }, []);

  React.useEffect(() => {
    if (walletOn) {
      dispatch(fetchMyBin());
    }
  }, [walletOn, dispatch]);

  if (fetching) {
    return (
      <div className="recycleBinPage">
        <Spinner forceDarkBg />
      </div>
    );
  }

  return (
    <div className="recycleBinPage">
      {error && <div className="recycleBinPage__error">{error}</div>}
      {count === 0 && <div className="recycleBinPage__empty">Your recycle bin is empty</div>}
      {count > 0 && (
        <ul>
          {Object.entries(list).map(([txId, timestampMs]) => (
            <li
              key={txId}
              title={JSON.stringify(postsById[txId], null, 2)}
              className={classnames("item", { "item--restoring": restoringIds.includes(txId) })}
            >
              <div className="item__thumb">
                <img src={getThumbnail(txId, postsById)} />
              </div>
              <div className="item__meta">
                <div className="item__title">{getTitle(txId, postsById)}</div>
                <div className="item__ts">Deleted: {dayjs(timestampMs).fromNow()}</div>
                <div className="item__ts">Created: {dayjs(getCreationTs(txId, postsById)).fromNow()}</div>
                <div className="item__id">{txId}</div>
              </div>
              <div className="item__action">
                <button onClick={() => doRestoreItem(txId)} disabled={restoringIds.includes(txId)}>
                  Restore
                </button>
                {restoringIds.includes(txId) && <Spinner forceDarkBg size="small" />}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default RecycleBinPage;
