import { useLocation } from "react-router-dom";

function usePathPage() {
  const location = useLocation();

  if (location.pathname.includes("/u/")) {
    return "user";
  } else if (location.pathname.includes("/s/")) {
    return "shell";
  } else {
    return "home";
  }
}

export default usePathPage;
