import React from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import uploadImage from "redux/uploadImage/slice";

interface Props {
  id: string;
}

export default function UrlMode(props: Props) {
  const { id } = props;
  const dispatch = useAppDispatch();
  const url = useAppSelector((state) => state.uploadImage[id]?.url);

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(uploadImage.actions.setUrl({ id, url: e.target.value }));
  };

  return (
    <div className="upload-image__url-mode">
      <div>Enter Thumbnail URL</div>
      <input type="input" onChange={handleUrlChange} value={url} placeholder={"ar://<txid> or https://"} />
    </div>
  );
}
