const SEARCH_PAGE_SIZE = 5;

/**
 * See `PostTags` in "src/types/tx-tags.d.ts" for all tags in a Post.
 * @param q
 * @param exact
 */
export function gqlSearchPost(q: string, exact: boolean) {
  function getTitle() {
    if (exact) {
      return `{ name: "Title", values: ["${q}"] }`;
    } else {
      const words = q.trim().split(" ");
      return `{ name: "Title", values: ${JSON.stringify(words)}, match: FUZZY_OR }`;
    }
  }

  return `
  {
    transactions(
      first: ${SEARCH_PAGE_SIZE},
      tags: [
        ${getTitle()}
      ]
    ) 
    {
      pageInfo { hasNextPage }
      edges {
        cursor
        node {
          id
          owner { address }
          tags { name value }
        }
      }
    },
  }`;
}

export function gqlSearchShell(q: string, exact: boolean) {
  const SHELL_NAME = process.env.REACT_APP_SHELL_CAT_TAG_NAME;

  function getShellNameTag() {
    if (exact) {
      return `{ name: "${SHELL_NAME}", values: ["${q}"] }`;
    } else {
      const words = q.trim().split(" ");
      return `{ name: "${SHELL_NAME}", values: ${JSON.stringify(words)}, match: FUZZY_OR }`;
    }
  }

  return `
  {
    transactions(
      first: ${SEARCH_PAGE_SIZE},
      tags: [
        ${getShellNameTag()}
      ]
    ) 
    {
      pageInfo { hasNextPage }
      edges {
        cursor
        node {
          id
          owner { address }
          tags { name value }
        }
      }
    },
  }`;
}

export function gqlSearchUser(q: string, exact: boolean) {
  function getHandle() {
    const words = q.trim().split(" ");
    if (exact) {
      if (words.length > 1) {
        return `{ name: "handle", values: ${JSON.stringify([q].concat(words))} }`;
      } else {
        return `{ name: "handle", values: ["${q}"] }`;
      }
    } else {
      return `{ name: "handle", values: ${JSON.stringify(words)}, match: FUZZY_OR }`;
    }
  }

  return `
  {
    transactions(
      first: ${SEARCH_PAGE_SIZE},
      tags: [
        { name: "Protocol-Name", values: ["Account-0.2", "Account-0.3"] }
        ${getHandle()}
      ]
    ) 
    {
      pageInfo { hasNextPage }
      edges {
        cursor
        node {
          id
          owner { address }
          tags { name value }
        }
      }
    },
  }`;
}
