export function gqlDeletedItems(ids: string[]) {
  return `
  {
    transactions(
      ids: [${ids.map((id) => `"${id}"`).join(",")}]
    ) 
    {
      pageInfo { hasNextPage }
      edges {
        cursor
        node {
          id
          owner { address }
          tags { name value }
        }
      }
    },
  }`;
}
