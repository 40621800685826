import "../styles.scss";
import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { doReadShells, GroupLabel, selectStatusForGroupLabel } from "redux/shells/slice";

interface ShellGroupProps {
  shellGroup: GroupLabel;
}

function ShellGroup(props: ShellGroupProps) {
  const { shellGroup } = props;
  const shellStatus = useAppSelector((state) => selectStatusForGroupLabel(state, shellGroup)) || {};
  const shellIds = useAppSelector((state) => state.shells[shellGroup]);
  const shellsById = useAppSelector((state) => state.shells.shellsById);
  const { lastFetched, fetching } = shellStatus;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!lastFetched) {
      // or lastfetched was too long ago,
      dispatch(doReadShells({ groupLabel: shellGroup }));
    }
  }, [lastFetched, dispatch, shellGroup]);

  if (!lastFetched || fetching) {
    return <div>loading</div>;
  }

  return (
    <div className="dock__row">
      {shellIds.map((id: TxId) => (
        <div className="dock__tile" key={id}>
          <div className="dock__tile-title">{shellsById[id]?.name || `Not found: ${id}`}</div>
        </div>
      ))}
    </div>
  );
}

export default ShellGroup;
